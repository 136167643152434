export enum UserActionType {
    openModal = 'open_modal',
    redirect = 'redirect',
    select = 'select',
    selectAll = 'select_all',
    filterByStatus = 'filter_by_status',
    filterByLevel = 'filter_by_level',
    filterBySearchValue = 'filter_by_search_value',
    bulkActionTypeSelected = 'bulk_action_type_selected',
    addRole = 'add_role',
    removeRole = 'remove_role',
    downloadCsv = "download_csv",
    deleteAll = "delete_all",
    upload = "upload",
    cellClick = "cell_click",
    chartClick = "chart_click",
    dateRangeOpen = "date_range_open",
}
