import { WizardContentType } from "../../enums/wizardType";

export class WizardContent {
    title: string;
    symbol: string;
    description: string;
    name: string;
    type: WizardContentType;
    data: any;

    constructor(type: WizardContentType, data: any) {
        this._setWizardDetails(type, data);
    }

    private _setWizardDetails(type: WizardContentType, data: any) {
        this.type = type;
        this.data = data;

        if (type === WizardContentType.actionReason) {
            this.title = "Reason";
            this.symbol = 'fa-list-ul';
            this.description = "Select a reason for this action:";
            this.name = "action_reason";;
        }
        else if (type === WizardContentType.refund) {
            this.title = "Refund Amount";
            this.symbol = 'fa-gears';
            this.description = "Define refund details";
            this.name = "";
        }
        else if (type === WizardContentType.summary) {
            this.title = "Summary";
            this.symbol = 'fa-check';
            this.description = null;
            this.name = "";
        }
        else if (type === WizardContentType.price) {
            this.title = "Price";
            this.symbol = 'fa-list-ul';
            this.description = "Select price";
            this.name = "renewal_price";
        }
        else if (type === WizardContentType.penalty) {
            this.title = "Penalty";
            this.symbol = 'fa-user';
            this.description = "Set a contract penalty";
            this.name = "";
        }
        else if (type === WizardContentType.item_group) {
            this.title = "Item Group";
            this.symbol = 'fa-list-ul';
            this.description = "Select Item Group";
            this.name = "item_group";
        }
        else if (type === WizardContentType.card) {
            this.title = "Card";
            this.symbol = 'fa-credit-card';
            this.description = "Card";
            this.name = "card";
        }
        else if (type === WizardContentType.discount) {
            this.title = "Discount Details";
            this.symbol = 'fa-edit';
            this.description = "Discount Details";
            this.name = "discount";
        }
        else if (type === WizardContentType.customer_care_action) {
            this.title = "Customer Care";
            this.symbol = 'fa-handshake-o';
            this.description = "Customer Care Action";
            this.name = "support_ticker";
        }
        else if (type === WizardContentType.select_date) {
            this.title = "Select Date";
            this.symbol = 'fa-handshake-o';
            this.description = "Select Date";
            this.name = "finish_at";
        }
        else if (type === WizardContentType.linked_user) {
            this.title = "Add Linked User";
            this.symbol = 'fa-plus';
            this.description = "";
            this.name = "linked_user";
        }
        else if (type === WizardContentType.bulk_action) {
            this.title = "Bulk Action";
            this.symbol = 'fa-list-ul';
            this.description = "";
            this.name = "bulk_action";
        }
        else if (type === WizardContentType.renew_offline_payment) {
            this.title = "Renew Offline Payment";
            this.symbol = 'fa-list-ul';
            this.description = "Renew Offline Payment";
            this.name = "renew_offline_payment";
        }
        else if (type === WizardContentType.payment) {
            this.title = "Payment";
            this.symbol = 'fa-credit-card';
            // this.description = "Payment";
            this.name = "payment";
        }
        else if (type === WizardContentType.contact_details) {
            this.title = "Contact Details";
            this.symbol = 'fa-list-ul';
            // this.description = "Payment";
            this.name = "contact_details";
        }
    }
}
