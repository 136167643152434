import { Component, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { CarouselConfig } from 'ngx-bootstrap/carousel';

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.css'],
  providers: [
    { provide: CarouselConfig, useValue: { interval: 7500, noPause: true, showIndicators: true } }
  ]
})
export class LandingComponent {

  public didScroll = false;
  public changeHeaderOn = 200;
  public navbarScrolled = false;

  public showSpinner: any = false;
  public currentYear: number;

  constructor(
    private _router: Router
  ) {
    this.showSpinner = false;
    this.currentYear = new Date().getFullYear();
  }

  @HostListener('window:scroll', [])
  onWindowScroll() {
    if (!this.didScroll) {
      this.didScroll = true;
      // setTimeout(this.scrollPage(), 250);
    }
  }

  scrollPage() {
    const sy = this.scrollY();

    this.navbarScrolled = (sy >= this.changeHeaderOn);
    this.didScroll = false;
  }

  scrollY() {
    return window.pageYOffset;
    // return window.pageYOffset || this.docElem.scrollTop;
  }

  login() {
    this.showSpinner = true;
    const self = this;

    setTimeout(function () {
      self._router.navigate(['/analytics/overview/consolidated']);
    }, 100);
  }
}
