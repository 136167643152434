export enum PurchaseDetailsComponentType {
    purchaseStatus,
    purchaseDetails,
    penaltyDetails,
    purchaseGroupAccounts,
    paymentDetails,
    renewalDetails,
    refundDetails,
    chargeDetails,
    purchaseActions,
    contactInfoDetails,
    deliveryHolidayDetails
}