export const emailTemplateData = {
    '>>Customer.Name<<': 'John Doe',
    '>>Customer.Id<<': 'd621b848-dda1-4be4-a0f2-22f4b2fc03c0',
    '>>Customer.Email<<': 'john.doe@flip-pay.com',
    '>>Customer.Address<<': 'IFSC House, Custom House Quay, Dublin 1, Ireland',
    '>>Customer.PostCode<<': 'D01R2P9',
    '>>Customer.Pin<<': '12345',

    '>>Purchase.StartAt<<': '01/01/2100 12:00',
    '>>Purchase.StartAtDate<<': '01/01/2100',
    '>>Purchase.FinishAt<<': '31/12/2100 00:00',
    '>>Purchase.FinishAtDate<<': '31/12/2100',
    '>>Purchase.Description<<': 'Access to unlimited content',
    '>>Purchase.Id<<': '94c6aeae-8158-440a-ab70-70ee35e28658',
    '>>Purchase.Price<<': '€13.99',
    '>>Purchase.Frequency<<': 'monthly',
    '>>Purchase.OriginalStartAtDate<<': '01/01/2100',
    '>>Purchase.RenewablePrice<<': '€13.99',
    '>>Purchase.RenewablePriceFrequency<<': 'monthly',
    '>>Purchase.FirstDeliveryDate<<': '01/01/2100',
    '>>Purchase.LastDeliveryDate<<': '31/01/2100',
    '>>Purchase.DeliveryHolidayDeduction<<': '€3.00',
    '>>Purchase.UpgradeDeduction<<': '€2.50',
    '>>Purchase.DelayedPaymentProcessAt<<': '01/01/2100',
    '>>Purchase.ContractPenalty<<': '€12.50',
    '>>Purchase.ContractFinishAt<<': '31/12/2100 00:00',
    '>>Purchase.ContractFinishAtDate<<': '31/12/2100',
    '>>Purchase.CancelledAtDate<<': '31/12/2100',
    '>>Purchase.CancelledAt<<': '31/12/2100',
    '>>Purchase.GiftCode<<': 'ABC123',
    '>>Purchase.GiftExpiration<<': '31/12/2100',
    '>>Purchase.GiftMessage<<': 'Happy Birthday!',
    '>>CurrentYear<<': '2100',
    '>>ItemGroup.Description<<': 'Premium content',
    '>>Price.Name<<': 'Premium Access',
    '>>Price.Id<<': '6a6ce48f-4334-4204-9d18-c01a8b5c3de3',
    '>>Price.PriceToDisplay<<': '€13.99',
    '>>Price.PriceToDisplayBilled<<': '€13.99',
    '>>Price.PriceToDisplayFrequency<<': 'monthly',
    '>>Price.Summary<<': '€13.99',
    '>>RenewablePrice.Name<<': 'Premium Access',
    '>>RenewablePrice.Id<<': '6a6ce48f-4334-4204-9d18-c01a8b5c3de3',
    '>>RenewablePrice.PriceToDisplay<<': '€13.99',
    '>>RenewablePrice.PriceToDisplayBilled<<': '€13.99',
    '>>RenewablePrice.PriceToDisplayFrequency<<': 'monthly',
    '>>RenewablePrice.Summary<<': '€13.99',
    '>>Card.Brand<<': 'Visa',
    '>>Card.Last4<<': '1234',
    '>>Mandate.Last4<<': '5678',
    '>>Mandate.SortCode<<': '123',
    '>>Refund.Amount<<': '€10',
    '>>InvitedCustomer.Name<<': 'John Doe 2',
    '>>InvitedCustomer.Id<<': 'd7990669-c8ea-47a5-8d49-c3ce801fc7e6',
    '>>InvitedCustomer.Email<<': 'john2.doe@flip-pay.com',
    '>>InvitedCustomer.Address<<': 'IFSC House, Custom House Quay, Dublin 1, Ireland',
    '>>InvitedCustomer.PostCode<<': 'D01R2P9',
    '>>InvitedCustomer.Pin<<': '23456',
    '>>InvitationToken<<': '6809ce62-bb0d-4547-847d-d71b79a532da',
    '>>PaymentDetailsLink<<': 'Pruchase Details Link',

    '>>Price.Brand<<': 'Visa',
    '>>Price.Last4<<': '1234',

    '>>Item.EventDate<<': '01/01/2100',
    '>>Item.EventDateTime<<': '01/01/2100 03:00',
  };