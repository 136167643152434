export class StripeCredential {
    id?: string;
    client_id?: string;
    name?: string;
    publishable_key?: string;
    secret_key?: string;
    statement_descriptor?: string;
    non_associated_fees_sku_code?: string;
    email_flow_redirect_url?: string;
    payment_method_type?: Array<any>;
    payment_method_type_moto?: Array<any>;

    // Internal Properties
    internal_translate?: any = {
        path:"analytics_settings.stripe_credentials.table",
        section: "analytics_settings",
        category: "stripe_credentials",
        type: "form"
    };

    internal_properties?: any = [
        { name: "id", type: "string", readonly: true },
        { name: "client_id", type: "string" },
        { name: "name", type: "string" },
        { name: "publishable_key", type: "string" },
        { name: "secret_key", type: "string" },
        { name: "statement_descriptor", type: "string", length: 22 },
        { name: "non_associated_fees_sku_code", type: "string" },
        { name: "email_flow_redirect_url", type: "string" },
        { name: "payment_method_type", type: "dropdown-multiple-label" },
        { name: "payment_method_type_moto", type: "dropdown-multiple-label" }
    ];

    constructor(values: StripeCredential = {}) {
        Object.assign(this, values);
    }
}