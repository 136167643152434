export class PurchaseDuration {
    duration?:number;
    duration_unit?: string;
    grace_period?: boolean;
    grace_period_duration_unit?: string;
    grace_period_length?: number;
    renewable?: boolean;
    renewable_price_id?: string;
    variable_finish_at?: boolean;
    grace_period_suspend?: boolean;
    grace_period_suspend_duration?: number;
    grace_period_suspend_duration_unit?: string;

    start_at?:string;
    finish_at?:string;

    constructor(values: PurchaseDuration = {}) {
        [
            'duration',
            'duration_unit',
            'grace_period',
            'grace_period_duration_unit',
            'grace_period_length',
            'renewable',
            'renewable_price_id',
            'variable_finish_at',
            'start_at',
            'finish_at',
            'grace_period_suspend',
            'grace_period_suspend_duration',
            'grace_period_suspend_duration_unit',
        ].forEach(p => this[p] = values[p]);
    }
}
