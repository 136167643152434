export class MeteredPaywall {
    metered_paywall?: boolean;
    metered_paywall_duration?: number;
    metered_paywall_duration_unit?: string;
    metered_paywall_expiration_type?: string;
    metered_paywall_items_limit?: number;
    metered_paywall_message_first?: string;
    metered_paywall_message_last?: string;
    metered_paywall_message_middle?: string;
    metered_paywall_show_counter_first?: boolean;
    metered_paywall_show_counter_last?: boolean;
    metered_paywall_show_counter_middle?: boolean;

    constructor(values: MeteredPaywall = {}) {
        [
            'metered_paywall',
            'metered_paywall_duration',
            'metered_paywall_duration_unit',
            'metered_paywall_expiration_type',
            'metered_paywall_items_limit',
            'metered_paywall_message_first',
            'metered_paywall_message_last',
            'metered_paywall_message_middle',
            'metered_paywall_show_counter_first',
            'metered_paywall_show_counter_last',
            'metered_paywall_show_counter_middle',
        ].forEach(p => this[p] = values[p]);
    }
}