import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Routes, RouterModule } from '@angular/router';

import { CarouselModule } from 'ngx-bootstrap/carousel';
import { NgSelectModule } from '@ng-select/ng-select';
import { ToastrModule } from 'ngx-toastr';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { ConfirmActionModalModule } from './components/common/confirm-action-modal/confirm-action-modal.module';

import { AppComponent } from './app.component';
import { LandingComponent } from './pages/landing/landing.component';

import { Globals } from './services/common/globals.service';
import { ApiService } from './services/api/api.service';
import { HelpersService } from './services/common/helpers.service';
import { AuthGuard } from './services/auth/auth.guard';
import { LocalizedText } from './services/localization/localizedText.service';
import { RoleGuard } from './services/auth/role.guard';
import { AuthService } from './services/auth/auth.guard.service';
import { StorageService } from './services/common/storage.service';
import { PreviousRouteService } from './services/common/previous-route.service';
import { CanDeactivateGuard } from './services/auth/can-deactivate-guard.service';
import { PaymentService } from './services/payments/stripe.service';
import { VendorCommonRequestsService } from './services/common/vendor.service';

import { ClientResolver } from './shared/resolvers/clients.resolver';

const routes1: any = [
  {
    path: '',
    redirectTo: 'clients',
    pathMatch: 'full'
  },
  {
    path: 'clients',
    canActivate: [AuthGuard],
    children: [
      { path: '', redirectTo: 'loading', pathMatch: 'full' },
      {
        path: ':clientId',
        loadComponent: () => import('./clients.component').then(m => m.ClientsComponent),
        canActivate: [AuthGuard, ClientResolver],
        children: [
          { path: 'analytics', loadChildren: () => import('../app/pages/analytics-landing/analytics-landing.module').then(m => m.AnalyticsLandingModule) }
        ]
      },
    ]
  },
  { path: 'authentication', loadChildren: () => import('../app/pages/authentication/authentication.module').then(m => m.AuthenticationModule) },
  { path: 'policies', loadChildren: () => import('../app/pages/policies/privacy/privacy.module').then(m => m.PrivacyPolicyModule) },
  { path: 'callback', loadChildren: () => import('../app/pages/callbacks/callback/callback.module').then(m => m.CallbackModule) },
  { path: 'signin-google-impadminclient', loadChildren: () => import('../app/pages/callbacks/authentication/google/callback.google.module').then(m => m.CallbackGoogleModule) },
  { path: 'redirect', loadChildren: () => import('../app/pages//analytics-user-settings/analytics-user-settings.module').then(m => m.AnalyticsUserSettingsModule) },
  { path: 'error', loadChildren: () => import('../app/pages/callbacks/fallbacks/pages-error/pages-error.module').then(m => m.PageErrorModule) },
  { path: '**', redirectTo: '/error/page-not-found', pathMatch: 'full' }
];

const routes: Routes = [
  {
    path: '',
    redirectTo: 'analytics',
    pathMatch: 'full'
  },
  {
    path: 'analytics',
    loadChildren: () => import('../app/pages/analytics-landing/analytics-landing.module').then(m => m.AnalyticsLandingModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'policies',
    // loadChildren: () => PrivacyPolicyModule
    loadChildren: () => import('../app/pages/policies/privacy/privacy.module').then(m => m.PrivacyPolicyModule),
  },
  {
    path: 'authentication',
    loadChildren: () => import('../app/pages/authentication/authentication.module').then(m => m.AuthenticationModule),
  },
  {
    path: 'callback',
    loadChildren: () => import('../app/pages/callbacks/callback/callback.module').then(m => m.CallbackModule),
  },
  {
    path: 'signin-google-impadminclient',
    loadChildren: () => import('../app/pages/callbacks/authentication/google/callback.google.module').then(m => m.CallbackGoogleModule),
  },
  {
    path: 'error',
    // loadChildren: () => PageErrorModule
    loadChildren: () => import('../app/pages/callbacks/fallbacks/pages-error/pages-error.module').then(m => m.PageErrorModule),
  },
  {
    path: '**', redirectTo: '/error/page-not-found', pathMatch: 'full'
  },
];

@NgModule({
  declarations: [
    AppComponent,
    LandingComponent
  ],
  imports: [
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserModule,
    HttpClientModule,
    NgxChartsModule,
    NgSelectModule,
    ConfirmActionModalModule,
    CarouselModule.forRoot(),
    ToastrModule.forRoot({preventDuplicates: true}),
    RouterModule.forRoot(routes1, { paramsInheritanceStrategy: 'always' }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (http: HttpClient) => new TranslateHttpLoader(http, '/assets/i18n/', '.json?cb=' + new Date().getTime()),
        deps: [HttpClient]
      }
    })
  ],
  providers: [
    ApiService,
    PaymentService,
    AuthGuard,
    AuthService,
    RoleGuard,
    CanDeactivateGuard,
    Globals,
    HelpersService,
    PreviousRouteService,
    VendorCommonRequestsService,
    HttpClient,
    LocalizedText,
    StorageService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
