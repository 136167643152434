import { Group } from '../content/Group';
import { Customer } from '../customer/Customer';
import { ItemGroup } from '../itemGroup.model';

export class NavigationDetail {
    userName?: string;
    userRole?: string;
    userRoles?: any;
    currentRoleLevels?: string[];
    currentClientId?: string;

    isGod?: boolean;
    parentGroup?: Group;
    parentGroups?: Group[];

    customer?: Customer;

    purchase?: any;
    price?: any;
    item?: ItemGroup;

    showEvents?: any;

    constructor(navDetails: any) {
        if (!!navDetails) {
            // User Details
            if (!!navDetails.user) {
                this.userName = navDetails.user.name;

                this.isGod = navDetails.user.all_role_levels.includes('god');

                if (!this.userName) {
                    this.userName = !!navDetails.user.email ? navDetails.user.email.split('@')[0] : null;
                }

                this.userRoles = navDetails.user.roles;

                if (!!navDetails.user.roles && navDetails.user.roles.length > 0) {
                    const temp = navDetails.user.roles.find(x => x.client_id === navDetails.user.current_client_id);
                    this.userRole = !!temp ? temp.level : navDetails.user.roles[0].level;
                }

                this.currentRoleLevels = navDetails.user.current_role_levels;
                this.currentClientId = navDetails.user.current_client_id;

                //Vendor
                if (!!navDetails?.vendor) {
                  this.showEvents = !!navDetails?.vendor?.show_events ? navDetails?.vendor?.show_events : null;
                }
            }

            // Selected Group Details
            this.parentGroup = navDetails.parentGroup;
            this.parentGroups = navDetails.parentGroups;

            // Selected Customer Details
            this.customer = navDetails.customer;

            // Selected Purchase Details
            this.purchase = navDetails.purchase;

            // Selected Price Details
            this.price = navDetails.selectedPrice;

            // Selected Group Item
            this.item = navDetails.selectedItem;
        }
    }
}
