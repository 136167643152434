export enum WizardContentType {
    actionReason,
    refund,
    penalty,
    price,
    summary,
    item_group,
    card,
    discount,
    customer_care_action,
    select_date,
    linked_user,
    bulk_action,
    payment,
    renew_offline_payment,
    contact_details,
}