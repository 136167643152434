import { AudienceRule } from "./audienceRule.model";

export class Audience {
    id?: string;
    parent_item_group_id?: string;
    name?: string;
    code?: string;
    description?: string;
    created_at?: string;
    updated_at?: string;

    active?: boolean;
    deleted?: boolean;
    root?: boolean;
    priority?: number;
    rules?: Array<AudienceRule>;
    custom_text_fields?: Array<AudienceRule>;

    constructor(values?: Audience) {
        Object.assign(this, values);
    }
}