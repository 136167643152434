import { Tag } from "./content/Tag";

export class ItemGroup {
  id?: string;
  client_id?: string;
  item_group_id?: string;
  item_code?: string;
  created_at?: string;
  description?: string;
  link?: string;
  event_date?: any;
  tags?: Tag[];
  ignore_parent_prices?: boolean;
  maximum_number_of_purchases?: number;
  login_providers?: string;
  site_code?: string;

  //
  object?: string = "item_group";

  parent_id?: string;
  parent_code?: string;
  updated_at?: string;

  item_group_code?: string;
  deleted?: boolean;
  explicit_items_only?: boolean;
  explicit?: boolean;
  paywall_status?: string;
  country_rules?: Array<any>;

  badges?: Array<any>;
  smtp_credential_id?: string
  stripe_credential_id?: string
  address_validation_credential_id?: string
  showcase_publication_id?: string

  //region Expand = true
  AuditItems?: Array<any>;
  //endregion

  AvailableForPurchaseInPortal?: boolean;
  Tags?: Array<any>;
  ReportingCurrency?: string;
  CommissionTaxRate?: number;

  PaymentProviders?: Array<any>
  PaymentProvidersMoto?: Array<any>

  constructor(values: ItemGroup = {}) {
    Object.assign(this, values);
  }
}
