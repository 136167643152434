export const paywallTypes = [
  {
    label: "Normal",
    value: "normal"
  },
  {
    label: "Free",
    value: "free"
  },
  {
    label: "Blocked",
    value: "blocked"
  }
]
