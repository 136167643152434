export class RefundReason {
    type = 'standard';
    reason?: string;
    text_answer: boolean;
    active: any = true;

    constructor(values: Object = {}) {
        Object.assign(this, values);
    }
}
