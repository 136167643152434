import { Dom, InputView, OutputView, Step, StepComponentView, StepComponentViewContext, StepContext, StepExtension, TaskStepComponentViewConfiguration, Vector } from "sequential-workflow-designer";

export const InterruptStep: StepExtension = {
     componentType: "interrupt",
     createComponentView: (
          parentElement: SVGElement,
          stepContext: StepContext<Step>,
          viewContext: StepComponentViewContext
     ) => {
          const viewConfig: TaskStepComponentViewConfiguration = {
               paddingLeft: 0,
               paddingRight: 20,
               paddingY: 13,
               textMarginLeft: 20,
               minTextWidth: 20,
               iconSize: 28,
               radius: 10,
               inputSize: 10,
               outputSize: 0,
          };

          const wrapperEl = Dom.svg("g", {
               class: `sqd-step-icon sqd-type-${stepContext.step.type}`,
          });
          parentElement.appendChild(wrapperEl);

          const circleEl = Dom.svg("circle", {
               cx: 60,
               cy: 26,
               r: 26,
               class: "sqd-step-icon-circle",
          });

          circleEl.textContent = stepContext.step.name;
          wrapperEl.appendChild(circleEl);

          const imageEl = Dom.svg("image", {
               href: "./assets/img/icons/journey-builder/icon-interrupting-icon.svg",
          });

          Dom.attrs(imageEl, {
               x: 46,
               y: viewConfig.paddingY,
               width: viewConfig.iconSize,
               height: viewConfig.iconSize,
          });
          wrapperEl.appendChild(imageEl);

          const height = 2 * viewConfig?.paddingY + viewConfig?.iconSize;
          const width =
               viewConfig?.iconSize +
               viewConfig?.paddingLeft +
               viewConfig?.paddingRight +
               viewConfig?.textMarginLeft +
               Math.max(circleEl.getBBox().width, viewConfig?.minTextWidth);

          const rectEl = Dom.svg("rect", {
               x: 0.5,
               y: 0.5,
               class: "sqd-step-task-rect",
               width: width,
               height: height,
               rx: viewConfig?.radius,
               ry: viewConfig?.radius,
          });

          const v =
               0 === stepContext.depth &&
               0 === stepContext.position &&
               !stepContext.isInputConnected;

          const w = v
               ? null
               : InputView.createRoundInput(
                    wrapperEl,
                    width / 2,
                    0,
                    viewConfig.inputSize
               );

          const disableStepOutput = true;
          const stepOutput = disableStepOutput
               ? null
               : OutputView.create(
                    wrapperEl,
                    width / 2,
                    height,
                    viewConfig.outputSize
               );

          const stepConpomentView: StepComponentView = {
               g: wrapperEl,
               width: width,
               height: height,
               joinX: width / 2,
               sequenceComponents: null,
               placeholders: null,
               hasOutput: () => !!stepOutput,
               getClientPosition() {
                    const t = circleEl.getBoundingClientRect();
                    return new Vector(t.x, t.y);
               },
               resolveClick: (t: any) => {
                    return !!wrapperEl.contains(t.element) || null;
               },
               setIsDragging(t: any) {
                    null === w || w.setIsHidden(t);
                    null === stepOutput || stepOutput.setIsHidden(t);
               },
               setIsDisabled(t: any) {
                    Dom.toggleClass(wrapperEl, t, "sqd-disabled");
               },
               setIsSelected(t: any) {
                    Dom.toggleClass(circleEl, t, "sqd-selected");
               },
          };

          return stepConpomentView;
     },
};
