export enum ErrorCodeType {
    duplicate = "duplicate",
    refundExceed = "refund_exceeding_available_amount",
    periodMismatch = "period_mismatch",
    periodDataNotAvailable = "period_data_not_available",
    processCard = "unable_to_process_card",
    penaltyFailed = "contact_penalty_failed",
    validationError = "validation_error",
    autoAddress = "autoaddress",
    badRequest = "Bad Request",
    socialRegistration = "social_registration",
    customerNotFound = "customer_not_found",
    EmailSentSuccessfully = "Email sent successfully",
    invalidPassword = "invalid_password",
    limitExceeded = "limit_exceeded",
    maximumDurationExceeded ="maximum_duration_exceeded",
    paymentTermsDesriptionLimitExceeded ="payment_terms_desription_limit_exceeded",
    offerNotAvailableDescriptionLimitExceeded ="offer_not_available_description_limit_exceeded",
    overlappingHolidayExists = "overlapping_holiday_exists",
    PriceNotFoundOrOverThePurchaseLimit = "price_not_found_or_over_the_purchase_limit",
    PeriodsAlreadyProcessed = "period_already_processed",
    PeriodNotFound = "period_not_found",
    maximumDateRangeExceeded ="maximum_date_range_exceeded",
    reportContainsNoData = "report_contains_no_data",
    yourCardWasDeclined = "your_card_was_declined",
    insufficientFunds = "insufficient_funds",
    expiredCard = "expired_card",
    incorrectCvc = "incorrect_cvc",
    processingError = "processing_error",
    repeatedAttempts = "repeated_attempts"
}
