export enum PaymentProviderType {
    stripe = "stripe",
    cca = "cca",
    tpay = "tpay",
    payfort = "payfort",
    stripeSubscriptions = "stripe_subscriptions",
    stripeBacs = "stripe_bacs",
    stripeSepa = "stripe_sepa",
    stripeGooglePay = "stripe_google_pay",
    stripeApplePay = "stripe_apple_pay",
    offline = "offline",
    stripe24 = "stripe_24",
    payway = "payway",
    authorizeNet = "authorize_net",
    secureCall = "secure_call",
}