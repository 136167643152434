import { Injectable } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';

@Injectable()
export class PreviousRouteService {

    private _previousUrl: string;
    private _currentUrl: string;

    constructor(private _router: Router) {
        this._currentUrl = this._router.url;
        _router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
                this._previousUrl = this._currentUrl;
                this._currentUrl = event.url;
            }
        });
    }

    public getPreviousUrl() {
        return this._previousUrl;
    }
}
