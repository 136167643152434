export class ContactInfo {
    address_id?: string;
    address_is_deliverable?: boolean;

    id?: string;
    email?: string;
    name?: string;
    phone_number?: string;
    address1?: string;
    address?: string;
    address2?: string;
    address3?: string;
    address4?: string;
    address5?: string;
    delivery_notes?: string;
    post_code?: string;
    country?: string;
    country_code?: string;
    code?: string;
    latitude?: any;
    longitude?: any;

    // helpers
    canceled?: boolean;

    internal_properties?: any = [
        { name: "id", type: "string", readonly: true },
        { name: "email", type: "string" },
        { name: "name", type: "string" },
        { name: "phoneNumber", type: "number" },
        { name: "address1", type: "string" },
        { name: "address2", type: "string" },
        { name: "address3", type: "string" },
        { name: "address4", type: "string" },
        { name: "address5", type: "string" },
        { name: "address", type: "string" },
        { name: "delivery_notes", type: "string" },
        { name: "post_code", type: "string" },
        { name: "country_code", type: "dropdown", options: {} },
        { name: "latitude", type: "string" },
        { name: "longitude", type: "string" },
    ];

    constructor(values: ContactInfo = {}) {
        Object.assign(this, values);
    }
}