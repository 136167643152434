import { Injectable } from '@angular/core';

@Injectable()
export class StorageService {
    private _storageName: any = 'fp.user.settings';

    constructor() { }

    setSettings(data: any) {
        localStorage.setItem(this._storageName, JSON.stringify(data));
    }

    getUserSettings() {
        const data = localStorage.getItem(this._storageName);
        return data && data !== "undefined" ? JSON.parse(data) : null;
    }

    clearUserSettings() {
        localStorage.removeItem(this._storageName);
    }

    cleanAll() {
        localStorage.clear();
    }
}
