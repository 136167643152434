export class PriceDescription {
    internal_name?: string;
    name?: string;
    summary?: string;
    descriptions?: Array<any>;
    position?: number;
    sku_code?: string;
    image_url?: string;
    badge_text?: string;
    badges?: Array<any>;
    segments?: Array<any>;
    tags?: Array<any>;

    constructor(values: PriceDescription = {}) {
        [
            'internal_name',
            'name',
            'summary',
            'descriptions',
            'position',
            'sku_code',
            'image_url',
            'badge_text',
            'badges',
            'segments',
            'tags',
            'subscription_id',
        ].forEach(p => this[p] = values[p]);
    }
}
