export class Referrer {
    id?: string;
    name?: string;
    domain?: string;
    type?: string;
    created_at?: string;

    constructor(values: Object = {}) {
        Object.assign(this, values);
    }
}
