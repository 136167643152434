export class CouponCode {
    id?: string;
    coupon_id?: string;
    temp_coupon_id?: string;
    code?: string;
    active?: boolean;
    created_at?: string;

    constructor(values: Object = {}) {
        Object.assign(this, values);
    }
}
