import { Tag } from './Tag';

export class Group {
    id?: string;
    parent_id?: string;
    client_id?: string;
    item_group_code?: string;
    created_at?: string;
    description?: string;
    link?: string;
    tags?: Tag[];
    group_prices?: Group[];
    explicit_items_only?: boolean;
    ignore_parent_prices?: boolean;
    paywall_status?: any;
    country_rules?: any;
    audit_items?: any;
    reporting_currency?: any;
    commission_tax_rate?: any;
    badges?: any;
    smtp_credential_id?: any;
    stripe_credential_id?: any;
    custom_text_fields_set?: boolean;
    custom_text_fields?: any;
    login_providers?: any;

    constructor(values: Object = {}) {
        Object.assign(this, values);
    }
}
