export class Badge {
  id?: string;
  name?: string;
  description?: string;
  created_at?: string;
  custom_text_fields?: Array<any>;
  custom_text_fields_set?: boolean;

  constructor(values: Badge) {
    this._internalMapping.forEach(b => this[b] = values[b]);
    delete this._internalMapping;
  }

  private _internalMapping = [
    "id",
    "name",
    "description",
    "created_at",
    "custom_text_fields",
    "custom_text_fields_set"
  ]

}
