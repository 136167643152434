export class PayfortCredential {
    id?: string;
    client_id?: string;
    name?: string;

    created_at?: string;
    merchant_identifier?: string;
    access_code?: string;
    language?: string;
    request_pass_phrase?: string;
    response_pass_phrase?: string;

    // Internal Properties
    internal_translate?: any = {
        path: "analytics_settings.payfort_credentials.table",
        section: "analytics_settings",
        category: "payfort_credentials",
        type: "form"
    };

    internal_properties?: any = [
        { name: "id", type: "string", readonly: true },
        { name: "client_id", type: "string" },
        { name: "name", type: "string" },
        { name: "created_at", type: "string" },
        { name: "merchant_identifier", type: "string" },
        { name: "access_code", type: "string" },
        { name: "language", type: "string" },
        { name: "request_pass_phrase", type: "string" },
        { name: "response_pass_phrase", type: "string" },
    ];

    constructor(values: PayfortCredential = {}) {
        Object.assign(this, values);
    }
}