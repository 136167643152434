export enum JourneyBuilderStepType {
     // Customer Actions
     PremiumProduct = 'jb-ca-premium-product',
     NonPremiumProduct = 'jb-ca-non-premium-product',
     CustomerSelect = 'jb-ca-customer-select',
     CustomerConfirm = 'jb-ca-customer-confirm',

     // Flip-Pay Actions
     JourneyTrigger = 'jb-fp-trigger',
     JourneyAccountRequired = 'jb-fp-account-required',
     FlipPayAccessStatus = 'jb-fp-access-status',
     FlipPayRedirect = 'jb-fp-redirect',
     FlipPayPremiumProductDelivered = 'jb-fp-display-premium-content',
     FlipPayPayment = 'jb-fp-payment',
     FlipPayInterrupt = 'jb-fp-interrupt',
     
     ActionProcessPayment = 'process_payment',
     PaymentSuccess = 'payment_success',
     
     // Flip-Pay Fragments
     FlipPayFragmentPremiumPlans = 'jb-fragment-premium-plans',
     FlipPayFragmentOrderSummary = 'jb-fragment-order-summary',
     FlipPayFragmentThankYou = 'jb-fragment-thank-you',

     FragmentAuthentication = 'authentication_fragment',
     FragmentVoluntaryContribution = 'voluntary_contribution_fragment',

     // Flip-Pay Authentication
     FlipPayAuthenticationStatus = 'jb-fp-authentication-status',

     // Flip-Pay Notification
     EmailNotification = 'jb-fp-email-notification',

}
