import { Injectable } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { GlobalConfig, IndividualConfig, ToastrService } from 'ngx-toastr';
import { ConfirmActionModalComponent } from '../../components/common/confirm-action-modal/confirm-action-modal.component';
import { regexExpression } from '../../constants';
import { countryCodes } from '../../constants/country_codes.constant';
import { ModalWindowSize, WizardContentType } from '../../enums';
import { ErrorCodeType } from '../../enums/errorCodeType';
import { ModalType } from '../../enums/modalType';
import { WarningType } from '../../enums/warningType';
import { GenericReport, Price, WizardContent } from '../../models';
import { LocalizedText } from '../localization/localizedText.service';
import { Globals } from './globals.service';

@Injectable({ providedIn: 'root' })

export class HelpersService {
    public currentLang: any = 'en';
    public textOrientation: any = 'ltr';

    public oidc_token_manager: any;
    public templateConfiguration: any = this.setTemplateConfiguration();
    public currentLocalizedText: any;

    constructor(
        private _localizedText: LocalizedText,
        private _toastr: ToastrService,
        private _modalService: BsModalService,
        private _globals: Globals
    ) {
        this._localizedText.text.subscribe(res => {
            this.currentLocalizedText = res.component_texts;
        });
    }

    // #region IS Helpers

    public secondsToDate(secs: any, dateNow?: any) {
        const t = !!dateNow ? new Date() : new Date(1970, 0, 1); // Epoch
        t.setSeconds(secs);
        return t;
    }

    public setCookie(cname: string, value: any, expires: any) {
        try {
            expires = !!expires ? ("; expires=" + expires) : "";
            const domain = window.location.hostname;
            document.cookie = cname + "=" + (value || "") + expires + "; path=/;domain=" + domain;
        } catch (err) {
            console.error(err);
            throw err;
        }
    }

    public getCookie(cname: string) {
        try {
            const name = cname + "=";
            const decodedCookie = decodeURIComponent(document.cookie);
            const ca = decodedCookie.split(';');
            for (let i = 0; i < ca.length; i++) {
                let c = ca[i];
                while (c.charAt(0) === ' ') {
                    c = c.substring(1);
                }
                if (c.indexOf(name) === 0) {
                    return c.substring(name.length, c.length);
                }
            }
            return "";
        } catch (err) {
            console.error(err);
            return "";
        }
    }

    public deleteCookie(name: string, path?: string) {
        try {
            if (this.getCookie(name)) {
                const domain = window.location.hostname;
                document.cookie = name + "=" +
                    ((path) ? ";path=" + path : "") +
                    ((domain) ? ";domain=" + domain : "") +
                    ";expires=Thu, 01 Jan 1970 00:00:01 GMT";
            }
        } catch (err) {
            console.error(err);
            throw err;
        }
    }

    public removeUndefinedValues(data: any) {
        Object.keys(data).forEach(key => {
            if (data[key] === undefined) {
                delete data[key];
            }
        });
        return data;
    }

    /**
     * @description Remove all values equel null
     * @param data
     * @returns Data clear of null values
     */
    public removeNullValues(data: any) {
        Object.keys(data).forEach(key => {
            if (data[key] === null) {
                delete data[key];
            }
        });
        return data;
    }

    /**
     * @method check if the input string is UUID
     * @param s string to check
     */
    public isUUID(s: any) {
        const re = new RegExp('^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$');
        return re.test(s);
    }

    public generateGuid(): string {
        let result: string;
        let i: string;
        let j: number;

        result = '';
        for (j = 0; j < 32; j++) {
            if (j === 8 || j === 12 || j === 16 || j === 20) {
                result = result + '-';
            }
            i = Math.floor(Math.random() * 16).toString(16).toUpperCase();
            result = result + i;
        }
        return result;
    }

    /**
     * @method comparing two objects. Returns true if objects are different
     * @param a object A
     * @param b object B
     * @param propertiesToCheck properties that needs to be compared
     * @param skipUndefinedProperties flag to skip undefined properties in object A
     */
    public isDifferent(a, b, propertiesToCheck, skipUndefinedProperties?): boolean {
        for (let index = 0; index < propertiesToCheck.length; index++) {
            const prop = propertiesToCheck[index];

            if (!!skipUndefinedProperties && a[prop] === undefined) {
                continue;
            }

            if (Array.isArray(a[prop])) {
                if (!b[prop] || (a[prop].length !== b[prop].length)) {
                    return true;
                }

                const firstKeyInArray: any = typeof a[prop][0] === 'object' ? Object.keys(a[prop][0])[0] : null;
                if (!!firstKeyInArray) {
                    a[prop] = a[prop].sort((a: any, b: any) => (a[firstKeyInArray] > b[firstKeyInArray]) ? 1 : -1);
                    b[prop] = b[prop].sort((a: any, b: any) => (a[firstKeyInArray] > b[firstKeyInArray]) ? 1 : -1);
                }

                for (let ind = 0; ind < Object.keys(a[prop]).length; ind++) {
                    const valA = a[prop][ind];
                    const valB = b[prop][ind];

                    if (!valB || JSON.stringify(valA, Object.keys(valA).sort()) !== JSON.stringify(valB, Object.keys(valB).sort())) {
                        return true;
                    }
                }

            } else if (prop !== 'currency_deal' && JSON.stringify(a[prop]) !== JSON.stringify(b[prop])) {
                return true;
            }
        }

        return false;
    }

    /**
     * @method check whether the input string is date or not.
     * @param value input string
     */
    public isISODate(value): boolean {
        const _regExp = new RegExp('^(-?(?:[1-9][0-9]*)?[0-9]{4})-(1[0-2]|0[1-9])-(3[01]|0[1-9]|[12][0-9])T(2[0-3]|[01][0-9]):([0-5][0-9]):([0-5][0-9])(.[0-9]+)?(Z)?$');
        return _regExp.test(value);

    }

    /**
     * @method check whether the input string is date or not.
     * @param value input string
     */
    public isDate(value) {
        if (!value) {
            return false;
        }

        if (typeof value === 'boolean') {
            return false;
        }

        if (typeof value === 'string' && value.length < 5) {
            return false;
        }

        const testDate = new Date(value);
        if (typeof value !== 'number' && Object.prototype.toString.call(testDate) === '[object Date]' && !(isNaN(testDate.getTime()))) {
            return true;
        }

        if (toString.call(value) === '[object Date]') {
            return true;
        }

        if (typeof value.replace === 'function') {
            value.replace(/^\s+|\s+$/gm, '');
        }

        const dateFormat = /(^\d{1,4}[\.|\\/|-]\d{1,2}[\.|\\/|-]\d{1,4})(\s*(?:0?[1-9]:[0-5]|1(?=[012])\d:[0-5])\d\s*[ap]m)?$/;
        return dateFormat.test(value);
    }

    /**
     * @method check whether the object is empty or not
     * @param obj input object
     */
    public isEmpty(obj) {
        for (const prop in obj) {
            if (obj.hasOwnProperty(prop)) {
                return false;
            }
        }

        return true;
    }

    // #endregion IS Helpers

    //#region Common Helpers

    /**
     * @method create a deep copy of the input object
     * @param obj input object
     */
    public deepCopy(obj: any) {
        if (!obj) {
            return obj;
        }

        let copy: any;

        // Handle the 3 simple types, and null or undefined
        if (null === obj || 'object' !== typeof obj) { return obj; }

        // Handle Date
        if (obj instanceof Date) {
            copy = new Date();
            copy.setTime(obj.getTime());
            return copy;
        }

        // Handle Array
        if (obj instanceof Array) {
            copy = [];
            for (let i = 0, len = obj.length; i < len; i++) {
                copy[i] = this.deepCopy(obj[i]);
            }
            return copy;
        }

        // Handle Object
        if (obj instanceof Object) {
            copy = {};
            for (const attr in obj) {
                if (obj.hasOwnProperty(attr)) { copy[attr] = this.deepCopy(obj[attr]); }
            }
            return copy;
        }

        throw new Error('Unable to copy obj! Its type isnt supported.');
    }

    /**
     * @method to define CSS class for elements where we want to display flag icons
     * @param countryName - identifier of the country
     */
    public setGeoCountryInputStyle(countryName: string) {
        const selectedCountry = countryCodes.find(x => x.name === countryName);

        if (!!selectedCountry) {
            return 'flag-icon-input flag-icon-' + selectedCountry.code.toLowerCase();
        } else {
            return '#fff';
        }
    }

    /**
    * @method to define CSS class for elements where we want to display flag icons
    * @param countryName - identifier of the country
    */
    public setGeoCountryInputStyleByCountryCode(countryCode: string): string {
        if (!countryCode) {
            return 'fa fa-minus';
        }

        const selectedCountry = countryCodes.find(x => x.code === countryCode);
        return 'flag-icon-btn flag-icon-' + selectedCountry.code.toLowerCase();
    }

    /**
    * @method to define CSS class for elements where we want to display flag icons
    * @param countryName - identifier of the country
    */
    public setGeoCountryInputLabelByCountryCode(countryCode: string) {
        if (!countryCode) {
            return '';
        }

        const selectedCountry = countryCodes.find(x => x.code === countryCode);
        return selectedCountry.name;
    }

    public setTemplateConfiguration() {
        const preferences = {
            backgrounds: {
                body: '#989898',
                header: '#f1f1f1',
                footer: '#f1f1f1'
            }
        };

        return preferences;
    }

    /**
     * @description retunr number of days between two dates
     * @param startAt
     * @param finishAt
     * @returns retunr number of days between two dates
     */
    public calucateNumberOfDaysBetweenTwoDates(startAt: string, finishAt: string): number {
        try {
            const startDate = new Date(startAt);
            const finishDate = new Date(finishAt);
            return finishDate.getDate() - startDate.getDate();
        } catch (err: any) {
            console.log()
            throw err;
        }
    }

    public currencyToSymbol(currency) {
        let uc = '\u20AC';
        if (!!currency) {
            switch (currency.toUpperCase()) {
                case 'EUR':
                    uc = '\u20AC';
                    break;
                case 'USD':
                    uc = '\u0024';
                    break;
                case 'CAD':
                    uc = '\u0024';
                    break;
                case 'AUD':
                    uc = '\u0024';
                    break;
                case 'GBP':
                    uc = '\u00A3';
                    break;
                case 'EGP':
                    uc = 'EGP';
                    break;
                case 'AED':
                    uc = 'AED ';
                    break;
                default:
                    uc = currency.toUpperCase();
                    break;

                // AED,EGP currency unicode - Not used, because it is not a common currency
                // Easier to display just AED or EGP to users for better understanding
                // uc = ' \u062F\u002E\u0625 ';
            }
        }

        return uc;
    }

    /**
     * @description method to check whether the input string is a valid IP address or not
     * @param ipaddress string to check
     */
    public validateIPaddress(ipaddress): boolean {
        return /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(ipaddress);
    }

    /**
     * @description method to validate an email address format
     * @param email input string - email address
     */
    public validateEmail(email: string) {
        const re = regexExpression.emailValidation.pattern;
        return re.test(email);
    }

    /**
     * @description method to convert amount and currency to friendly string.
     * @param amount amount type of number
     * @param currency currency type of string
     */
    public amountAndCurrencyToString(amount, currency) {
        if (amount < 0) {
            return '(' + this.currencyToSymbol(currency) + Math.abs(amount).toFixed(2) + ')';
        }
        return this.currencyToSymbol(currency) + amount.toFixed(2);
    }

    /**
     * @method format number to string where thousands are separated by comma
     * @param x input number
     */
    public numberWithCommas(x) {
        const roundedNumber = Math.abs(x).toFixed(2);
        const parts = roundedNumber.toString().split('.');
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        return parts.join('.');
    }

    public stringPluralize(duration, durationUnit): string {
        // Singular
        if (duration === 1) {
            switch (durationUnit) {
                case 'hours':
                    return 'Hourly';
                case 'days':
                    return 'Daily';
                case 'weeks':
                    return 'Weekly';
                case 'months':
                    return 'Monthly';
                case 'quarters':
                    return 'Quarterly';
                case 'years':
                    return 'Annually';
                default:
                    return (!!duration && !!durationUnit)
                        ? (duration.toString() + ' ' + durationUnit.toString())
                        : '';
            }
        } else {
            // Plural
            switch (durationUnit) {
                case 'hours':
                    return duration.toString() + 'Hours';
                case 'days':
                    return duration.toString() + ' Days';
                case 'weeks':
                    return duration.toString() + ' Weeks';
                case 'months':
                    return duration.toString() + ' Months';
                case 'quarters':
                    return duration.toString() + ' Quarters';
                case 'years':
                    return duration.toString() + ' Years';
                default:
                    return (!!duration && !!durationUnit)
                        ? (duration.toString() + ' ' + durationUnit.toString())
                        : '';
            }
        }
    }

    public capitalizeFirstLetter(string) {
        if (!string) {
            return string;
        }
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    public getDateStamp(date?: any, yearFirst?: boolean) {
        const tempDate = !!date ? date : new Date();
        if (!!yearFirst) {
            return ('' + tempDate.getFullYear()).slice(-2)
                + ('0' + (tempDate.getMonth() + 1)).slice(-2)
                + ('0' + tempDate.getDate()).slice(-2)
                + ('0' + tempDate.getHours()).slice(-2)
                + ('0' + tempDate.getMinutes()).slice(-2);
        }

        return ('0' + tempDate.getDate()).slice(-2) + '-' + ('0' + (tempDate.getMonth() + 1)).slice(-2) + '-' + tempDate.getFullYear();
    }

    public applyDateStringPattern(dateValue, format, reverse: boolean = false) {
        if (reverse) {
            
            dateValue = dateValue.replace(/"/g, '');

            if(!format){
                format = "yyyy-MM-dd";
            }

            let parts;
            let year, month, day;
            switch (format) {
                case 'M/d/yyyy':
                case 'MM/dd/yyyy':
                    parts = dateValue.split('/');
                    if (parts.length !== 3) { return '-';}
                    month = parseInt(parts[0], 10);
                    day   = parseInt(parts[1], 10);
                    year  = parseInt(parts[2], 10);                    
                    break;
                case 'yyyy-MM-dd':
                case 'yyyy/MM/dd':                    
                    const delimiter = format.indexOf('-') !== -1 ? '-' : '/';
                    parts = dateValue.split(delimiter);
                    if (parts.length !== 3) { return '-'; }
                    year  = parseInt(parts[0], 10);
                    month = parseInt(parts[1], 10);
                    day   = parseInt(parts[2], 10);
                    break;
                case 'd/M/yyyy':                    
                    parts = dateValue.split('/');
                    if (parts.length !== 3) { return '-'; }
                    day   = parseInt(parts[0], 10);
                    month = parseInt(parts[1], 10);
                    year  = parseInt(parts[2], 10);                                      
                    break;
                default:                    
                    return '-';
            }
            const dateObj = new Date(year, month -1, day, 12,0,0);            
            if (isNaN(dateObj.getTime())) {
                return '-';
            }
            return dateObj;
        } else {
            // Treat dateValue as a valid date input for the Date constructor
            const dateObj = new Date(dateValue);
            if (isNaN(dateObj.getTime())) {
                return '-';
            }
    
            const year  = dateObj.getFullYear();
            const month = dateObj.getMonth() + 1; // Note: getMonth() is zero-based
            const day   = dateObj.getDate();
    
            // Helpers for zero-padding
            const mm = String(month).padStart(2, '0');
            const dd = String(day).padStart(2, '0');
    
            let convertedDate;
            switch (format) {
                case 'M/d/yyyy':
                    convertedDate = `${month}/${day}/${year}`;
                    break;
                case 'MM/dd/yyyy':
                    convertedDate = `${mm}/${dd}/${year}`;
                    break;
                case 'yyyy-MM-dd':
                    convertedDate = `${year}-${mm}-${dd}`;
                    break;
                case 'yyyy/MM/dd':
                    convertedDate = `${year}/${mm}/${dd}`;
                    break;
                case 'd/M/yyyy':
                    convertedDate = `${day}/${month}/${year}`;
                    break;
                // Add more formats if needed
                default:
                    const includeTime = false;
                    convertedDate = this.formatDateForRequest(dateValue, includeTime);
                    break;
            }
            return convertedDate;
        }
    }    

    public formatDateString(date, type = null) {

        if (!this.isDate(date)) {
            return '-';
        }

        const tempDate = new Date(date);
        let outputDate = date;

        switch (type) {
            case "short":
                const month = this.getMonthString(date, true);
                const year = tempDate.getFullYear();
                outputDate = month + ' ' + year;
                break;
            default:
                outputDate = this.getDay(date) + '/' + this.getMonth(date) + '/' + tempDate.getFullYear();
                if (date.indexOf('00Z') > -1) {
                    outputDate += ' ' + ('0' + tempDate.getHours()).slice(-2) + ':' + ('0' + tempDate.getMinutes()).slice(-2);
                }
                break;
        }
        return outputDate;
    }

    private getMonthString(date, abbreviate = false) {
        if (!this.isDate(date)) {
            return "-";
        }

        date = new Date(date);
        let monthString = "";
        const month = date.getMonth();
        switch (month) {
            case 0: monthString = abbreviate === false ? 'January' : 'Jan'; break;
            case 1: monthString = abbreviate === false ? 'February' : 'Feb'; break;
            case 2: monthString = abbreviate === false ? 'March' : 'Mar'; break;
            case 3: monthString = abbreviate === false ? 'April' : 'Apr'; break;
            case 4: monthString = abbreviate === false ? 'May' : 'May'; break;
            case 5: monthString = abbreviate === false ? 'June' : 'Jun'; break;
            case 6: monthString = abbreviate === false ? 'July' : 'Jul'; break;
            case 7: monthString = abbreviate === false ? 'August' : 'Aug'; break;
            case 8: monthString = abbreviate === false ? 'September' : 'Sep'; break;
            case 9: monthString = abbreviate === false ? 'October' : 'Oct'; break;
            case 10: monthString = abbreviate === false ? 'November' : 'Nov'; break;
            case 11: monthString = abbreviate === false ? 'December' : 'Dec'; break;
            default: monthString = " - "; break;
        }
        return monthString;
    }

    private getMonth(date) {
        if (!this.isDate(date)) {
            return "-";
        }

        const dateObj = typeof date !== "string" ? date : new Date(date);
        return ('0' + (dateObj.getMonth() + 1)).slice(-2);
    }

    private getDay(date) {
        if (date === null) {
            return "-";
        }

        const dateObj = typeof date !== "string" ? date : new Date(date);
        return ('0' + dateObj.getDate()).slice(-2);
    }

    public getRandomColor() {
        const letters = '0123456789ABCDEF';
        let color = '#';
        for (let i = 0; i < 6; i++) {
            color += letters[Math.floor(Math.random() * 16)];
        }
        return color;
    }

    public difference(o1, o2) {
        let k, kDiff = {};
        const diff = {};

        for (k in o1) {
            if (!o1.hasOwnProperty(k)) {
            } else if (typeof o1[k] !== 'object' || typeof o2[k] !== 'object') {
                if (!(k in o2) || o1[k] !== o2[k]) {
                    diff[k] = o2[k];
                }
            } else if (kDiff = this.difference(o1[k], o2[k])) {
                diff[k] = kDiff;
            }
        }
        for (k in o2) {
            if (o2.hasOwnProperty(k) && !(k in o1)) {
                diff[k] = o2[k];
            }
        }
        for (k in diff) {
            if (diff.hasOwnProperty(k)) {
                return diff;
            }
        }

        return false;
    }

    public setOverviewDescription(price: Price, override) {
        let name = price.name;
        let amount = price.amount;
        let currency = price.currency;
        let duration;

        if (price.duration_unit) {
            if (price.duration_unit === 'years') {
                duration = price.duration === 1 ? 'Annually' : price.duration + ' Years';
            } else if (price.duration_unit === 'months') {
                duration = price.duration === 1 ? 'Monthly' : price.duration + ' Months';
            } else if (price.duration_unit === 'weeks') {
                duration = price.duration === 1 ? 'weekly' : price.duration + ' Weeks';
            }
        }

        if (!!override) {
            if (!!override.amount) {
                amount = override.amount;
            }

            if (!!override.currency) {
                currency = override.currency;
            }

            if (!!override.name) {
                name = override.name;
            }
        }

        let priceDesc: any;
        // Free price dont have currency or amount
        if (!!amount && !!currency) {
            priceDesc = this.amountAndCurrencyToString(amount, currency);
        }

        if ((!!price && price.free) || (!!override && override.free)) {
            priceDesc = 'FREE';
        }

        return (!!priceDesc ? priceDesc + ' - ' : '') + (!!duration ? duration + ' - ' : '') + name;
    }

    public setDurationDescription(duration: number, durationUnit: string) {
        const durationUnitDesc = this.capitalizeFirstLetter(durationUnit);

        let durationDesc = duration + ' ';
        durationDesc +=
            duration === 1
                ? durationUnitDesc.substr(0, durationUnit.length - 1)
                : durationUnitDesc;

        return durationDesc;
    }

    //#region ARRAY FUNCTIONS
    /**
    * Sorts an array for specified key/property
    * @param array A value representing data you want to sort
    * @param sortByKey A value representing a property you want to sort by.
    * @param asc A value representing sorting order.
    */
    public sortArrayByKey(array, sortByKey, asc?): any {
        if (!!array) {
            array.sort(function (a, b) {
                if (a[sortByKey] < b[sortByKey]) {
                    return !!asc ? 1 : -1;
                }

                if (a[sortByKey] > b[sortByKey]) {
                    return !!asc ? -1 : 1;
                }

                return 0;
            });
        }
    }

    /**
    * Selects unique values for specified key/property
    * @param array A value representing data which you want to filter.
    * @param key A value representing an a property you want to filter.
    */
    public selectDistinctFromArray(array, key): any {
        if (!array) {
            return;
        }

        return Array.from(new Set(array.map((item: any) => item[key])));
    }

    //#endregion

    public scrollToTop() {
        window.scroll({
            top: 0,
            behavior: 'smooth'
        });
    }

    //#endregion

    //#region Toastr Message

    public displayErrorMessage(err: any) {

        const config: GlobalConfig = Object.assign({});
        config.toastClass = 'ngx-toastr fp-custom-error-toastr';
        config.preventDuplicates = true;

        //Check ErrorCode for match with plain text error
        if (Object.values(ErrorCodeType).includes(err)) {            
            err = {
                error:{
                    code: Object.values(ErrorCodeType).find(value => value === err)
                }
            }                      
        }else{            
            this._toastr.error(
                this.currentLocalizedText.toaster_content.message.error,
                this.currentLocalizedText.toaster_content.title.error,
                config);
            return;
        }     
        
        

        if (!!err && !!err.error) {
            if (err.error.code === ErrorCodeType.duplicate) {
                this._toastr.error(
                    this.currentLocalizedText.toaster_content.message.duplicate,
                    this.currentLocalizedText.toaster_content.title.duplicate,
                    config);
            } else if (err.error.code === ErrorCodeType.refundExceed) {
                this._toastr.error(
                    this.currentLocalizedText.toaster_content.message.refund_exceeding_available_amount,
                    this.currentLocalizedText.toaster_content.title.warning,
                    config);
            } else if (err.error.code === ErrorCodeType.periodMismatch) {
                this._toastr.error(
                    this.currentLocalizedText.toaster_content.message.periokd_mismatch,
                    this.currentLocalizedText.toaster_content.title.error,
                    config);
            } else if (err.error.code === ErrorCodeType.periodDataNotAvailable) {
                this._toastr.error(
                    this.currentLocalizedText.toaster_content.message.data_not_available,
                    this.currentLocalizedText.toaster_content.title.error,
                    config);
            } else if (err.error.code === ErrorCodeType.processCard) {
                this._toastr.error(
                    err.error.message,
                    this.currentLocalizedText.toaster_content.title.unable_to_process_card,
                    config);
            } else if (err.error.code === ErrorCodeType.penaltyFailed) {
                this._toastr.error(
                    err.error.message,
                    this.currentLocalizedText.toaster_content.title.contact_penalty_failed,
                    config);
            } else if (err.error.type === ErrorCodeType.validationError && err.error.code === !ErrorCodeType.invalidPassword) {
                this._toastr.error(
                    err.error.message,
                    this.currentLocalizedText.toaster_content.title.error,
                    config);
            } else if (err.error.type === ErrorCodeType.autoAddress) {
                this._toastr.error(
                    this.currentLocalizedText.toaster_content.message.error,
                    'Auto Address',
                    config);
            } else if (err.statusText === ErrorCodeType.badRequest) {
                this._toastr.error(
                    err.error,
                    this.currentLocalizedText.toaster_content.title.error,
                    config)
            } else if (err.error.code === ErrorCodeType.invalidPassword && err.error.type === ErrorCodeType.validationError) {
                this._toastr.error(
                    this.currentLocalizedText.toaster_content.message.invalid_password,
                    this.currentLocalizedText.toaster_content.title.error,
                    config);
            } else if (err.error.code === ErrorCodeType.limitExceeded && err.error.type === ErrorCodeType.validationError) {
                this._toastr.error(
                    this.currentLocalizedText.toaster_content.message.limit_exceeded,
                    this.currentLocalizedText.toaster_content.title.error,
                    config);
            } else if (err.error.code === ErrorCodeType.maximumDurationExceeded) {
                this._toastr.error(
                    this.currentLocalizedText.toaster_content.message.maximum_duration_exceeded,
                    this.currentLocalizedText.toaster_content.title.error,
                    config);
            } else if (err.error.code === ErrorCodeType.paymentTermsDesriptionLimitExceeded) {
                this._toastr.error(
                    this.currentLocalizedText.toaster_content.message.payment_terms_description_limit_exceeded,
                    this.currentLocalizedText.toaster_content.title.error,
                    config);
            } else if (err.error.code === ErrorCodeType.offerNotAvailableDescriptionLimitExceeded) {
                this._toastr.error(
                    this.currentLocalizedText.toaster_content.message.offer_not_available_description_limit_exceeded,
                    this.currentLocalizedText.toaster_content.title.error,
                    config);
            } else if (err.error.code === ErrorCodeType.overlappingHolidayExists) {
                this._toastr.error(
                    this.currentLocalizedText.toaster_content.message.overlapping_holiday_exists,
                    this.currentLocalizedText.toaster_content.title.error,
                    config);
            } else if (err.error.code === ErrorCodeType.PriceNotFoundOrOverThePurchaseLimit) {
                this._toastr.error(
                    this.currentLocalizedText.toaster_content.message.price_not_found_or_over_the_purchase_limit,
                    this.currentLocalizedText.toaster_content.title.error,
                    config);
            } else if (err.error.code === ErrorCodeType.PeriodsAlreadyProcessed) {
                this._toastr.error(
                    this.currentLocalizedText.toaster_content.message.period_already_processed,
                    this.currentLocalizedText.toaster_content.title.error,
                    config);
            } else if (err.error.code === ErrorCodeType.PeriodNotFound) {
                this._toastr.error(
                    this.currentLocalizedText.toaster_content.message.period_not_found,
                    this.currentLocalizedText.toaster_content.title.error,
                    config);
            } else if (err.error.code === ErrorCodeType.maximumDateRangeExceeded) {
                this._toastr.error(
                    this.currentLocalizedText.toaster_content.message.maximum_date_range_exceeded,
                    this.currentLocalizedText.toaster_content.title.error,                    
                    config);
            } else if (err.error.code === ErrorCodeType.reportContainsNoData) {
                this._toastr.error(
                    this.currentLocalizedText.toaster_content.message.report_contains_no_data,
                    this.currentLocalizedText.toaster_content.title.error,
                    config);
            }
            else {
                this._toastr.error(
                    this.currentLocalizedText.toaster_content.message.error,
                    this.currentLocalizedText.toaster_content.title.error,
                    config);

            }
        } else if (!!err && err.status === 401) {
            this._toastr.error(
                this.currentLocalizedText.toaster_content.message.unauthorized,
                this.currentLocalizedText.toaster_content.title.unauthorized,
                config);
        } else if (err?.code === ErrorCodeType.socialRegistration) {
            this._toastr.error(
                this.currentLocalizedText.toaster_content.message.social_login,
                this.currentLocalizedText.toaster_content.title.error,
                config);
        } else if (err?.code === ErrorCodeType.customerNotFound) {
            this._toastr.error(
                this.currentLocalizedText.toaster_content.message.other_login,
                this.currentLocalizedText.toaster_content.title.error,
                config);
        } else if (err === ErrorCodeType.EmailSentSuccessfully) {
            this._toastr.success(
                err,
                this.currentLocalizedText.toaster_content.title.success,
                config);
        } 
        else {
            this._toastr.error(
                this.currentLocalizedText.toaster_content.message.error,
                this.currentLocalizedText.toaster_content.title.error,
                config);
        }
    }

    public displayCustomSuccessMessage(type) {
        const title = this.currentLocalizedText.toaster_content.title.success;
        let message = '';
        if (type === 'email_sent') {
            message = this.currentLocalizedText.toaster_content.message.success_email_sent;
        } else if (type === 'csv_export') {
            message = this.currentLocalizedText.toaster_content.message.success_csv_export;
        } else if (type === 'file_loaded') {
            message = "File Loaded";
        } else if (type === 'csv_download') {
            message = this.currentLocalizedText.toaster_content.message.success_csv_download;
        } else if (type === 'cc_access_token_created') {
            message = this.currentLocalizedText.toaster_content.message.cc_access_token_created;
        }
        else if (type === 'confirm_background_report_generation') {
            message = this.currentLocalizedText.toaster_content.message.emailed_report_generated;
        }                     
        this._toastr.success(message, title);
    }

    public displaySaveSuccessMessage() { 
        this._toastr.success(
            this.currentLocalizedText.toaster_content.message.save,
            this.currentLocalizedText.toaster_content.title.save);
    }

    public displayUserActionSuccessMessage(title: string, message: string) {
        this._toastr.success(
            message,
            title);
    }

    public displayUpdateSuccessMessage() {     
        this._toastr.success(
            this.currentLocalizedText.toaster_content.message.update,
            this.currentLocalizedText.toaster_content.title.update);
    }

    public displayDeleteSuccessMessage() {
        this._toastr.success(
            this.currentLocalizedText.toaster_content.message.delete,
            this.currentLocalizedText.toaster_content.title.delete);
    }

    public displayCustomWarningMessage(type: WarningType, customText?: any) {
        const title = this.currentLocalizedText.toaster_content.title.warning;
        const config: IndividualConfig = Object.assign({});
        config.toastClass = 'ngx-toastr fp-custom-warning-toastr';

        let message = '';
        if (type === WarningType.cannotEditPermissions) {
            message = this.currentLocalizedText.toaster_content.message.warning_cannot_edit_permissions;
        } else if (type === WarningType.minLevelCount) {
            message = this.currentLocalizedText.toaster_content.message.warning_min_member_levels;
        } else if (type === WarningType.onlyAdminCanChangePermissions) {
            message = this.currentLocalizedText.toaster_content.message.warning_only_admin_can_change_permissions;
        } else if (type === WarningType.customerNotFound) {
            message = this.currentLocalizedText.toaster_content.message.customer_not_found;
        } else if (type === WarningType.noPendingDeletetionCustomers) {
            message = this.currentLocalizedText.toaster_content.message.no_pending_deletion_customers;
        } else if (type === WarningType.customerAlreadyDeleted) {
            message = this.currentLocalizedText.toaster_content.message.customer_already_deleted;
        } else if (type === WarningType.countryAlreadySelected) {
            message = this.currentLocalizedText.toaster_content.message.country_already_selected;
        } else if (type === WarningType.csvErrorNoPurchaseFound) {
            message = this.currentLocalizedText.toaster_content.message.csv_error_no_purchases;
        } else if (type === WarningType.codeNotFound) {
            message = this.currentLocalizedText.toaster_content.message.code_not_found;
        } else if (type === WarningType.requiredFieldsNotPopulated) {
            message = this.currentLocalizedText.toaster_content.message.required_fields_not_populated;
        } else if (type === WarningType.noDataFound) {
            message = this.currentLocalizedText.toaster_content.message.no_data_found;
        } else if (type === WarningType.noAvailableDowngradeOption) {
            message = this.currentLocalizedText.toaster_content.message.no_sub_downgrade_option;
        } else if (type === WarningType.noAvailableUpgradeOption) {
            message = this.currentLocalizedText.toaster_content.message.no_sub_upgrade_option;
        } else if (type === WarningType.incorrectDateRange) {
            message = this.currentLocalizedText.toaster_content.message.incorrect_date_range;
        } else if (type === WarningType.valueHasToBeGreaterThan) {
            message = this.currentLocalizedText.toaster_content.message.value_has_to_be_greater_than + (!!customText ? customText : '');
        } else if (type === WarningType.valueHasToBeLowerThan) {
            message = this.currentLocalizedText.toaster_content.message.value_has_to_be_lower_than + (!!customText ? customText : '');
        } else if (type === WarningType.importValidCSVFile) {
            message = this.currentLocalizedText.toaster_content.message.import_valid_csv_file;
        } else if (type === WarningType.defineValidIpAddress) {
            message = this.currentLocalizedText.toaster_content.message.define_valid_ip_address;
        } else if (type === WarningType.incorrectEmailAddress) {
            message = this.currentLocalizedText.toaster_content.message.incorrect_email_address;
        } else if (type === WarningType.couponCodeRequired) {
            message = this.currentLocalizedText.toaster_content.message.coupon_code_required;
        } else if (type === WarningType.addressNotFound) {
            message = this.currentLocalizedText.toaster_content.message.address_not_found;
        } else if (type === WarningType.addressNotUnique) {
            message = this.currentLocalizedText.toaster_content.message.address_not_unique;
        } else if (type === WarningType.addressNotComplete) {
            message = this.currentLocalizedText.toaster_content.message.address_not_complete;
        } else if (type === WarningType.postcodeNotFound) {
            message = this.currentLocalizedText.toaster_content.message.postcode_not_found;
        } else if (type === WarningType.noLinkedPricesFound) {
            message = this.currentLocalizedText.toaster_content.message.no_linked_prices_found;
        } else if (type === WarningType.noSelectedValue) {
            message = this.currentLocalizedText.toaster_content.message.no_selected_value;
        } else if (type === WarningType.cardExpirationDateBeforeFirstDeliveryDate) {
            config.disableTimeOut = true;
            message = this.currentLocalizedText.toaster_content.message.card_expiration_date_before_first_delivery_date;
        } else if (type === WarningType.deliveryHolidaysDatesUpdated) {
            config.disableTimeOut = true;
            message = this.currentLocalizedText.toaster_content.message.delivery_holidays_dates_updated;
        } else if (type === WarningType.emptyFile) {
            message = this.currentLocalizedText.toaster_content.message.empty_file;
            config.toastClass = 'ngx-toastr fp-custom-warning-toastr-empy-file';
        } else if (type === WarningType.noApplicable) {
            message = this.currentLocalizedText.toaster_content.message.no_applicable;
        } else if (type === WarningType.notAllCustomersUpdated) {
            message = this.currentLocalizedText.toaster_content.message.not_all_customers_updated;
        } else if (type === WarningType.contactInfo) {
            message = `Fields missing: ${customText}`
        } else if (type === WarningType.noCredentials) {
            message = `No Credentials Found`
        } else if (type === WarningType.fieldLengthExceeds) {
            message = this.currentLocalizedText.toaster_content.message.field_length_exceeds;
        } else if (type === WarningType.noPurchaseNotification) {
            message = this.currentLocalizedText.toaster_content.message.no_purchase_notification;
        } else if (type === WarningType.ccAccessTokenWarning) {
            message = this.currentLocalizedText.toaster_content.message.cc_access_token_warning;
        } else if (type === WarningType.smptpTestWarning) {
            message = this.currentLocalizedText.toaster_content.message.smtp_test_error_response;
        } else if (type === WarningType.ccAccessTokenRefreshWarning) {
            message = this.currentLocalizedText.toaster_content.message.cc_access_token_refresh_warning;
        } else if (type === WarningType.ccDeleteCredentialWarning) {
            message = this.currentLocalizedText.toaster_content.message.cc_delete_check;
        }

        this._toastr.warning(message, title, config);
    }

    //#endregion

    public getLocaleDateString() {
        const formats = {
            'ar-SA': 'dd/mm/yy',
            'bg-BG': 'dd.M.yyyy',
            'ca-ES': 'dd/mm/yyyy',
            'zh-TW': 'yyyy/M/d',
            'cs-CZ': 'd.M.yyyy',
            'da-DK': 'dd-MM-yyyy',
            'de-DE': 'dd.MM.yyyy',
            'el-GR': 'd/M/yyyy',
            'en-US': 'M/d/yyyy',
            'fi-FI': 'd.M.yyyy',
            'fr-FR': 'dd/mm/yyyy',
            'he-IL': 'dd/mm/yyyy',
            'hu-HU': 'yyyy. MM. dd.',
            'is-IS': 'd.M.yyyy',
            'it-IT': 'dd/mm/yyyy',
            'ja-JP': 'yyyy/MM/dd',
            'ko-KR': 'yyyy-MM-dd',
            'nl-NL': 'd-M-yyyy',
            'nb-NO': 'dd.MM.yyyy',
            'pl-PL': 'yyyy-MM-dd',
            'pt-BR': 'd/M/yyyy',
            'ro-RO': 'dd.MM.yyyy',
            'ru-RU': 'dd.MM.yyyy',
            'hr-HR': 'd.M.yyyy',
            'sk-SK': 'd. M. yyyy',
            'sq-AL': 'yyyy-MM-dd',
            'sv-SE': 'yyyy-MM-dd',
            'th-TH': 'd/M/yyyy',
            'tr-TR': 'dd.MM.yyyy',
            'ur-PK': 'dd/mm/yyyy',
            'id-ID': 'dd/mm/yyyy',
            'uk-UA': 'dd.MM.yyyy',
            'be-BY': 'dd.MM.yyyy',
            'sl-SI': 'd.M.yyyy',
            'et-EE': 'd.MM.yyyy',
            'lv-LV': 'yyyy.MM.dd.',
            'lt-LT': 'yyyy.MM.dd',
            'fa-IR': 'MM/dd/yyyy',
            'vi-VN': 'dd/mm/yyyy',
            'hy-AM': 'dd.MM.yyyy',
            'az-Latn-AZ': 'dd.MM.yyyy',
            'eu-ES': 'yyyy/MM/dd',
            'mk-MK': 'dd.MM.yyyy',
            'af-ZA': 'yyyy/MM/dd',
            'ka-GE': 'dd.MM.yyyy',
            'fo-FO': 'dd-MM-yyyy',
            'hi-IN': 'dd-MM-yyyy',
            'ms-MY': 'dd/mm/yyyy',
            'kk-KZ': 'dd.MM.yyyy',
            'ky-KG': 'dd.MM.yy',
            'sw-KE': 'M/d/yyyy',
            'uz-Latn-UZ': 'dd/MM yyyy',
            'tt-RU': 'dd.MM.yyyy',
            'pa-IN': 'dd-MM-yy',
            'gu-IN': 'dd-MM-yy',
            'ta-IN': 'dd-MM-yyyy',
            'te-IN': 'dd-MM-yy',
            'kn-IN': 'dd-MM-yy',
            'mr-IN': 'dd-MM-yyyy',
            'sa-IN': 'dd-MM-yyyy',
            'mn-MN': 'yy.MM.dd',
            'gl-ES': 'dd/mm/yy',
            'kok-IN': 'dd-MM-yyyy',
            'syr-SY': 'dd/mm/yyyy',
            'dv-MV': 'dd/mm/yy',
            'ar-IQ': 'dd/mm/yyyy',
            'zh-CN': 'yyyy/M/d',
            'de-CH': 'dd.MM.yyyy',
            'en-GB': 'dd/mm/yyyy',
            'es-MX': 'dd/mm/yyyy',
            'fr-BE': 'd/MM/yyyy',
            'it-CH': 'dd.MM.yyyy',
            'nl-BE': 'd/MM/yyyy',
            'nn-NO': 'dd.MM.yyyy',
            'pt-PT': 'dd-MM-yyyy',
            'sr-Latn-CS': 'd.M.yyyy',
            'sv-FI': 'd.M.yyyy',
            'az-Cyrl-AZ': 'dd.MM.yyyy',
            'ms-BN': 'dd/mm/yyyy',
            'uz-Cyrl-UZ': 'dd.MM.yyyy',
            'ar-EG': 'dd/mm/yyyy',
            'zh-HK': 'd/M/yyyy',
            'de-AT': 'dd.MM.yyyy',
            'en-AU': 'd/MM/yyyy',
            'es-ES': 'dd/mm/yyyy',
            'fr-CA': 'yyyy-MM-dd',
            'sr-Cyrl-CS': 'd.M.yyyy',
            'ar-LY': 'dd/mm/yyyy',
            'zh-SG': 'd/M/yyyy',
            'de-LU': 'dd.MM.yyyy',
            'en-CA': 'dd/mm/yyyy',
            'es-GT': 'dd/mm/yyyy',
            'fr-CH': 'dd.MM.yyyy',
            'ar-DZ': 'dd-MM-yyyy',
            'zh-MO': 'd/M/yyyy',
            'de-LI': 'dd.MM.yyyy',
            'en-NZ': 'd/MM/yyyy',
            'es-CR': 'dd/mm/yyyy',
            'fr-LU': 'dd/mm/yyyy',
            'ar-MA': 'dd-MM-yyyy',
            'en-IE': 'dd/mm/yyyy',
            'es-PA': 'MM/dd/yyyy',
            'fr-MC': 'dd/mm/yyyy',
            'ar-TN': 'dd-MM-yyyy',
            'en-ZA': 'yyyy/MM/dd',
            'es-DO': 'dd/mm/yyyy',
            'ar-OM': 'dd/mm/yyyy',
            'en-JM': 'dd/mm/yyyy',
            'es-VE': 'dd/mm/yyyy',
            'ar-YE': 'dd/mm/yyyy',
            'en-029': 'MM/dd/yyyy',
            'es-CO': 'dd/mm/yyyy',
            'ar-SY': 'dd/mm/yyyy',
            'en-BZ': 'dd/mm/yyyy',
            'es-PE': 'dd/mm/yyyy',
            'ar-JO': 'dd/mm/yyyy',
            'en-TT': 'dd/mm/yyyy',
            'es-AR': 'dd/mm/yyyy',
            'ar-LB': 'dd/mm/yyyy',
            'en-ZW': 'M/d/yyyy',
            'es-EC': 'dd/mm/yyyy',
            'ar-KW': 'dd/mm/yyyy',
            'en-PH': 'M/d/yyyy',
            'es-CL': 'dd-MM-yyyy',
            'ar-AE': 'dd/mm/yyyy',
            'es-UY': 'dd/mm/yyyy',
            'ar-BH': 'dd/mm/yyyy',
            'es-PY': 'dd/mm/yyyy',
            'ar-QA': 'dd/mm/yyyy',
            'es-BO': 'dd/mm/yyyy',
            'es-SV': 'dd/mm/yyyy',
            'es-HN': 'dd/mm/yyyy',
            'es-NI': 'dd/mm/yyyy',
            'es-PR': 'dd/mm/yyyy',
            'am-ET': 'd/M/yyyy',
            'tzm-Latn-DZ': 'dd-MM-yyyy',
            'iu-Latn-CA': 'd/MM/yyyy',
            'sma-NO': 'dd.MM.yyyy',
            'mn-Mong-CN': 'yyyy/M/d',
            'gd-GB': 'dd/mm/yyyy',
            'en-MY': 'd/M/yyyy',
            'prs-AF': 'dd/mm/yy',
            'bn-BD': 'dd-MM-yy',
            'wo-SN': 'dd/mm/yyyy',
            'rw-RW': 'M/d/yyyy',
            'qut-GT': 'dd/mm/yyyy',
            'sah-RU': 'MM.dd.yyyy',
            'gsw-FR': 'dd/mm/yyyy',
            'co-FR': 'dd/mm/yyyy',
            'oc-FR': 'dd/mm/yyyy',
            'mi-NZ': 'dd/mm/yyyy',
            'ga-IE': 'dd/mm/yyyy',
            'se-SE': 'yyyy-MM-dd',
            'br-FR': 'dd/mm/yyyy',
            'smn-FI': 'd.M.yyyy',
            'moh-CA': 'M/d/yyyy',
            'arn-CL': 'dd-MM-yyyy',
            'ii-CN': 'yyyy/M/d',
            'dsb-DE': 'd. M. yyyy',
            'ig-NG': 'd/M/yyyy',
            'kl-GL': 'dd-MM-yyyy',
            'lb-LU': 'dd/mm/yyyy',
            'ba-RU': 'dd.MM.yy',
            'nso-ZA': 'yyyy/MM/dd',
            'quz-BO': 'dd/mm/yyyy',
            'yo-NG': 'd/M/yyyy',
            'ha-Latn-NG': 'd/M/yyyy',
            'fil-PH': 'M/d/yyyy',
            'ps-AF': 'dd/mm/yy',
            'fy-NL': 'd-M-yyyy',
            'ne-NP': 'M/d/yyyy',
            'se-NO': 'dd.MM.yyyy',
            'iu-Cans-CA': 'd/M/yyyy',
            'sr-Latn-RS': 'd.M.yyyy',
            'si-LK': 'yyyy-MM-dd',
            'sr-Cyrl-RS': 'd.M.yyyy',
            'lo-LA': 'dd/mm/yyyy',
            'km-KH': 'yyyy-MM-dd',
            'cy-GB': 'dd/mm/yyyy',
            'bo-CN': 'yyyy/M/d',
            'sms-FI': 'd.M.yyyy',
            'as-IN': 'dd-MM-yyyy',
            'ml-IN': 'dd-MM-yy',
            'en-IN': 'dd-MM-yyyy',
            'or-IN': 'dd-MM-yy',
            'bn-IN': 'dd-MM-yy',
            'tk-TM': 'dd.MM.yy',
            'bs-Latn-BA': 'd.M.yyyy',
            'mt-MT': 'dd/mm/yyyy',
            'sr-Cyrl-ME': 'd.M.yyyy',
            'se-FI': 'd.M.yyyy',
            'zu-ZA': 'yyyy/MM/dd',
            'xh-ZA': 'yyyy/MM/dd',
            'tn-ZA': 'yyyy/MM/dd',
            'hsb-DE': 'd. M. yyyy',
            'bs-Cyrl-BA': 'd.M.yyyy',
            'tg-Cyrl-TJ': 'dd.MM.yy',
            'sr-Latn-BA': 'd.M.yyyy',
            'smj-NO': 'dd.MM.yyyy',
            'rm-CH': 'dd/mm/yyyy',
            'smj-SE': 'yyyy-MM-dd',
            'quz-EC': 'dd/mm/yyyy',
            'quz-PE': 'dd/mm/yyyy',
            'hr-BA': 'd.M.yyyy.',
            'sr-Latn-ME': 'd.M.yyyy',
            'sma-SE': 'yyyy-MM-dd',
            'en-SG': 'd/M/yyyy',
            'ug-CN': 'yyyy-M-d',
            'sr-Cyrl-BA': 'd.M.yyyy',
            'es-US': 'M/d/yyyy'
        };

        return formats[navigator.language] || 'dd/mm/yyyy';
        //return 'dd/mm/yyyy';
    }

    downloadCsv(csvString: any, fileName: any) {
        try {
            const reportName = `${fileName} - ${this.getDateStamp(null, false)}.csv`;
            const a = document.createElement('a');
            a.href = 'data:attachment/csv;charset=utf-8,%EF%BB%BF' + encodeURIComponent(csvString);
            a.target = '_blank';
            a.download = reportName;
            document.body.appendChild(a);
            a.click();
            return { success: true };
        } catch (error) {
            throw error;
        }
    }

    async openModalAsync(modalType: ModalType, content?: any, windowSize?: string, config?: any) {
        try {
            windowSize = windowSize ?? ModalWindowSize.Small;
            const modalContent = [ModalType.deletionConfirmation, ModalType.actionConfirmation].includes(modalType) ? ConfirmActionModalComponent : content;
            const modalOptions = { class: windowSize, initialState: { configuration: config } };
            const modalRef: any = this._modalService.show(modalContent, modalOptions);

            return await modalRef.content.onClose.toPromise();
        } catch (error) {
            return error;
        }
    }

    public formatGenericReports(genericReports: any) {
        const reports = [];
        genericReports.forEach((report: any) => {

            reports.push(new GenericReport({
                name: report.name,
                type: "generic",
                report_type: report.report_type,
                report_id: report.id,
                background_generated: report.background_generated,
                report_details: report.reports,
                params: report?.parameters,
            }));
        });
        return reports;
    }

    public displayLinkSuccessMessage() {
        this._toastr.success(
            this.currentLocalizedText.report_success[0].success,
            this.currentLocalizedText.toaster_content.title.save);

    }

    /**
     * @description
     * @param date
     * @returns date in iso string
     */
    private _formatDateIntoIsoString(date: any) {
        const newDate = new Date(date);
        if (newDate.toString() !== "Invalid Date") {
            const year = newDate.getFullYear().toString();
            const month = ("0" + (newDate.getMonth() + 1)).slice(-2); // getMonth() returns index value from 0 - 11 -> (0 = Jan, 1 = Feb ...)
            const day = ("0" + newDate.getDate()).slice(-2);
            const formatedDate = `${year}-${month}-${day}`;
            return formatedDate;
        }
        return null;
    }

    private _formatTimeIntoIsoString(date: any) {
        const newDate = new Date(date);
        if (newDate.toString() !== "Invalid Date") {
            const hours = ("0" + newDate.getHours()).slice(-2);
            const minutes = ("0" + newDate.getMinutes()).slice(-2);
            const formatedIsoTime = `T${hours}:${minutes}:00.000Z`;
            return formatedIsoTime;
        }
        return null;
    }

    /**
     * @description format date into iso string
     * @param requestDate
     * @param includeDefaultTime - boolean value to choose if iso time should be included in iso string
     * @returns
     */
    public formatDateForRequest(requestDate: any, includeDefaultTime: boolean, applyDefault: boolean = false) {
        const newDate = new Date(requestDate);
        if (newDate.toString() !== "Invalid Date" && newDate.getFullYear() < 3000) {
            let formatedDate = `${this._formatDateIntoIsoString(requestDate)}`;
            if (includeDefaultTime) {
                const defultIsoTime = "T00:00:00.000Z";
                formatedDate += defultIsoTime;
            }
            return formatedDate;
        }

        if (applyDefault) {
            let returnedDate = this._formatDateIntoIsoString(new Date());
            if (includeDefaultTime) {
                const defultIsoTime = "T00:00:00.000Z";
                returnedDate += defultIsoTime;
            }
            return returnedDate;
        } else {
            return null;
        }
    }

    /**
     * @description format datetime into iso string. Using vendor timezone offset in minutes insted of user local time zone.
     * @param requestDate
     * @returns
     */
    public formatDateTimeForRequest(requestDate: any) {
        const venedorTimeOffset = this._globals.vendorTimeZoneOffSetInMinutes; // venedorTimeOffset - is number of minutes between vendor time and utc time
        const newDate = new Date(requestDate);
        if (newDate.toString() !== "Invalid Date") {
            const dev = this._formatDateIntoIsoString(requestDate);
            newDate.setTime(newDate.getTime() - venedorTimeOffset * 60000);
            const formatedDateAsIsoString = `${dev}${this._formatTimeIntoIsoString(newDate)}`;
            return formatedDateAsIsoString;
        }
    }

    /**
     * @description format datetime into vendor timezone
     * @param responseDate
     * @returns
     */
    public formatDateTimeFromResponse(responseDate: any) {
        const venedorTimeOffset = this._globals.vendorTimeZoneOffSetInMinutes; // venedorTimeOffset - is difference of minutes between vendor time and utc time
        const newDate = new Date(responseDate);
        if (newDate.toString() !== "Invalid Date") {
            newDate.setTime(newDate.getTime() + (venedorTimeOffset * 60000));
            const userTimezoneOffset = newDate.getTimezoneOffset() * 60000; // userTimezoneOffset - return timezone difference in minutes
            return new Date(newDate.getTime() + userTimezoneOffset);
        }
        return null;
    }

    /**
     * @description return today in utc format. Ignores local time of pc/machine
     * @returns today as DATE in UTC Format
     */
    public getTodayInUTC() {
        const now = new Date();
        return new Date(now.getTime() + (now.getTimezoneOffset() * 60000));
    }

    public sortDataFromMostRecent(data: any, sortByParamName: string) {
        return data.sort((n1, n2) => n1[sortByParamName] > n2[sortByParamName] ? -1 : 1);
    }

    public sortDataStringValuesFirst(data: any, sortByParamName: string) {
        const regexExpression = /\d/;
        return [
            ...data.filter(a => !regexExpression.test(a[sortByParamName])),
            ...data.filter(a => regexExpression.test(a[sortByParamName])),
        ]
    }

    public subtractDays(date: Date, days: number) {
        const result = new Date(date);
        result.setDate(result.getDate() - days);
        return result;
    }

    public capitalizeEachWordOfString(data: string, splitChar: string) {
        const words = data.split(splitChar);
        words.forEach((word: string, index: number) => {
            words[index] = this.capitalizeFirstLetter(word);
        });
        const joinedWords = words.join();
        return joinedWords.replace(",", " ");
    }

    /**
    * @description Define wizard steps
    * @param types steps to be included in a wizar modal
    * @param value wizard modal input data
    */
    public createWizardSteps(types: Array<WizardContentType>, value: any) {
        const wizardSteps: Array<any> = new Array<any>();
        types.forEach((type: WizardContentType) => {
            wizardSteps.push(new WizardContent(type, value));
        });

        return wizardSteps;
    }

    public populateWizardStepPenalty(modalState: any, wizardSteps: Array<WizardContentType>, value: any) {
        if (!!modalState.initial_state.data.purchase.is_contract
            && (value?.purchase?.contract_periods !== value?.purchase?.current_contract_period)) {
            wizardSteps.push(WizardContentType.penalty);
        }
    }

    public getYesterday(): Date {
        const yesterday = new Date();
        yesterday.setDate(yesterday.getDate() - 1);
        return yesterday;
    }

    public openUrlInNewTab(urlPath: string) {
        const baseUrl = window.location.protocol + '//' + window.location.host;
        const url = baseUrl + `/clients/${this._globals?.user?.current_client_id}` + urlPath;

        window.open(url, '_blank');
    }
}
