export class FormControlSettings {
    model: any;
    parentForm: any;
    controlName: any;
    isRequired: boolean;
    setCheckbox: any = true;
    localizedText?: any;
    displayErrors: boolean;

    constructor(values: Object = {}) {
        Object.assign(this, values);
    }
}
