export const countryCodes = [
    {
        "name": "Afghanistan",
        "code": "AF",
        "zone": "Asia",
        "translation_source": "component_texts.countries.0"
    },
    {
        "name": "Aland Islands",
        "code": "AX",
        "zone": "Europe",
        "translation_source": "component_texts.countries.1"
    },
    {
        "name": "Albania",
        "code": "AL",
        "zone": "Europe",
        "translation_source": "component_texts.countries.2"
    },
    {
        "name": "Algeria",
        "code": "DZ",
        "zone": "Africa",
        "translation_source": "component_texts.countries.3"
    },
    {
        "name": "American Samoa",
        "code": "AS",
        "zone": null,
        "translation_source": "component_texts.countries.4"
    },
    {
        "name": "Andorra",
        "code": "AD",
        "zone": "Europe",
        "translation_source": "component_texts.countries.5"
    },
    {
        "name": "Angola",
        "code": "AO",
        "zone": "Africa",
        "translation_source": "component_texts.countries.6"
    },
    {
        "name": "Anguilla",
        "code": "AI",
        "zone": null,
        "translation_source": "component_texts.countries.7"
    },
    {
        "name": "Antarctica",
        "code": "AQ",
        "zone": null,
        "translation_source": "component_texts.countries.8"
    },
    {
        "name": "Antigua and Barbuda",
        "code": "AG",
        "zone": null,
        "translation_source": "component_texts.countries.9"
    },
    {
        "name": "Argentina",
        "code": "AR",
        "zone": "South America",
        "translation_source": "component_texts.countries.10"
    },
    {
        "name": "Armenia",
        "code": "AM",
        "zone": "Asia",
        "translation_source": "component_texts.countries.11"
    },
    {
        "name": "Aruba",
        "code": "AW",
        "zone": null,
        "translation_source": "component_texts.countries.12"
    },
    {
        "name": "Australia",
        "code": "AU",
        "zone": "Oceania",
        "translation_source": "component_texts.countries.13"
    },
    {
        "name": "Austria",
        "code": "AT",
        "zone": "Europe",
        "currency": "EUR", 
        "translation_source": "component_texts.countries.14"
    },
    {
        "name": "Azerbaijan",
        "code": "AZ",
        "zone": "Asia",
        "translation_source": "component_texts.countries.15"
    },
    {
        "name": "Bahamas",
        "code": "BS",
        "zone": "North America",
        "translation_source": "component_texts.countries.16"
    },
    {
        "name": "Bahrain",
        "code": "BH",
        "zone": "Asia",
        "translation_source": "component_texts.countries.17"
    },
    {
        "name": "Bangladesh",
        "code": "BD",
        "zone": "Asia",
        "translation_source": "component_texts.countries.18"
    },
    {
        "name": "Barbados",
        "code": "BB",
        "zone": null,
        "translation_source": "component_texts.countries.19"
    },
    {
        "name": "Belarus",
        "code": "BY",
        "zone": "Europe",
        "translation_source": "component_texts.countries.20"
    },
    {
        "name": "Belgium",
        "code": "BE",
        "zone": "Europe",
        "currency": "EUR",
        "translation_source": "component_texts.countries.21"
    },
    {
        "name": "Belize",
        "code": "BZ",
        "zone": "North America",
        "translation_source": "component_texts.countries.22"
    },
    {
        "name": "Benin",
        "code": "BJ",
        "zone": "Africa",
        "translation_source": "component_texts.countries.23"
    },
    {
        "name": "Bermuda",
        "code": "BM",
        "zone": null,
        "translation_source": "component_texts.countries.24"
    },
    {
        "name": "Bhutan",
        "code": "BT",
        "zone": "Asia",
        "translation_source": "component_texts.countries.25"
    },
    {
        "name": "Bolivia",
        "code": "BO",
        "zone": "South America",
        "translation_source": "component_texts.countries.26"
    },
    {
        "name": "Bosnia and Herz.",
        "code": "BA",
        "zone": "Europe",
        "translation_source": "component_texts.countries.27"
    },
    {
        "name": "Botswana",
        "code": "BW",
        "zone": "Africa",
        "translation_source": "component_texts.countries.28"
    },
    {
        "name": "Bouvet Island",
        "code": "BV",
        "zone": null,
        "translation_source": "component_texts.countries.29"
    },
    {
        "name": "Brazil",
        "code": "BR",
        "zone": "South America",
        "translation_source": "component_texts.countries.30"
    },
    {
        "name": "British Indian Ocean Territory",
        "code": "IO",
        "zone": null,
        "translation_source": "component_texts.countries.31"
    },
    {
        "name": "British Virgin Islands",
        "code": "VG",
        "zone": null,
        "translation_source": "component_texts.countries.32"
    },
    {
        "name": "Brunei Darussalam",
        "code": "BN",
        "zone": "Asia",
        "translation_source": "component_texts.countries.33"
    },
    {
        "name": "Bulgaria",
        "code": "BG",
        "zone": "Europe",
        "translation_source": "component_texts.countries.34"
    },
    {
        "name": "Burkina Faso",
        "code": "BF",
        "zone": "Africa",
        "translation_source": "component_texts.countries.35"
    },
    {
        "name": "Burundi",
        "code": "BI",
        "zone": "Africa",
        "translation_source": "component_texts.countries.36"
    },
    {
        "name": "Cambodia",
        "code": "KH",
        "zone": "Asia",
        "translation_source": "component_texts.countries.37"
    },
    {
        "name": "Cameroon",
        "code": "CM",
        "zone": "Africa",
        "translation_source": "component_texts.countries.38"
    },
    {
        "name": "Canada",
        "code": "CA",
        "zone": "North America",
        "translation_source": "component_texts.countries.39"
    },
    {
        "name": "Cape Verde",
        "code": "CV",
        "zone": "Africa",
        "translation_source": "component_texts.countries.40"
    },
    {
        "name": "Cayman Islands",
        "code": "KY",
        "zone": null,
        "translation_source": "component_texts.countries.41"
    },
    {
        "name": "Central African Rep.",
        "code": "CF",
        "zone": "Africa",
        "translation_source": "component_texts.countries.42"
    },
    {
        "name": "Chad",
        "code": "TD",
        "zone": "Africa",
        "translation_source": "component_texts.countries.43"
    },
    {
        "name": "Chile",
        "code": "CL",
        "zone": "South America",
        "translation_source": "component_texts.countries.44"
    },
    {
        "name": "China",
        "code": "CN",
        "zone": "Asia",
        "translation_source": "component_texts.countries.45"
    },
    {
        "name": "Christmas Island",
        "code": "CX",
        "zone": null,
        "translation_source": "component_texts.countries.46"
    },
    {
        "name": "Cocos (Keeling) Islands",
        "code": "CC",
        "zone": null,
        "translation_source": "component_texts.countries.47"
    },
    {
        "name": "Colombia",
        "code": "CO",
        "zone": "South America",
        "translation_source": "component_texts.countries.48"
    },
    {
        "name": "Comoros",
        "code": "KM",
        "zone": "Africa",
        "translation_source": "component_texts.countries.49"
    },
    {
        "name": "Congo (Brazzaville)",
        "code": "CG",
        "zone": "Africa",
        "translation_source": "component_texts.countries.50"
    },
    {
        "name": "Congo",
        "code": "CD",
        "zone": "Africa",
        "translation_source": "component_texts.countries.51"
    },
    {
        "name": "Cook Islands",
        "code": "CK",
        "zone": null,
        "translation_source": "component_texts.countries.52"
    },
    {
        "name": "Costa Rica",
        "code": "CR",
        "zone": "North America",
        "translation_source": "component_texts.countries.53"
    },
    {
        "name": "Croatia",
        "code": "HR",
        "zone": "Europe",
        "translation_source": "component_texts.countries.54"
    },
    {
        "name": "Cuba",
        "code": "CU",
        "zone": "North America",
        "translation_source": "component_texts.countries.55"
    },
    {
        "name": "Cyprus",
        "code": "CY",
        "zone": "Asia;Euro Zone;",
        "translation_source": "component_texts.countries.56"
    },
    {
        "name": "Czech Rep.",
        "code": "CZ",
        "zone": "Europe",
        "translation_source": "component_texts.countries.57"
    },
    {
        "name": "Côte d'Ivoire",
        "code": "CI",
        "zone": "Africa",
        "translation_source": "component_texts.countries.58"
    },
    {
        "name": "Denmark",
        "code": "DK",
        "zone": "Europe",
        "translation_source": "component_texts.countries.59"
    },
    {
        "name": "Djibouti",
        "code": "DJ",
        "zone": "Africa",
        "translation_source": "component_texts.countries.60"
    },
    {
        "name": "Dominica",
        "code": "DM",
        "zone": "North America",
        "translation_source": "component_texts.countries.61"
    },
    {
        "name": "Dominican Republic",
        "code": "DO",
        "zone": "North America",
        "translation_source": "component_texts.countries.62"
    },
    {
        "name": "Ecuador",
        "code": "EC",
        "zone": "South America",
        "translation_source": "component_texts.countries.63"
    },
    {
        "name": "Egypt",
        "code": "EG",
        "zone": "Africa",
        "translation_source": "component_texts.countries.64"
    },
    {
        "name": "El Salvador",
        "code": "SV",
        "zone": "North America",
        "translation_source": "component_texts.countries.65"
    },
    {
        "name": "Equatorial Guinea",
        "code": "GQ",
        "zone": "Africa",
        "translation_source": "component_texts.countries.66"
    },
    {
        "name": "Eritrea",
        "code": "ER",
        "zone": "Africa",
        "translation_source": "component_texts.countries.67"
    },
    {
        "name": "Estonia",
        "code": "EE",
        "zone": "Europe",
        "currency": "EUR",
        "translation_source": "component_texts.countries.68"
    },
    {
        "name": "Ethiopia",
        "code": "ET",
        "zone": "Africa",
        "translation_source": "component_texts.countries.69"
    },
    {
        "name": "Falkland Islands (Malvinas)",
        "code": "FK",
        "zone": "South America",
        "translation_source": "component_texts.countries.70"
    },
    {
        "name": "Faroe Islands",
        "code": "FO",
        "zone": "Europe",
        "translation_source": "component_texts.countries.71"
    },
    {
        "name": "Fiji",
        "code": "FJ",
        "zone": "Oceania",
        "translation_source": "component_texts.countries.72"
    },
    {
        "name": "Finland",
        "code": "FI",
        "zone": "Europe",
        "currency": "EUR",
        "translation_source": "component_texts.countries.73"
    },
    {
        "name": "France",
        "code": "FR",
        "zone": "Europe",
        "currency": "EUR",
        "translation_source": "component_texts.countries.74"
    },
    {
        "name": "French Guiana",
        "code": "GF",
        "zone": null,
        "translation_source": "component_texts.countries.75"
    },
    {
        "name": "French Polynesia",
        "code": "PF",
        "zone": "Oceania",
        "translation_source": "component_texts.countries.76"
    },
    {
        "name": "French Southern Territories",
        "code": "TF",
        "zone": null,
        "translation_source": "component_texts.countries.77"
    },
    {
        "name": "Gabon",
        "code": "GA",
        "zone": "Africa",
        "translation_source": "component_texts.countries.78"
    },
    {
        "name": "Gambia",
        "code": "GM",
        "zone": "Africa",
        "translation_source": "component_texts.countries.79"
    },
    {
        "name": "Georgia",
        "code": "GE",
        "zone": "Asia",
        "translation_source": "component_texts.countries.80"
    },
    {
        "name": "Germany",
        "code": "DE",
        "zone": "Europe",
        "currency": "EUR",
        "translation_source": "component_texts.countries.81"
    },
    {
        "name": "Ghana",
        "code": "GH",
        "zone": "Africa",
        "translation_source": "component_texts.countries.82"
    },
    {
        "name": "Gibraltar",
        "code": "GI",
        "zone": null,
        "translation_source": "component_texts.countries.83"
    },
    {
        "name": "Greece",
        "code": "GR",
        "zone": "Europe",
        "currency": "EUR",
        "translation_source": "component_texts.countries.84"
    },
    {
        "name": "Greenland",
        "code": "GL",
        "zone": "North America",
        "translation_source": "component_texts.countries.85"
    },
    {
        "name": "Grenada",
        "code": "GD",
        "zone": null,
        "translation_source": "component_texts.countries.86"
    },
    {
        "name": "Guadeloupe",
        "code": "GP",
        "zone": null,
        "translation_source": "component_texts.countries.87"
    },
    {
        "name": "Guam",
        "code": "GU",
        "zone": "Oceania",
        "translation_source": "component_texts.countries.88"
    },
    {
        "name": "Guatemala",
        "code": "GT",
        "zone": "North America",
        "translation_source": "component_texts.countries.89"
    },
    {
        "name": "Guernsey",
        "code": "GG",
        "zone": "Europe",
        "translation_source": "component_texts.countries.90"
    },
    {
        "name": "Guinea",
        "code": "GN",
        "zone": "Africa",
        "translation_source": "component_texts.countries.91"
    },
    {
        "name": "Guinea-Bissau",
        "code": "GW",
        "zone": "Africa",
        "translation_source": "component_texts.countries.92"
    },
    {
        "name": "Guyana",
        "code": "GY",
        "zone": "South America",
        "translation_source": "component_texts.countries.93"
    },
    {
        "name": "Haiti",
        "code": "HT",
        "zone": "North America",
        "translation_source": "component_texts.countries.94"
    },
    {
        "name": "Heard Island and Mcdonald Islands",
        "code": "HM",
        "zone": null,
        "translation_source": "component_texts.countries.95"
    },
    {
        "name": "Holy See (Vatican City State)",
        "code": "VA",
        "zone": null,
        "translation_source": "component_texts.countries.96"
    },
    {
        "name": "Honduras",
        "code": "HN",
        "zone": "North America",
        "translation_source": "component_texts.countries.97"
    },
    {
        "name": "Hong Kong, Special Administrative Region of China",
        "code": "HK",
        "zone": "Asia",
        "translation_source": "component_texts.countries.98"
    },
    {
        "name": "Hungary",
        "code": "HU",
        "zone": "Europe",
        "translation_source": "component_texts.countries.99"
    },
    {
        "name": "Iceland",
        "code": "IS",
        "zone": "Europe",
        "translation_source": "component_texts.countries.100"
    },
    {
        "name": "India",
        "code": "IN",
        "zone": "Asia",
        "translation_source": "component_texts.countries.101"
    },
    {
        "name": "Indonesia",
        "code": "ID",
        "zone": "Asia",
        "translation_source": "component_texts.countries.102"
    },
    {
        "name": "Iran",
        "code": "IR",
        "zone": "Asia",
        "translation_source": "component_texts.countries.103"
    },
    {
        "name": "Iraq",
        "code": "IQ",
        "zone": "Asia",
        "translation_source": "component_texts.countries.104"
    },
    {
        "name": "Ireland",
        "code": "IE",
        "zone": "Europe",
        "currency": "EUR",
        "translation_source": "component_texts.countries.105"
    },
    {
        "name": "Isle of Man",
        "code": "IM",
        "zone": "Europe",
        "translation_source": "component_texts.countries.106"
    },
    {
        "name": "Israel",
        "code": "IL",
        "zone": "Asia",
        "translation_source": "component_texts.countries.107"
    },
    {
        "name": "Italy",
        "code": "IT",
        "zone": "Europe",
        "currency": "EUR",
        "translation_source": "component_texts.countries.108"
    },
    {
        "name": "Jamaica",
        "code": "JM",
        "zone": "North America",
        "translation_source": "component_texts.countries.109"
    },
    {
        "name": "Japan",
        "code": "JP",
        "zone": "Asia",
        "translation_source": "component_texts.countries.110"
    },
    {
        "name": "Jersey",
        "code": "JE",
        "zone": "Europe",
        "translation_source": "component_texts.countries.111"
    },
    {
        "name": "Jordan",
        "code": "JO",
        "zone": "Asia",
        "translation_source": "component_texts.countries.112"
    },
    {
        "name": "Kazakhstan",
        "code": "KZ",
        "zone": "Asia",
        "translation_source": "component_texts.countries.113"
    },
    {
        "name": "Kenya",
        "code": "KE",
        "zone": "Africa",
        "translation_source": "component_texts.countries.114"
    },
    {
        "name": "Kiribati",
        "code": "KI",
        "zone": "Oceania",
        "translation_source": "component_texts.countries.115"
    },
    {
        "name": "Korea",
        "code": "KR",
        "zone": "Asia",
        "translation_source": "component_texts.countries.116"
    },
    {
        "name": "Dem. Rep. Korea",
        "code": "KP",
        "zone": "Asia",
        "translation_source": "component_texts.countries.117"
    },
    {
        "name": "Kuwait",
        "code": "KW",
        "zone": "Asia",
        "translation_source": "component_texts.countries.118"
    },
    {
        "name": "Kyrgyzstan",
        "code": "KG",
        "zone": "Asia",
        "translation_source": "component_texts.countries.119"
    },
    {
        "name": "Lao PDR",
        "code": "LA",
        "zone": "Asia",
        "translation_source": "component_texts.countries.120"
    },
    {
        "name": "Latvia",
        "code": "LV",
        "zone": "Europe",
        "currency": "EUR",
        "translation_source": "component_texts.countries.121"
    },
    {
        "name": "Lebanon",
        "code": "LB",
        "zone": "Asia",
        "translation_source": "component_texts.countries.122"
    },
    {
        "name": "Lesotho",
        "code": "LS",
        "zone": "Africa",
        "translation_source": "component_texts.countries.123"
    },
    {
        "name": "Liberia",
        "code": "LR",
        "zone": "Africa",
        "translation_source": "component_texts.countries.124"
    },
    {
        "name": "Libya",
        "code": "LY",
        "zone": "Africa",
        "translation_source": "component_texts.countries.125"
    },
    {
        "name": "Liechtenstein",
        "code": "LI",
        "zone": "Europe",
        "translation_source": "component_texts.countries.126"
    },
    {
        "name": "Lithuania",
        "code": "LT",
        "zone": "Europe",
        "currency": "EUR",
        "translation_source": "component_texts.countries.127"
    },
    {
        "name": "Luxembourg",
        "code": "LU",
        "zone": "Europe",
        "currency": "EUR",
        "translation_source": "component_texts.countries.128"
    },
    {
        "name": "Macao, Special Administrative Region of China",
        "code": "MO",
        "zone": null,
        "translation_source": "component_texts.countries.129"
    },
    {
        "name": "Macedonia",
        "code": "MK",
        "zone": "Europe",
        "translation_source": "component_texts.countries.130"
    },
    {
        "name": "Madagascar",
        "code": "MG",
        "zone": "Africa",
        "translation_source": "component_texts.countries.131"
    },
    {
        "name": "Malawi",
        "code": "MW",
        "zone": "Africa",
        "translation_source": "component_texts.countries.132"
    },
    {
        "name": "Malaysia",
        "code": "MY",
        "zone": "Asia",
        "translation_source": "component_texts.countries.133"
    },
    {
        "name": "Maldives",
        "code": "MV",
        "zone": null,
        "translation_source": "component_texts.countries.134"
    },
    {
        "name": "Mali",
        "code": "ML",
        "zone": "Africa",
        "translation_source": "component_texts.countries.135"
    },
    {
        "name": "Malta",
        "code": "MT",
        "zone": "Europe",
        "currency": "EUR",
        "translation_source": "component_texts.countries.136"
    },
    {
        "name": "Marshall Islands",
        "code": "MH",
        "zone": null,
        "translation_source": "component_texts.countries.137"
    },
    {
        "name": "Martinique",
        "code": "MQ",
        "zone": null,
        "translation_source": "component_texts.countries.138"
    },
    {
        "name": "Mauritania",
        "code": "MR",
        "zone": "Africa",
        "translation_source": "component_texts.countries.139"
    },
    {
        "name": "Mauritius",
        "code": "MU",
        "zone": null,
        "translation_source": "component_texts.countries.140"
    },
    {
        "name": "Mayotte",
        "code": "YT",
        "zone": null,
        "translation_source": "component_texts.countries.141"
    },
    {
        "name": "Mexico",
        "code": "MX",
        "zone": "North America",
        "translation_source": "component_texts.countries.142"
    },
    {
        "name": "Micronesia, Federated States of",
        "code": "FM",
        "zone": "Oceania",
        "translation_source": "component_texts.countries.143"
    },
    {
        "name": "Moldova",
        "code": "MD",
        "zone": "Europe",
        "translation_source": "component_texts.countries.144"
    },
    {
        "name": "Monaco",
        "code": "MC",
        "zone": null,
        "translation_source": "component_texts.countries.145"
    },
    {
        "name": "Mongolia",
        "code": "MN",
        "zone": "Asia",
        "translation_source": "component_texts.countries.146"
    },
    {
        "name": "Montenegro",
        "code": "ME",
        "zone": "Europe",
        "translation_source": "component_texts.countries.147"
    },
    {
        "name": "Montserrat",
        "code": "MS",
        "zone": null,
        "translation_source": "component_texts.countries.148"
    },
    {
        "name": "Morocco",
        "code": "MA",
        "zone": "Africa",
        "translation_source": "component_texts.countries.149"
    },
    {
        "name": "Mozambique",
        "code": "MZ",
        "zone": "Africa",
        "translation_source": "component_texts.countries.150"
    },
    {
        "name": "Myanmar",
        "code": "MM",
        "zone": "Asia",
        "translation_source": "component_texts.countries.151"
    },
    {
        "name": "Namibia",
        "code": "NA",
        "zone": "Africa",
        "translation_source": "component_texts.countries.152"
    },
    {
        "name": "Nauru",
        "code": "NR",
        "zone": null,
        "translation_source": "component_texts.countries.153"
    },
    {
        "name": "Nepal",
        "code": "NP",
        "zone": "Asia",
        "translation_source": "component_texts.countries.154"
    },
    {
        "name": "Netherlands",
        "code": "NL",
        "zone": "Europe",
        "currency": "EUR",
        "translation_source": "component_texts.countries.155"
    },
    {
        "name": "Netherlands Antilles",
        "code": "AN",
        "zone": null,
        "translation_source": "component_texts.countries.156"
    },
    {
        "name": "New Caledonia",
        "code": "NC",
        "zone": "Oceania",
        "translation_source": "component_texts.countries.157"
    },
    {
        "name": "New Zealand",
        "code": "NZ",
        "zone": "Oceania",
        "translation_source": "component_texts.countries.158"
    },
    {
        "name": "Nicaragua",
        "code": "NI",
        "zone": "North America",
        "translation_source": "component_texts.countries.159"
    },
    {
        "name": "Niger",
        "code": "NE",
        "zone": "Africa",
        "translation_source": "component_texts.countries.160"
    },
    {
        "name": "Nigeria",
        "code": "NG",
        "zone": "Africa",
        "translation_source": "component_texts.countries.161"
    },
    {
        "name": "Niue",
        "code": "NU",
        "zone": "Oceania",
        "translation_source": "component_texts.countries.162"
    },
    {
        "name": "Norfolk Island",
        "code": "NF",
        "zone": null,
        "translation_source": "component_texts.countries.163"
    },
    {
        "name": "Northern Mariana Islands",
        "code": "MP",
        "zone": null,
        "translation_source": "component_texts.countries.164"
    },
    {
        "name": "Norway",
        "code": "NO",
        "zone": "Europe",
        "translation_source": "component_texts.countries.165"
    },
    {
        "name": "Oman",
        "code": "OM",
        "zone": "Asia",
        "translation_source": "component_texts.countries.166"
    },
    {
        "name": "Pakistan",
        "code": "PK",
        "zone": "Asia",
        "translation_source": "component_texts.countries.167"
    },
    {
        "name": "Palau",
        "code": "PW",
        "zone": "Oceania",
        "translation_source": "component_texts.countries.168"
    },
    {
        "name": "Palestinian Territory, Occupied",
        "code": "PS",
        "zone": "Asia",
        "translation_source": "component_texts.countries.169"
    },
    {
        "name": "Panama",
        "code": "PA",
        "zone": "North America",
        "translation_source": "component_texts.countries.170"
    },
    {
        "name": "Papua New Guinea",
        "code": "PG",
        "zone": "Oceania",
        "translation_source": "component_texts.countries.171"
    },
    {
        "name": "Paraguay",
        "code": "PY",
        "zone": "South America",
        "translation_source": "component_texts.countries.172"
    },
    {
        "name": "Peru",
        "code": "PE",
        "zone": "South America",
        "translation_source": "component_texts.countries.173"
    },
    {
        "name": "Philippines",
        "code": "PH",
        "zone": "Asia",
        "translation_source": "component_texts.countries.174"
    },
    {
        "name": "Pitcairn",
        "code": "PN",
        "zone": null,
        "translation_source": "component_texts.countries.175"
    },
    {
        "name": "Poland",
        "code": "PL",
        "zone": "Europe",
        "translation_source": "component_texts.countries.176"
    },
    {
        "name": "Portugal",
        "code": "PT",
        "zone": "Europe",
        "currency": "EUR",
        "translation_source": "component_texts.countries.177"
    },
    {
        "name": "Puerto Rico",
        "code": "PR",
        "zone": "North America",
        "translation_source": "component_texts.countries.178"
    },
    {
        "name": "Qatar",
        "code": "QA",
        "zone": "Asia",
        "translation_source": "component_texts.countries.179"
    },
    {
        "name": "Romania",
        "code": "RO",
        "zone": "Europe",
        "translation_source": "component_texts.countries.180"
    },
    {
        "name": "Russia",
        "code": "RU",
        "zone": "Europe",
        "translation_source": "component_texts.countries.181"
    },
    {
        "name": "Rwanda",
        "code": "RW",
        "zone": "Africa",
        "translation_source": "component_texts.countries.182"
    },
    {
        "name": "Réunion",
        "code": "RE",
        "zone": null,
        "translation_source": "component_texts.countries.183"
    },
    {
        "name": "Saint Helena",
        "code": "SH",
        "zone": null,
        "translation_source": "component_texts.countries.184"
    },
    {
        "name": "Saint Kitts and Nevis",
        "code": "KN",
        "zone": null,
        "translation_source": "component_texts.countries.185"
    },
    {
        "name": "Saint Lucia",
        "code": "LC",
        "zone": "North America",
        "translation_source": "component_texts.countries.186"
    },
    {
        "name": "Saint Pierre and Miquelon",
        "code": "PM",
        "zone": null,
        "translation_source": "component_texts.countries.187"
    },
    {
        "name": "Saint Vincent and Grenadines",
        "code": "VC",
        "zone": null,
        "translation_source": "component_texts.countries.188"
    },
    {
        "name": "Saint-Barthélemy",
        "code": "BL",
        "zone": null,
        "translation_source": "component_texts.countries.189"
    },
    {
        "name": "Saint-Martin (French part)",
        "code": "MF",
        "zone": null,
        "translation_source": "component_texts.countries.190"
    },
    {
        "name": "Samoa",
        "code": "WS",
        "zone": "Oceania",
        "translation_source": "component_texts.countries.191"
    },
    {
        "name": "San Marino",
        "code": "SM",
        "zone": "Europe",
        "translation_source": "component_texts.countries.192"
    },
    {
        "name": "Sao Tome and Principe",
        "code": "ST",
        "zone": "Africa",
        "translation_source": "component_texts.countries.193"
    },
    {
        "name": "Saudi Arabia",
        "code": "SA",
        "zone": "Asia",
        "translation_source": "component_texts.countries.194"
    },
    {
        "name": "Senegal",
        "code": "SN",
        "zone": "Africa",
        "translation_source": "component_texts.countries.195"
    },
    {
        "name": "Serbia",
        "code": "RS",
        "zone": "Europe",
        "translation_source": "component_texts.countries.196"
    },
    {
        "name": "Seychelles",
        "code": "SC",
        "zone": null,
        "translation_source": "component_texts.countries.197"
    },
    {
        "name": "Sierra Leone",
        "code": "SL",
        "zone": "Africa",
        "translation_source": "component_texts.countries.198"
    },
    {
        "name": "Singapore",
        "code": "SG",
        "zone": "Asia",
        "translation_source": "component_texts.countries.199"
    },
    {
        "name": "Slovakia",
        "code": "SK",
        "zone": "Europe",
        "currency": "EUR",
        "translation_source": "component_texts.countries.200"
    },
    {
        "name": "Slovenia",
        "code": "SI",
        "zone": "Europe",
        "currency": "EUR",
        "translation_source": "component_texts.countries.201"
    },
    {
        "name": "Solomon Islands",
        "code": "SB",
        "zone": "Oceania",
        "translation_source": "component_texts.countries.202"
    },
    {
        "name": "Somalia",
        "code": "SO",
        "zone": "Africa",
        "translation_source": "component_texts.countries.203"
    },
    {
        "name": "South Africa",
        "code": "ZA",
        "zone": "Africa",
        "translation_source": "component_texts.countries.204"
    },
    {
        "name": "South Georgia and the South Sandwich Islands",
        "code": "GS",
        "zone": null,
        "translation_source": "component_texts.countries.205"
    },
    {
        "name": "South Sudan",
        "code": "SS",
        "zone": "Africa",
        "translation_source": "component_texts.countries.206"
    },
    {
        "name": "Spain",
        "code": "ES",
        "zone": "Europe",
        "currency": "EUR",
        "translation_source": "component_texts.countries.207"
    },
    {
        "name": "Sri Lanka",
        "code": "LK",
        "zone": "Asia",
        "translation_source": "component_texts.countries.208"
    },
    {
        "name": "Sudan",
        "code": "SD",
        "zone": "Africa",
        "translation_source": "component_texts.countries.209"
    },
    {
        "name": "Suriname",
        "code": "SR",
        "zone": "South America",
        "translation_source": "component_texts.countries.210"
    },
    {
        "name": "Svalbard and Jan Mayen Islands",
        "code": "SJ",
        "zone": null,
        "translation_source": "component_texts.countries.211"
    },
    {
        "name": "Swaziland",
        "code": "SZ",
        "zone": "Africa",
        "translation_source": "component_texts.countries.212"
    },
    {
        "name": "Sweden",
        "code": "SE",
        "zone": "Europe",
        "translation_source": "component_texts.countries.213"
    },
    {
        "name": "Switzerland",
        "code": "CH",
        "zone": "Europe",
        "translation_source": "component_texts.countries.214"
    },
    {
        "name": "Syria",
        "code": "SY",
        "zone": "Asia",
        "translation_source": "component_texts.countries.215"
    },
    {
        "name": "Taiwan",
        "code": "TW",
        "zone": "Asia",
        "translation_source": "component_texts.countries.216"
    },
    {
        "name": "Tajikistan",
        "code": "TJ",
        "zone": "Asia",
        "translation_source": "component_texts.countries.217"
    },
    {
        "name": "Tanzania",
        "code": "TZ",
        "zone": "Africa",
        "translation_source": "component_texts.countries.218"
    },
    {
        "name": "Thailand",
        "code": "TH",
        "zone": "Asia",
        "translation_source": "component_texts.countries.219"
    },
    {
        "name": "Timor-Leste",
        "code": "TL",
        "zone": "Asia",
        "translation_source": "component_texts.countries.220"
    },
    {
        "name": "Togo",
        "code": "TG",
        "zone": "Africa",
        "translation_source": "component_texts.countries.221"
    },
    {
        "name": "Tokelau",
        "code": "TK",
        "zone": null,
        "translation_source": "component_texts.countries.222"
    },
    {
        "name": "Tonga",
        "code": "TO",
        "zone": null,
        "translation_source": "component_texts.countries.223"
    },
    {
        "name": "Trinidad and Tobago",
        "code": "TT",
        "zone": "North America",
        "translation_source": "component_texts.countries.224"
    },
    {
        "name": "Tunisia",
        "code": "TN",
        "zone": "Africa",
        "translation_source": "component_texts.countries.225"
    },
    {
        "name": "Turkey",
        "code": "TR",
        "zone": "Asia",
        "translation_source": "component_texts.countries.226"
    },
    {
        "name": "Turkmenistan",
        "code": "TM",
        "zone": "Asia",
        "translation_source": "component_texts.countries.227"
    },
    {
        "name": "Turks and Caicos Islands",
        "code": "TC",
        "zone": null,
        "translation_source": "component_texts.countries.228"
    },
    {
        "name": "Tuvalu",
        "code": "TV",
        "zone": null,
        "translation_source": "component_texts.countries.229"
    },
    {
        "name": "Uganda",
        "code": "UG",
        "zone": "Africa",
        "translation_source": "component_texts.countries.230"
    },
    {
        "name": "Ukraine",
        "code": "UA",
        "zone": "Europe",
        "translation_source": "component_texts.countries.231"
    },
    {
        "name": "UAE",
        "code": "AE",
        "zone": "Asia",
        "translation_source": "component_texts.countries.232"
    },
    {
        "name": "United Kingdom",
        "code": "GB",
        "zone": "Europe",
        "translation_source": "component_texts.countries.233"
    },
    {
        "name": "USA",
        "code": "US",
        "zone": "North America",
        "translation_source": "component_texts.countries.234"
    },
    {
        "name": "Uruguay",
        "code": "UY",
        "zone": "South America",
        "translation_source": "component_texts.countries.235"
    },
    {
        "name": "Uzbekistan",
        "code": "UZ",
        "zone": "Asia",
        "translation_source": "component_texts.countries.236"
    },
    {
        "name": "Vanuatu",
        "code": "VU",
        "zone": "Oceania",
        "translation_source": "component_texts.countries.237"
    },
    {
        "name": "Venezuela",
        "code": "VE",
        "zone": "South America",
        "translation_source": "component_texts.countries.238"
    },
    {
        "name": "Vietnam",
        "code": "VN",
        "zone": "Asia",
        "translation_source": "component_texts.countries.239"
    },
    {
        "name": "Virgin Islands, US",
        "code": "VI",
        "zone": null,
        "translation_source": "component_texts.countries.240"
    },
    {
        "name": "Wallis and Futuna Islands",
        "code": "WF",
        "zone": null,
        "translation_source": "component_texts.countries.241"
    },
    {
        "name": "Western Sahara",
        "code": "EH",
        "zone": "Africa",
        "translation_source": "component_texts.countries.242"
    },
    {
        "name": "Yemen",
        "code": "YE",
        "zone": "Asia",
        "translation_source": "component_texts.countries.243"
    },
    {
        "name": "Zambia",
        "code": "ZM",
        "zone": "Africa",
        "translation_source": "component_texts.countries.244"
    },
    {
        "name": "Zimbabwe",
        "code": "ZW",
        "zone": "Africa",
        "translation_source": "component_texts.countries.245"
    }
]