export class Tag {
  id?: string;
  client_id?: string;
  category?: string;
  name?: string;
  private?: boolean;
  description?: string;

  constructor(values: Tag) {
    this._internalMapping.forEach(t => this[t] = values[t]);
    delete this._internalMapping;
  }

  private _internalMapping = [
    "id",
    "name",
    "description",
    "private",
    "client_id",
    "category",
    "created_at"
  ]
}
