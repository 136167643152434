export class ReportDefinitionCredential {
  id?: string;
  name?: string;
  file_name?: string;
  aggregated_by_column_name?: string;
  sort_column_name?: string;
  filter_column_name?: string;
  filter_column_value?: string;
  report_type?: string;
  report_logic?: string;
  database_type?: string;
  custom_logic?: boolean;
  background_generated?: boolean;
  pre_generated?: boolean;
  generate_every?: boolean;
  aggregated?: boolean;
  sort_order?: string;
  query?: string;
  suppressed_for_client_ids?  : Array<string>;
  report_parameter?: Array<any>;
  report_response?: Array<any>;

  // Internal Properties
  internal_translate?: any = {
    path: "analytics_settings.report_definition_credentials.table",
    section: "analytics_settings",
    category: "report_definition_credentials",
    type: "form",
  };

  internal_properties?: any = [
    {
      name: "id",
      type: "string",
      readonly: true,
    },
    {
      name: "name",
      type: "string",
    },
    {
      name: "file_name",
      type: "string",
    },
    {
      name: "aggregated_by_column_name",
      type: "string",
    },
    {
      name: "sort_column_name",
      type: "string",
    },
    {
      name: "filter_column_name",
      type: "string",
    },
    {
      name: "filter_column_value",
      type: "string",
    },
    {
      name: "filter_column_exclude_name",
      type: "string",
    },
    {
      name: "filter_column_exclude_value",
      type: "string",
    },
    {
      name: "report_type",
      type: "dropdown-label",
    },
    {
      name: "report_logic",
      type: "dropdown-label"
    },
    {
      name: "database_type",
      type: "dropdown-label",
    },
    {
      name: "sort_order",
      type: "dropdown-label",
    },
    {
      name: "generate_every",
      type: "number",
    },
    {
      name: "custom_logic",
      type: "boolean",
    },
    {
      name: "background_generated",
      type: "boolean",
    },
    {
      name: "pre_generated",
      type: "boolean",
    },
    {
      name: "aggregated",
      type: "boolean",
    },
    {
      name: "query",
      type: "textarea",
    },
    {
      report_parameter: [{}],
    },
    {
      report_response: [{}],
    },
  ];

  constructor(values: ReportDefinitionCredential = {}) {
    Object.assign(this, values);
  }
}
