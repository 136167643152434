import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthService } from './auth.guard.service';
import { Observable } from '../../../../node_modules/rxjs';
import { Globals } from '../common';

@Injectable()
export class AuthGuard implements CanActivate {

    constructor(
        private _authService: AuthService,
        private _globals: Globals,
        private _router: Router
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> | Promise<boolean> {
        if (this._authService.isLoggedIn()) {
            return true;
        }

        this._globals.entryUrl = state;
        localStorage.setItem("fp.entry_url", state.url);

        this._router.navigate(["/authentication"]);
        return false;
    }
}
