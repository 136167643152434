export class Bundle {
    id?: string;
    parentItemGroupId?: string;
    name?: string;
    skuCode?: string;
    description?: string;

    products?: Array<any> | null;

    constructor(values: Bundle) { }

}
