export class ServiceResponse {
    loading: boolean;
    data: any = {};
    error: any;
    success: boolean;

    constructor(values: Object = {}) {
        Object.assign(this, values);
    }
}
