export class UnlockCode {
    id?: string;
    parent_item_group_id?: string;

    active?: boolean;
    code?: string;
    item_code?: string;
   
    custom_text_fields_set?: boolean;
    custom_text_fields?: Array<any>;

    constructor(values?: UnlockCode) {
        Object.assign(this, values);
    }
}