import { Component, Input, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ModalType } from '../../../enums/modalType';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-confirm-action-modal',
  templateUrl: './confirm-action-modal.component.html',
  styleUrls: ['./confirm-action-modal.component.css'],
})

export class ConfirmActionModalComponent implements OnInit {
  @Input() modalType: ModalType;
  @Input() type: string;
  @Input() customer: any;
  @Input() data: any;
  @Input() localizedText: any;
  @Input() configuration: any;

  public confirmAction: any;
  public result: Subject<boolean> = new Subject<boolean>();
  public onClose: Subject<any> = new Subject<boolean>();

  get modalTypeEnum() { return ModalType; }

  constructor(
    public bsModalRef: BsModalRef
  ) { }

  ngOnInit() {
    this._handleConfiguration();
  }

  private _handleConfiguration() {
    if (!!this.configuration) {
      this.modalType = !!this.configuration?.modal_type ? this.configuration?.modal_type: this.modalType;
    }
  }

  public confirm() {
    this.result.next(true);
    this.bsModalRef.hide();
    const response: any = {};
    if (this.bsModalRef.content.callback !== null && this.bsModalRef.content.callback !== undefined) {
      response.confirmed = true;
      response.data = this.data;
      response.customer = this.customer;

      this.bsModalRef.content.callback(response);
    } else {
      response.confirmed = true;
      response.data = this.data;
      this.onClose.next(response);
      this.onClose.complete();
      this.bsModalRef.hide();
    }
  }

  public cancel() {
    this.result.next(false);
    this.bsModalRef.hide();
    const response: any = {};
    if (this.bsModalRef.content.callback !== null && this.bsModalRef.content.callback !== undefined) {
      response.confirmed = false;
      this.bsModalRef.content.callback(response);
    } else {
      response.confirmed = false;
      this.onClose.next(response);
      this.onClose.complete();
      this.bsModalRef.hide();
    }
  }
}

