export class GoogleAppCredential {
    id?: string
    client_id?: string
    package_name?: string
    client_email?: string
    private_key?: string
    bucket_name?: string

    // Internal Properties
    internal_translate?: any = {
        path: "analytics_settings.google_app_credentials.table",
        section: "analytics_settings",
        category: "google_app_credentials",
        type: "form"
    };

    internal_properties?: any = [
        { name: "id", type: "string", readonly: true },
        { name: "client_id", type: "string", nullable: false },
        { name: "package_name", type: "string", nullable: false },
        { name: "client_email", type: "string", nullable: false },
        { name: "private_key", type: "textarea", nullable: false },
        { name: "bucket_name", type: "string" },
    ];

    constructor(values: GoogleAppCredential = {}) {
        Object.assign(this, values);
    }
}