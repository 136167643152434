export enum WarningType {
    onlyAdminCanChangePermissions,
    cannotEditPermissions,
    minLevelCount,
    customerNotFound,
    codeNotFound,
    noPendingDeletetionCustomers,
    customerAlreadyDeleted,
    countryAlreadySelected,
    importValidCSVFile,
    csvErrorNoPurchaseFound,
    requiredFieldsNotPopulated,
    noDataFound,
    noAvailableUpgradeOption,
    noAvailableDowngradeOption,
    noLinkedPricesFound,
    incorrectDateRange,
    valueHasToBeGreaterThan,
    valueHasToBeLowerThan,
    defineValidIpAddress,
    incorrectEmailAddress,
    couponCodeRequired,
    addressNotFound,
    addressNotUnique,
    addressNotComplete,
    postcodeNotFound,
    cardExpirationDateBeforeFirstDeliveryDate,
    deliveryHolidaysDatesUpdated,
    emptyFile,
    noSelectedValue,
    noApplicable,
    notAllCustomersUpdated,
    contactInfo,
    noCredentials,
    fieldLengthExceeds,
    notFound,
    noPurchaseNotification,
    noConstantContactAccessTokenCreated,
    ccAccessTokenWarning,
    ccAccessTokenRefreshWarning,            
    smptpTestWarning,
    ccDeleteCredentialWarning   
}
