export class Contract {
    contract_penalty_amount?: number;
    contract_penalty_capped_by_remaining_value?: boolean;
    contract_penalty_type?: string;
    contract_periods?: number;
    contract_renewal_type?: string;
    is_contract?: boolean;
    period_amounts?: Array<any>;

    constructor(values: Contract = {}) {
        [
            'contract_penalty_amount',
            'contract_penalty_capped_by_remaining_value',
            'contract_penalty_type',
            'contract_periods',
            'contract_renewal_type',
            'is_contract',
            'period_amounts',
        ].forEach(p => this[p] = values[p]);
    }
}