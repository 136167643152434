export class Member {
    id?: string;
    email?: string;
    image?: string;
    level?: string;
    user_roles?: Array<any>;
    name?: string;
    provider?: string;

    constructor(values: Object = {}) {
        Object.assign(this, values);
    }
}
