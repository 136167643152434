interface Properties {
     country: string;
     interests: string;
     organisation_type: string;
     organisation: string;
}

export class Customer {
     id?: string;
     automatic_verification?: boolean;
     available_funds?: number;
     claims?: any;
     created_at?: string;
     finish_at?: string;
     currency?: string;
     email?: string;
     image?: string;
     name?: string;
     object?: string;
     pin?: number;
     role?: number;
     roles?: any;
     deleted?: boolean;
     country_code?: string;
     provider?: string;
     dummy_email: boolean;

     code?: string;
     phone_number?: string;
     organisation?: string;
     date_of_birth?: any;
     address?: string;
     address_1?: string;
     address_2?: string;
     address_3?: string;
     address_4?: string;
     address_5?: string;

     address1?: string;
     address2?: string;
     address3?: string;
     address4?: string;
     address5?: string;

     city?: string;
     post_code?: string | null;
     country?: string;
     subscription_details?: any;
     card_brand?: string;
     card_last4?: string;
     suspend_emails?: boolean;
     delivery_notes?: string;
     address_id?: any;
     address_is_deliverable?: boolean;
     longitude?: any;
     latitude?: any;
     segments?: any;
     properties?: Properties;

     bulk_edit_purchases?: any;

     constructor(values: Object = {}) {
          Object.assign(this, values);
     }
}
