export class PriceAmount {
    amount?: number;
    currency?: string;

    placeholder?: boolean
    placeholder_badges?: Array<any>;

    variable_amount?: Boolean;

    free?: boolean;
    free_items_limit?: boolean;
    free_items_count?: number;

    introductory_offer?: boolean;
    require_registration?: boolean;
    require_card?: boolean;
    price_to_display?: string;
    price_to_display_frequency?: string;
    payment_providers?: Array<any>;
    payment_providers_moto?: Array<any>;
    available_for_upgrade?: boolean;
    available_for_downgrade?: boolean;
    apply_upgrade_deductionor_refund?: boolean;
    coupon_first?: boolean;

    user_cancellable?: boolean;
    user_cancellable_renewal?: boolean;
    user_cancellable_renewals?: boolean;
    user_cancellable_renewal_price_id?: any;

    validate_address?: boolean;

    delivery_days?: Array<any>;
    delayed_payment?: boolean;
    order_cut_off_day?: number;
    first_delivery_day?: number;
    max_delivery_holiday_length?: number;
    delivery_holiday_deduction?: number;
    delivery_holiday_deduction_type?: number;

    original_amount?: number;
    tax_rate?: number;
    subscription_payments?: string;
    new_customers_only?: boolean;
    allow_anonymous?: boolean;

    linked?: any;
    linked_price_id?: any;

    constructor(values: PriceAmount = {}) {
        [
            'amount',
            'currency',
            'placeholder',
            'placeholder_badges',
            'variable_amount',
            'free',
            'free_items_limit',
            'free_items_count',
            'introductory_offer',
            'require_registration',
            'require_card',
            'price_to_display',
            'price_to_display_frequency',
            'payment_providers',
            'payment_providers_moto',
            'available_for_upgrade',
            'available_for_downgrade',
            'apply_upgrade_deductionor_refund',
            'coupon_first',
            'user_cancellable',
            'user_cancellable_renewal',
            'user_cancellable_renewals',
            'user_cancellable_renewal_price_id',
            'validate_address',
            'delivery_days',
            'delayed_payment',
            'order_cut_off_day',
            'first_delivery_day',
            'max_delivery_holiday_length',
            'delivery_holiday_deduction',
            'delivery_holiday_deduction_type',
            'original_amount',
            'tax_rate',
            'subscription_payments',
            'new_customers_only',
            'allow_anonymous',
            'linked',
            'linked_price_id'
        ].forEach(p => this[p] = values[p]);
    }
}
