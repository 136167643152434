import { Injectable, NgZone, inject } from "@angular/core";
import { VendorCommonRequestsService } from "../../services/common/vendor.service";
import { CustomerService } from "../../services/common/customer.service";
import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot } from "@angular/router";
import { Globals } from "../../services/common";

@Injectable({ providedIn: 'root' })
export class ClientResolverService {
    constructor(
        private _customersService: CustomerService,
        private _globals: Globals,
        private _router: Router,
        private _vendorsService: VendorCommonRequestsService,
        private _zone: NgZone,
    ) { }

    async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<any> {
        await this._customersService.getCurrentUserAsync(route.params?.clientId);
        await this._vendorsService.getVendorAsync(null);

        const hasRoles = !!this._globals.user.roles?.find((x: any) => x.client_id === route.params.clientId);
        if (!["loading", "undefined"].includes(route.params.clientId) && !hasRoles) {
            this._accessDeniedRedirect(state); // Redirect a user to access denied page if doesn't have roles for selected client
            return false;
        }

        return true;
    }

    private _accessDeniedRedirect(state: RouterStateSnapshot) {
        this._globals.deniedAccessUrl = state;
        this._zone.run(() => {
            this._router.navigate(['/error/access-denied']);
        });
    }
}

export const ClientResolver: CanActivateFn = (next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<any> => {
    return inject(ClientResolverService).canActivate(next, state);
}
