export class AppleSocialCredential {
    id?: string
    callback_path?: string
    client_id?: string
    code?: string
    enable?: boolean
    key_id?: string
    name?: string
    private_key?: string
    scheme?: string
    sub?: string
    team_id?: string

    // Internal Properties
    internal_translate?: any = {
        path: "analytics_settings.apple_social_credentials.table",
        section: "analytics_settings",
        category: "apple_social_credentials",
        type: "form"
    };

    internal_properties?: any = [
        { name: "id", type: "string", readonly: true },
        { name: "client_id", type: "string", readonly: true },
        { name: "enabled", type: "boolean" },
        { name: "name", type: "string" },
        { name: "code", type: "string" },
        { name: "callback_path", type: "string" },
        { name: "scheme", type: "string" },
        { name: "key_id", type: "string", nullable: false },
        { name: "team_id", type: "string" },
        { name: "sub", type: "string" },
        { name: "private_key", type: "textarea" },
    ];

    constructor(values: AppleSocialCredential = {}) {
        Object.assign(this, values);
    }
}