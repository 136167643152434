export class EmailTemplate {
  id?: string;
  client_id?: string;
  email_type?: string;
  subject?: string;
  body?: string;
  html_body?: string;
  item_group_id?: string;
  price_id?: string;
  item_id?: string;
  anchor?: string;
  suppress?: string;
  language?: string;
  price_additional_purchase_notification_id?: string;

  constructor(values: EmailTemplate = {}) {
    this._internal_properties.forEach(p => this[p] = values[p]);
    delete this._internal_properties;
  }

  private _internal_properties?: any = [
    "id",
    "client_id",
    "email_type",
    "subject",
    "body",
    "html_body",
    "item_group_id",
    "price_id",
    "item_id",
    "anchor",
    "suppress",
    "language",
    "price_additional_purchase_notification_id",
  ]

}
