export class Events {

  id?: string;
  object?: string;
  client_id?: string;
  created_at?: string;
  item_group_id?: string;
  item_code?: string;
  description?: string;
  event_date?: string;
  updated_at?: string;
  event_revenue?: number;
  event_number_of_registered_users?: number;

  // Internal Properties
  internal_translate?: any = {
    path: "analytics_overview.events.archived-events.table",
    section: "analytics_overview",
    category: "archived",
    type: "form"
  };

  constructor(values: Events = {}) {
    Object.assign(this, values);
  }

}
