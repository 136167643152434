export class RuleActivePeriod {
    active_from?: string;
    active_to?: string;
    days_of_week?: Array<any>;
    disable_other?: boolean;

    constructor(values: RuleActivePeriod = {}) {
        [
            'active_from',
            'active_to',
            'days_of_week',
            'disable_other',
        ].forEach(p => this[p] = values[p]);
    }

}