export class Coupon {
    id?: string;
    temp_id?: string;
    price_id?: string;
    discount_type?: string;
    discount?: number;
    valid_from?: Date;
    valid_to?: Date;
    limit_per_customer?: number;
    limit_per_code?: number;
    limit_total?: number;
    active?: boolean;
    created_at?: string;
    description?: string;
    coupon_type?: string;
    codes?: Array<string>;

    constructor(values: Object = {}) {
        Object.assign(this, values);
    }
}
