export enum PaymentMethodType {
    unknown = "unknown",
    card = "card",
    googlePay = "google_pay",
    applePay = "apple_pay",
    revolutPay = "revolutPay",
    paypal = "paypal",
    bacsDebit = "bacs_debit",
    sepaDebit = "sepa_debit",
    offline = "offline",
    link = "link"
}