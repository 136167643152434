
import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { ApiService } from '../api/api.service';
import { Globals } from './globals.service';
import { PaymentProviderType } from '../../enums';

@Injectable({ providedIn: 'root', })

export class VendorCommonRequestsService {
  constructor(
    private _globals: Globals,
    private _apiService: ApiService,
  ) { }

  /**
  * @description Return details of a vendor
  * @param params array of additional BE params
  */
  async getVendorAsync(params?: any): Promise<any> {
    try {
      let requestUrl = 'vendors/me';

      // Set addtional parameters for BE
      if (!!params) {
        const keys = Object.keys(params);
        keys.forEach(key => {
          requestUrl += '&' + key + '=' + params[key];
        });
      }

      const vendor = await firstValueFrom(this._apiService.getRequest(requestUrl));
      this._setGlobals(vendor);

      return vendor;
    } catch (err) {
      throw err;
    }
  }

  private _setGlobals(vendor: any) {
    this._globals.vendor = vendor;
    this._globals.vendor.payment_providers = [PaymentProviderType.authorizeNet, PaymentProviderType.stripe];
    this._globals.supportedCurrencies = vendor?.currencies;
    this._globals.setBsDatePickerConfig(vendor);
    this._globals.globalsUpdated.next(true);
    this._globals.setVendorTimeZoneOffSetInMinutes(vendor);
  }
}
