import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ConfirmActionModalComponent } from './confirm-action-modal.component';
import { ModalModule } from 'ngx-bootstrap/modal';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
    imports: [
        CommonModule,
        TranslateModule,
        ModalModule.forRoot(),
    ],
    declarations: [
        ConfirmActionModalComponent
    ],
    exports: [
        ConfirmActionModalComponent
    ]
})

export class ConfirmActionModalModule { }
