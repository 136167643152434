import { Definition, Designer, DesignerExtension, StepsConfiguration, ToolboxConfiguration, ValidatorConfiguration } from "sequential-workflow-designer";
import { JourneyBuilderStepType } from "../enums/journeyBuilderStepType";
import { InterruptStep } from "../pages/analytics-content/content-sites/content-site/components/common/journey-builder/steps/extensions/steps/interrupt.step";

export class JourneyBuilderConfiguration {

     theme?: string | null = "light";
     is_readonly?: boolean = false;
     is_toolbox_collapsed?: boolean = true;
     is_editor_collapsed?: boolean = true;
     undo_stack_size?: number = 5;
     control_bar?: boolean = true;
     context_menu?: boolean = false;
     are_editors_hidden?: boolean = false;
     toolbox_configuration?: ToolboxConfiguration = null;
     steps_configuration?: StepsConfiguration | null;
     validator_configuration?: ValidatorConfiguration | null;
     extensions?: Array<DesignerExtension>;

     designer?: Designer;
     definition?: Definition | null;

     constructor() {
          this.steps_configuration = this._setDefaultStepsConfig();
          this.validator_configuration = this._setDefaultValidatorConfig()
          this.extensions = this._setDefaultExtensions();
     }

     private _setDefaultStepsConfig() {
          return {
               iconUrlProvider: (componentType, type) => {
                    return componentType === "switch"
                         ? "./assets/img/icons/journey-builder/icon-switch.svg"
                         : "./assets/img/icons/journey-builder/angular-icon.svg";
               }
          };
     }

     private _setDefaultValidatorConfig(): ValidatorConfiguration {
          return {
               step: (step: any) => {
                    switch (step.type) {
                         case JourneyBuilderStepType.JourneyTrigger:
                              return (!!step?.properties.id || !!step?.properties.classname || !!step?.properties.query);
                         case JourneyBuilderStepType.ActionProcessPayment:
                              return (!!step?.properties.payment?.key);
                         case JourneyBuilderStepType.FragmentVoluntaryContribution:
                              return !!step?.properties.template;
                         case JourneyBuilderStepType.EmailNotification:
                              return !!step?.properties.type;
                         case JourneyBuilderStepType.FlipPayAccessStatus:
                              return !!step?.properties.group_code;
                         default:
                              return !!step.name;
                    }
               },
               root: (definition: Definition) => {
                    return true;
               },
          };
     }

     private _setDefaultExtensions(): Array<DesignerExtension> {
          const designerExtension: DesignerExtension = {
               steps: [InterruptStep],
          };

          return [designerExtension];
     }
}
