import { Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { LocalizedText } from '../localization/localizedText.service';
import { ConfirmActionModalComponent } from '../../components/common/confirm-action-modal/confirm-action-modal.component';
import { Observable } from 'rxjs';

export interface CanComponentDeactivate {
    canDeactivate: () => Observable<boolean> | Promise<boolean> | boolean;
}

@Injectable()
export class CanDeactivateGuard implements CanDeactivate<CanComponentDeactivate> {
    public modalRef: BsModalRef;
    public currentLocalizedText: any;

    constructor(private _modalService: BsModalService, private _localizedText: LocalizedText) {
        this._localizedText.text.subscribe(res => {
            this.currentLocalizedText = res.component_texts;
        });
    }

    canDeactivate(component: CanComponentDeactivate) {
        return component.canDeactivate ? component.canDeactivate() : true;
    }

    openConfirmationModal(modalType, customer): Promise<boolean> {
        const modalState = this.setModalInitialState(modalType, customer);
        this.modalRef = this._modalService.show(modalState.content, Object.assign({}, { class: 'modal-sm', initialState: modalState.initial_state }));

        return new Promise<boolean>((resolve, reject) => this.modalRef.content.result.subscribe((result) => resolve(result)));
    }

    setModalInitialState(modalType, customer): any {
        const modalState: any = {};
        const initialState: any = {
            modalType: modalType,
            data: customer,
            localizedText: this.currentLocalizedText
        };

        modalState.initial_state = initialState;

        // Content - select the modal component which will be used
        modalState.content = ConfirmActionModalComponent;

        return modalState;
    }
}
