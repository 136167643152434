export class ChartColors {
    public defaultChartColor: any = {
        backgroundColor: 'rgba(0, 0, 0, 0)',
        borderColor: 'rgb(75, 192, 192)',
        pointBackgroundColor: 'rgba(0, 0, 0, 0)',
        pointBorderColor: 'rgb(75, 192, 192)',
        pointHoverBackgroundColor: '#fff',
        pointHoverBorderColor: 'rgb(75, 192, 192)',
        pointRadius: '3',
        pointHoverRadius: '4'
    };

    public defaultChartColors: Array<any> = [
        { // Teal
            backgroundColor: 'rgba(0, 0, 0, 0)',
            borderColor: 'rgba(38, 185, 154, 0.31)',
            pointBackgroundColor: 'rgba(38, 185, 154, 0.31)',
            pointBorderColor: 'rgba(38, 185, 154, 0.31)',
            pointHoverBackgroundColor: '#fff',
            pointHoverBorderColor: 'rgba(38, 185, 154, 0.31)',
            pointRadius: '3',
            pointHoverRadius: '4'
        },
        { // Yellow
            backgroundColor: 'rgba(0, 0, 0, 0)',
            borderColor: 'rgba(3, 88, 106, 0.3)',
            pointBackgroundColor: 'rgba(3, 88, 106, 0.3)',
            pointBorderColor: 'rgba(3, 88, 106, 0.3)',
            pointHoverBackgroundColor: '#fff',
            pointHoverBorderColor: 'rgba(3, 88, 106, 0.3)',
            pointRadius: '3',
            pointHoverRadius: '4'
        },
        { // Blue
            backgroundColor: 'rgba(0, 0, 0, 0)',
            borderColor: 'rgb(101, 105, 223)',
            pointBackgroundColor: 'rgba(0, 0, 0, 0)',
            pointBorderColor: 'rgb(101, 105, 223)',
            pointHoverBackgroundColor: '#fff',
            pointHoverBorderColor: 'rgb(101, 105, 223)',
            pointRadius: '3',
            pointHoverRadius: '4'
        }
    ];

    constructor(values: Object = {}) {
        Object.assign(this, values);
    }
}
