export class FacebookSocialCredential {
    id?: string;
    client_id?: string;
    enabled?: string;
    name?: string;
    code?: string;
    callback_path?: string;
    scheme?: string;
    app_id?: string;
    app_secret?: string;

    // Internal Properties
    internal_translate?: any = {
        path: "analytics_settings.facebook_social_credentials.table",
        section: "analytics_settings",
        category: "facebook_social_credentials",
        type: "form"
    };

    internal_properties?: any = [
        { name: "id", type: "string", readonly: true },
        { name: "client_id", type: "string", readonly: true },
        { name: "enabled", type: "boolean" },
        { name: "name", type: "string" },
        { name: "code", type: "string", nullable: false },
        { name: "callback_path", type: "string" },
        { name: "scheme", type: "string" },
        { name: "app_id", type: "string" },
        { name: "app_secret", type: "string" },
    ];

    constructor(values: FacebookSocialCredential = {}) {
        Object.assign(this, values);
    }
}