import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { HelpersService } from './services/common/helpers.service';
import { LocalizedText } from './services/localization/localizedText.service';
import { Globals } from './services/common';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  public showSpinner = true;

  constructor(
    private _globals: Globals,
    private _helpers: HelpersService,
    private _localizedText: LocalizedText,
    private _router: Router,
    private _translate: TranslateService
  ) {
    this._router.events.subscribe(val => {
      if (val instanceof NavigationEnd) {
        this.showSpinner = false;
      }
    });
  }

  ngOnInit() {
    this._setTranslations();
  }

  private _setTranslations() {
    this._translate.addLangs(['en', 'es', 'pt']);
    this._translate.setDefaultLang(this._helpers.currentLang);
    this._translate.use(this._helpers.currentLang ? this._helpers.currentLang : 'en');
    this._translate.onLangChange.subscribe((event: LangChangeEvent) => {
      if (event.lang.includes('ar')) {
        this._helpers.textOrientation = 'rtl';
      } else {
        this._helpers.textOrientation = 'ltr';
      }

      // Select properties from i18n file to be available in the LocalizedText Service
      const toTranslate = [
        'component_texts',
        'analytics_customer',
        'analytics_content',
        'analytics_settings',
        'analytics_subscription_management',
        'analytics_team',
        'analytics_overview',
        'analytics_statemnet',
        'shared'
      ];

      // Refresh component texts with the current language
      this._translate.get(toTranslate).subscribe(res => {
        this._localizedText.update(res);
      });
    });
  }
}
