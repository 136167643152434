import { Referrer } from './Referrer';
import { Coupon } from '../coupon/Coupon';

export class Price {
    public id: string;
    public parent_price_id?: string;
    public base_price_id?: string;
    public children?: any[];
    public client_id: string;
    public created_at?: string;

    public price_duration?: string;

    public item_id?: string;
    public item_code?: string;
    public item_group_id?: string;
    public item_group_code?: string;
    public default_for_items = false;

    public active?: boolean;
    public active_from?: string;
    public active_to?: string;

    public country_code?: string;
    public ad_block_detected?: boolean;

    public coupons: Coupon[];

    public free = false;
    public free_items_count?: any;
    public amount?: number;
    public original_amount?: number;
    public currency?: string;
    public name?: string;
    public description?: string;
    public description2?: string;
    public description3?: string;
    public summary?: string;

    public duration_unit?: any;
    public duration?: any;
    public refundable: boolean;
    public refundable_for?: any;
    public refundable_for_unit?: any;
    public refundable_before?: string;
    public renewable?: boolean;
    public renewable_price?: any;
    public renewable_price_id?: string;
    public renewals_count?: number;
    public manual_renewal?: boolean;

    public affiliates_subsidiaries: boolean;
    public affiliates_subsidiaries_share?: any;
    public affiliates_sanctioned: boolean;
    public affiliates_sanctioned_share: any;
    public affiliates_unsanctioned: boolean;
    public affiliates_unsanctioned_share: any;

    public referrer?: Referrer;
    public referrer_id?: string;
    public referrer_type?: string;
    public referrer_domain?: string;

    public tax_rate?: number;

    public start_at?: string;
    public finish_at?: string;
    public deleted?: boolean;

    public linked?: boolean;
    public linked_price_id?: string;
    public linked_pass_code?: boolean;
    public linked_parse_code?: boolean;

    public date_of_publication?: any;
    public date_of_publication_unit?: any;
    public days_of_week?: any[];

    public deal?: boolean;
    public deal_description?: string;
    public deal_gift?: string;
    public deal_cash_value?: number;

    public sku_code: string;

    public contract_penalty_amount?: number;
    public contract_penalty_capped_by_remaining_value?: boolean;
    public contract_penalty_type?: string;
    public contract_periods?: number;
    public contract_renewal_type?: string;
    public is_contract?: boolean;
    public period_amounts?: Array<any>;

    public consumable?: boolean;
    public consumables?: Array<any>;

    public custom_text_fields?: Array<any>;

    public metered_paywall?: boolean;
    public metered_paywall_duration?: number;
    public metered_paywall_duration_unit?: string;
    public metered_paywall_expiration_type?: string;
    public metered_paywall_items_limit?: number;
    public metered_paywall_message_first?: string;
    public metered_paywall_message_last?: string;
    public metered_paywall_message_middle?: string;
    public metered_paywall_show_counter_first?: boolean;
    public metered_paywall_show_counter_last?: boolean;
    public metered_paywall_show_counter_middle?: boolean;

    public country_codes?: Array<any>;

    public internal_name?: string;
    public friendly_name?: string;

    public additional_notifications?: Array<any>;
    public price_migrations?: Array<any>;

    public corporate_account?: boolean;

    public email_flow?: boolean;
    public payment_method_options_moto?: any;

    public constructor(init?: Partial<Price>) {
        Object.assign(this, init);
    }
}
