import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { map } from 'rxjs/operators';
import { Customer } from '../../models';
import { ApiService } from '../api/api.service';
import { Globals } from './globals.service';
import { HelpersService } from './helpers.service';

@Injectable({ providedIn: 'root', })

export class CustomerService {
  private _controllerBaseUrl = "customers";

  // constructor(private _service: CustomerPurchaseDetailService) { }
  constructor(
    private _apiService: ApiService,
    private _helpers: HelpersService,
    private _globals: Globals
  ) { }

  /**
  * @description Return details of the current customer
  * @param params array of additional BE params
  */
  async getCustomerAsync(params?: any): Promise<any> {
    try {
      let requestUrl = 'customers/me';

      // Set addtional parameters for BE
      if (!!params) {
        const keys = Object.keys(params);
        keys.forEach(key => {
          requestUrl += '&' + key + '=' + params[key];
        });
      }

      return this._apiService.getRequest(requestUrl)
        .pipe(map(data => data)).toPromise()
        .catch((err) => {
          this._helpers.displayErrorMessage(err);
          throw err;
        });
    } catch (error) {
      console.log(error);
      return null;
    }
  }

  async getCurrentUserAsync(clientId: string): Promise<any> {
    try {
      const user = await firstValueFrom(this._apiService.getRequest('customers/me'));
      this._globals.setGlobals(user, clientId);
      return user;
    } catch (error) {
      console.error("FP:", error);
      return null;
    }
  }

  async getCustomerVisits(userId: string): Promise<any> {
    try {
      const requestUrl = 'customers/' + userId + '/impressions';
      return this._apiService.getRequest(requestUrl)
        .pipe(map(data => data)).toPromise()
        .catch((err) => {
          this._helpers.displayErrorMessage(err);
          throw err;
        });
    } catch (error) {
      console.log(error);
      return null;
    }
  }

  /**
   * @description Get customer details for specified customer id
   * @param customerId Customer Id you would like to get details of
   */
  async getCustomerByIdAndSetGlobals(customerId: string): Promise<Customer> {
    try {
      const requestUrl = "customers/" + encodeURIComponent(customerId);
      return this._apiService.getRequest(requestUrl)
        .pipe(map((data) => {
          this._globals.customer = data;
          return new Customer(data);
        })).toPromise()
        .catch((err) => {
          this._helpers.displayErrorMessage(err);
          throw err;
        });
    } catch (error) {
      console.log(error);
      return null;
    }
  }

  async getCustomerByIdAsync(customerId: any): Promise<Customer> {
    try {
      return await firstValueFrom(this._apiService.getRequest(`${this._controllerBaseUrl}/${encodeURIComponent(customerId)}`));
    } catch (error) {
      throw error;
    }
  }

  async updateCustomerAsync(customerId: string, request: Customer) {
    try {
      return await firstValueFrom(this._apiService.patchRequest(`${this._controllerBaseUrl}/${encodeURIComponent(customerId)}`, request));
    } catch (error) {
      throw error;
    }
  }

  async updateCountryCodeAsync(customerId: any, countryCode: string) {
    try {
      const requestUrl = "customers/" + encodeURIComponent(customerId) + "/country_code";
      const request = { country_code: countryCode }
      return this._apiService.patchRequest(requestUrl, request)
        .pipe(
          map(() => {
            this._helpers.displaySaveSuccessMessage();
            return { success: true };
          })).toPromise()
        .catch((err) => {
          this._helpers.displayErrorMessage(err);
          throw err;
        });
    } catch (error) {
      console.log(error);
      return null;
    }
  }

  async getCustomerServiceGenericReports(url: string) {
    try {
      return await firstValueFrom(this._apiService.getRequest(url));
    } catch (error) {
      throw error;
    }
  }

  async cancelPendingDeletionStatusAsync(url: string) {
    try {
      return await firstValueFrom(this._apiService.postRequest(url, null));
    } catch (error) {
      throw error;
    }
  }

  async getCustomersInPendingDeletionStatusAsync(url: string) {
    try {
      return await firstValueFrom(this._apiService.getRequest(url));
    } catch (error) {
      throw error;
    }
  }

  async deleteCustomerAsync(url: string) {
    try {
      return await firstValueFrom(this._apiService.deleteRequest(url));
    } catch (error) {
      throw error;
    }
  }

  async createCustomer(customer: any) {
    try {
      const url = 'users';
      return await firstValueFrom(this._apiService.postRequest(url, customer));
    } catch (error) {
      throw error;
    }
  }

  async getRequest(url: any) {
    try {
      return await firstValueFrom(this._apiService.getRequest(url));
    } catch (error) {
      throw error;
    }
  }

  async postRequest(url: any, data: any) {
    try {
      return await firstValueFrom(this._apiService.postRequest(url, data));
    } catch (error) {
      throw error;
    }
  }

  async patchRequest(url: any, data: any) {
    try {
      return await firstValueFrom(this._apiService.patchRequest(url, data));
    } catch (error) {
      throw error;
    }
  }

  async getSummaryAsync(customerId: string) {
    try {
      const url = `customers/${encodeURIComponent(customerId)}/summary`;
      return await firstValueFrom(this._apiService.getRequest(url));
    } catch (error) {
      throw error;
    }
  }

  // Emails
  async getEmailAsync(customerId: string, emailId: string) {
    try {
      return await firstValueFrom(this._apiService.getRequest(`${this._controllerBaseUrl}/${encodeURIComponent(customerId)}/emails/${encodeURIComponent(emailId)}`));
    } catch (error) {
      throw error;
    }
  }

  async getEmailsAsync(customerId: string, limit: number): Promise<Array<any>> {
    try {
      return await firstValueFrom(this._apiService.getRequest(`${this._controllerBaseUrl}/${encodeURIComponent(customerId)}/emails?limit=${encodeURIComponent(limit + 1)}`));
    } catch (error) {
      throw error;
    }
  }

  async getEmailsNextAsync(customerId: string, limit: number, startAfterId: string) {
    try {
      return await firstValueFrom(this._apiService.getRequest(`${this._controllerBaseUrl}/${encodeURIComponent(customerId)}/emails?limit=${encodeURIComponent(limit + 1)}&starting_after=${encodeURIComponent(startAfterId)}`));
    } catch (error) {
      throw error;
    }
  }
  async getEmailsPreviousAsync(customerId: string, limit: number, endingBeforeId: string) {
    try {
      return await firstValueFrom(this._apiService.getRequest(`${this._controllerBaseUrl}/${encodeURIComponent(customerId)}/emails?limit=${encodeURIComponent(limit + 1)}&ending_before=${encodeURIComponent(endingBeforeId)}`));
    } catch (error) {
      throw error;
    }
  }

  async resendEmailAsync(customerId: string, emailId: string) {
    try {
      return await firstValueFrom(this._apiService.postRequest(`${this._controllerBaseUrl}/${encodeURIComponent(customerId)}/emails/${encodeURIComponent(emailId)}/resend`, null));
    } catch (error) {
      throw error;
    }
  }

  // Audit Items
  async getAuditItemsAsync(customerId: string, limit: number) {
    try {
      return await firstValueFrom(this._apiService.getRequest(`${this._controllerBaseUrl}/${encodeURIComponent(customerId)}/audit_items?limit=${encodeURIComponent(limit + 1)}`));
    } catch (error) {
      throw error;
    }
  }

  async getAuditItemsNextAsync(customerId: string, limit: number, startAfterId: string) {
    try {
      return await firstValueFrom(this._apiService.getRequest(`${this._controllerBaseUrl}/${encodeURIComponent(customerId)}/audit_items?limit=${encodeURIComponent(limit + 1)}&starting_after=${encodeURIComponent(startAfterId)}`));
    } catch (error) {
      throw error;
    }
  }

  async getAuditItemsPreviousAsync(customerId: string, limit: number, endingBeforeId: string) {
    try {
      return await firstValueFrom(this._apiService.getRequest(`${this._controllerBaseUrl}/${encodeURIComponent(customerId)}/audit_items?limit=${encodeURIComponent(limit + 1)}&ending_before=${encodeURIComponent(endingBeforeId)}`));
    } catch (error) {
      throw error;
    }
  }

  // Login Details
  async getLoginSessionDetailsAsync(customerId: string) {
    try {
      return await firstValueFrom(this._apiService.getRequest(`${this._controllerBaseUrl}/${encodeURIComponent(customerId)}/login_details?limit=100`));
    } catch (error) {
      throw error;
    }
  }

  // Payments
  async getPaymentsAsync(customerId: string, limit: number) {
    try {
      return await firstValueFrom(this._apiService.getRequest(`${this._controllerBaseUrl}/${encodeURIComponent(customerId)}/payments?limit=${encodeURIComponent(limit + 1)}`));
    } catch (error) {
      throw error;
    }
  }

  async getPaymentsNextAsync(customerId: string, limit: number, startAfterId: string) {
    try {
      return await firstValueFrom(this._apiService.getRequest(`${this._controllerBaseUrl}/${encodeURIComponent(customerId)}/payments?limit=${encodeURIComponent(limit + 1)}&starting_after=${encodeURIComponent(startAfterId)}`));
    } catch (error) {
      throw error;
    }
  }

  async getPaymentsPreviousAsync(customerId: string, limit: number, endingBeforeId: string) {
    try {
      return await firstValueFrom(this._apiService.getRequest(`${this._controllerBaseUrl}/${encodeURIComponent(customerId)}/payments?limit=${encodeURIComponent(limit + 1)}&ending_before=${encodeURIComponent(endingBeforeId)}`));
    } catch (error) {
      throw error;
    }
  }

  // Support Tickets
  async getSupportTicketsAsync(customerId: string, limit: number) {
    try {
      return await firstValueFrom(this._apiService.getRequest(`${this._controllerBaseUrl}/${encodeURIComponent(customerId)}/support_tickets?limit=${encodeURIComponent(limit + 1)}`));
    } catch (error) {
      throw error;
    }
  }

  async getSupportTicketsNextAsync(customerId: string, limit: number, startAfterId: string) {
    try {
      return await firstValueFrom(this._apiService.getRequest(`${this._controllerBaseUrl}/${encodeURIComponent(customerId)}/support_tickets?limit=${encodeURIComponent(limit + 1)}&starting_after=${encodeURIComponent(startAfterId)}`));
    } catch (error) {
      throw error;
    }
  }

  async getSupportTicketsPreviousAsync(customerId: string, limit: number, endingBeforeId: string) {
    try {
      return await firstValueFrom(this._apiService.getRequest(`${this._controllerBaseUrl}/${encodeURIComponent(customerId)}/support_tickets?limit=${encodeURIComponent(limit + 1)}&ending_before=${encodeURIComponent(endingBeforeId)}`));
    } catch (error) {
      throw error;
    }
  }

  async createSupportTicketAsync(customerId: string, request: any) {
    try {
      return await firstValueFrom(this._apiService.postRequest(`${this._controllerBaseUrl}/${encodeURIComponent(customerId)}/support_tickets`, request));
    } catch (error) {
      throw error;
    }
  }

  async createSupportTicketNotesAsync(customerId: string, supportTicket: any, request: any) {
    try {
      return await firstValueFrom(this._apiService.postRequest(`${this._controllerBaseUrl}/${encodeURIComponent(customerId)}/support_tickets/${supportTicket.id}/notes`, request));
    } catch (error) {
      throw error;
    }
  }

  async updateSupportTicketNotesAsync(customerId: string, supportTicket: any, note: any, request: any) {
    try {
      return await firstValueFrom(this._apiService.patchRequest(`${this._controllerBaseUrl}/${encodeURIComponent(customerId)}/support_tickets/${supportTicket.id}/notes/${encodeURIComponent(note.id)}`, request));
    } catch (error) {
      throw error;
    }
  }

  async updateSupportTicketAsync(customerId: string, supportTicketId: string, request: any) {
    try {
      return await firstValueFrom(this._apiService.patchRequest(`${this._controllerBaseUrl}/${encodeURIComponent(customerId)}/support_tickets/${encodeURIComponent(supportTicketId)}`, request));
    } catch (error) {
      throw error;
    }
  }

  async closeSupportTicketAsync(customerId: string, supportTicketId: string, request: any) {
    try {
      return await firstValueFrom(this._apiService.patchRequest(`${this._controllerBaseUrl}/${encodeURIComponent(customerId)}/support_tickets/${encodeURIComponent(supportTicketId)}`, request));
    } catch (error) {
      throw error;
    }
  }

  async getAgentAsync(agentId: any) {
    try {
      return await firstValueFrom(this._apiService.getRequest(`${this._controllerBaseUrl}/${encodeURIComponent(agentId)}`));
    } catch (error) {
      throw error;
    }
  }

  /**
   * 
   * @param customer
   * @param active 
   * @param startingAfterId
   * @param endingBeforeId
   * @param linked to get all linked purchases to customer
   * @returns list of web purchases
   */
  async getWebPurchasesAsync(
    customer: Customer,
    active?: boolean,
    startingAfterId?: any,
    endingBeforeId?: any,
    linked?: boolean
  ) {
    try {
      // include_linked_user_purchases to get all linked users of purchases (Group Accounts)
      let url = `${this._controllerBaseUrl}/${encodeURIComponent(customer.id)}/purchases?executed=true&include_linked_user_purchases=true`;

      // ACTIVE
      if (active !== undefined && !active) {
        url += "&active=false&limit=11";
      } else if (active !== undefined && active) {
        url += "&active=true";
      }

      // LINKED
      if (linked !== undefined && linked !== null) {
        url += `&linked=${linked}`;
      }

      // START AFTER - PAGINATION
      if (!!startingAfterId) {
        url += "&starting_after=" + encodeURIComponent(startingAfterId);
      }

      // START BEFORE - PAGINATION
      if (!!endingBeforeId) {
        url += "&ending_before=" + encodeURIComponent(endingBeforeId);
      }

      return await firstValueFrom(this._apiService.getRequest(url));
    } catch (error) {
      throw error;
    }
  }

  async getCustomerLoginDetails(customerId: string) {
    try {
      return await firstValueFrom(this._apiService.getRequest(`${this._controllerBaseUrl}/${encodeURIComponent(customerId)}/user_logins_details`));
    } catch (error) {
      throw error;
    }
  }

  async savePasswordAsync(customerId, userLoginId, request) {
    try {
      return await firstValueFrom(this._apiService.patchRequest(`${this._controllerBaseUrl}/${encodeURIComponent(customerId)}/user_logins/${encodeURIComponent(userLoginId)}/change_password`, request));
    } catch (error) {
      throw error;
    }
  }

  async createLoginDetailsAsync(customerId, request) {
    try {
      return await firstValueFrom(this._apiService.postRequest(`${this._controllerBaseUrl}/${encodeURIComponent(customerId)}/user_logins`, request));
    } catch (error) {
      throw error;
    }
  }
}
