import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { NavigationEnd, Router, RouterState } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { AuthService } from '../auth/auth.guard.service';
import { Globals } from '../common/globals.service';

@Injectable()

/**
 * @description
 *
 * A service that provides REST (Representational State Transfer) requests.
 *
 */
export class ApiService implements OnDestroy {
    public _config: any = environment;
    public clientId: any;

    private _state: RouterState;
    private _routerSub: Subscription;

    constructor(
        private _authService: AuthService,
        private _globals: Globals,
        private _htc: HttpClient,
        private _router: Router,
    ) {
        this._routerSub = this._router.events.subscribe((val) => {
            if (val instanceof NavigationEnd) {
                this._state = this._router.routerState;
            }
        });
    }

    ngOnDestroy() {
        this._routerSub.unsubscribe();
    }

    addHeaders(contentType?: string) {
        if (!!this._globals && !!this._globals.user) {
            this.clientId = this._globals.user.current_client_id;
        }

        let headers = new HttpHeaders(
            {
                'Content-Type': !!contentType ? contentType : 'application/json',
                'Authorization': this._authService.getAuthorizationHeaderValue(),

            });

        if (!!this.clientId) {
            headers = new HttpHeaders(
                {
                    'Content-Type': !!contentType ? contentType : 'application/json',
                    'Authorization': this._authService.getAuthorizationHeaderValue(),
                    'client-id': this.clientId
                });
        }

        return headers;
    }

    /**
    * The GET request is used to retrieve information from the given server using a given URI.
    * Requests using GET should only retrieve data and should have no other effect on the data.
    * @param url A value representing URI you want to call.
    * @param contentType A value representing response type i.e. text, json etc..
    */
    getRequest(url: string, contentType?: string, observeType?: string): Observable<any> {

        const options: any = {
            headers: this.addHeaders(contentType),
            responseType: (!!contentType && contentType.includes('text')) ? 'text' : 'json'
        };
        if(!!observeType){
            options.observe = observeType;

        }

        return this._htc
            .get(this._config.base_url_transaction + url, options)
            .pipe(
                catchError((err: HttpErrorResponse) => {
                    throw this._handleError(err);
                }));
    }

    /**
    * A POST request is used to send data to the server, for example, customer information, file upload, etc. using HTML forms.
    * @param url A value representing URI you want to call.
    * @param request A value representing data you want to send to server.
    */
    postRequest(url: string, request: any, contentType?: string): Observable<any> {
        const options: any = {
            headers: this.addHeaders(null),
            responseType: (!!contentType && contentType.includes('text')) ? 'text' : 'json'
        };

        return this._htc
            .post(this._config.base_url_transaction + url, JSON.stringify(request), options)
            .pipe(
                catchError((err: HttpErrorResponse) => {
                    throw this._handleError(err);
                }));
    }

    /**
    * A PUT request is used to make updates to existing resources.
    * It's important to note that PUT is used to replace the entire resource – it doesn’t do partial updates
    * @param url A value representing URI you want to call.
    * @param request A value representing data you want to send to server.
    */
    putRequest(url: string, request: any): Observable<any> {
        return this._htc
            .put(this._config.base_url_transaction + url, JSON.stringify(request), { headers: this.addHeaders() })
            .pipe(
                catchError((err: HttpErrorResponse) => {
                    throw this._handleError(err);
                }));
    }

    /**
    * A PATCH request is used to to make partial update on a resource.
    * @param url A value representing URI you want to call.
    * @param request A value representing data you want to send to server.
    */
    patchRequest(url: string, request: any): Observable<any> {
        return this._htc
            .patch(this._config.base_url_transaction + url, JSON.stringify(request), { headers: this.addHeaders() })
            .pipe(
                catchError((err: HttpErrorResponse) => {
                    throw this._handleError(err);
                }));
    }

    /**
    * A DELETE request is used to delete resources identified by the Request-URI.
    * @param url A value representing URI you want to call.
    */
    deleteRequest(url: string): Observable<any> {
        return this._htc
            .delete(this._config.base_url_transaction + url, { headers: this.addHeaders() })
            .pipe(
                catchError((err: HttpErrorResponse) => {
                    throw this._handleError(err);
                }));
    }

    private _handleError(err: any) {
        if (err.error instanceof Error) {
            // A client-side or network error occurred. Handle it accordingly.
            console.error('An error occurred:', err.error.message);
        } else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong,            
            console.error(`Backend returned code ${err.status}, with error code: ${err.error}`);
        }
        // ...optionally return a default fallback value so app can continue (pick one)
        // which could be a default value
        // return Observable.of<any>({my: "default value..."});
        // or simply an empty observable
        // return Observable.empty<any>();
        if (err.status === 401) {
            // this._authService.startAuthentication();
            this._authService.logout();
            return err;
        } else {
            return err;
        }
    }
}
