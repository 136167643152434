export class RefundReasonModel {
    id?: string;
    reason?: string;
    text_answer?: boolean;
    active?: boolean;
     
    // Internal Properties
    internal_translate?: any = { 
        path: "analytics_settings.refund_reason.table",
        section: "analytics_settings",
        category: "refund_reason",
        type: "form"
    };

    internal_properties?: any = [
        { name: "active", type: "boolean" },
        { name: "text_answer", type: "boolean" },
        { name: "reason", type: "string" },
    ];
    
    constructor(values: RefundReasonModel = {}) {
        Object.assign(this, values);
    }
}
