import { countryCodes } from './../constants';

export class LocationOverride {
  active?: boolean;
  client_id?: string;
  country_code?: string;
  created_at?: string;
  id?: string;
  ip_address?: string;

  // Internal Properties
  internal_translate?: any = {
    path: "analytics_settings.location_overrides.table",
    section: "analytics_settings",
    category: "location_overrides",
    type: "form"
  };

  internal_properties?: any = [
    { name: "ip_address", type: "string" },
    { name: "country_code", type: "dropdown", options: countryCodes },
  ];

  constructor(values: LocationOverride = {}) {
    Object.assign(this, values);
  }
}
