export enum ModalType {
    delete,
    groupRule,
    paywallStatusWarning,
    supportTicket,
    whitelistRule,
    linkedPriceSummary,
    downgradeSubscription,
    upgradeSubscription,
    changePurchaseRenewalPrice,
    reportParametersModal,
    reportDetailsModal,
    displayReportData,
    actionConfirmation,
    discardChangesConfirmation,
    deletionConfirmation,
    deleteLinkedUserConfirmation,
    whitelistDeletionConfirmation,
    emailTemplate,
    updateEmailAddress,
    updateName,
    updateUserCurrency,
    corporateAccount,
    corporateAccountAccessType,
    corporateAccountEmailImport,
    createPurchaseOnCustomerBehalf,
    customerHolidays,
    itemGroups,
    itemGroupPrices,
    couponCode,
    tag,
    userCancellation,
    appleFinancials,
    addressNotDeliverable,
    cardUpdate,
    applyPurchaseRenewalDiscount,
    removePurchaseRenewalDiscount,
    abTest,
    updateCustomerSegmentation,
    updateCustomerCountryCode,

    purchaseStop,
    purchaseRefund,
    purchaseRefundAndStop,
    purchaseRefundPenalty,
    purchaseCancelRenewal,
    purchaseCancelContractRenewal,
    purchaseUpdateRenewalDate,
    purchaseUpdateFinishDate,
    purchaseUpdateStartDate,
    purchaseRevertCancelledRenewal,
    purchaseRevertCancelledContractRenewal,

    // Settings Modals
    taxRate,
    refundReasonDetail,
    refundReasonSorting,
    csAgentWhitelistIpAddress,
    locationOverride,
    badges,
    supportTicketTypes,
    customerPropertyTypes,
    uploadData,
    customersSegmentation,
    smtpCredentials,
    smtpCredentialsTest,
    stripeCredentials,
    authorizeNetCredentials,
    recaptchaCredentials,
    appleAppCredentials,
    googleAppCredentials,
    appleSocialCredentials,
    googleSocialCredentials,
    facebookSocialCredentials,
    payfortCredentials,
    addressValidationCredentials,
    mailchimpCredentials,
    constantcontactCredentials,
    roblyCredentials,
    linkedUser,
    newsletterCredentials,

    // Subscriptions Managment
    loadData,
    applyBulkAction,

    // Team
    addNewMember,

    //Offline Payment
    renewOfflinePayment,

    // Report Definition
    createReportDefinition,
    updateReportDefinition,
    reportDefinition,
    deleteReportDefinition,
    manualRenewal,

    //Webhook Credentials
    webhookCredentials,

    // OVERVIEW
    // OVERVIEW - EVENTS
    currentEvents,
    pastEvents,

    switchClient,
    emailTemplates,

    bundleProduct,
    addBundleToCorporateAccount,
    //Dynamic Content
    dynamicContent
}
