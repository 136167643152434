export class ChartConfig {
    showXAxis: any = false;
    showYAxis: any = false;
    gradient: any = false;
    showLegend: any = false;
    showXAxisLabel: any = false;
    xAxisLabel: any = '';
    showYAxisLabel: any = false;
    yAxisLabel: any = '';
    colorScheme: any = {
      domain: ['white']
    };

    constructor(values: Object = {}) {
        Object.assign(this, values);
    }
}
