export class Gifting {
  gift?: boolean;
  gift_code_length?: number;
  gift_expiration_duration?: string;
  gift_expiration_duration_unit?: number;
  gift_expiration_notification_duration?: string
  gift_expiration_notification_duration_unit?: number;

  constructor(values: Gifting = {}) {
    [
      "gift",
      "gift_code_length",
      "gift_expiration_duration",
      "gift_expiration_duration_unit",
      "gift_expiration_notification_duration",
      "gift_expiration_notification_duration_unit",
    ].forEach(p => this[p] = values[p]);
  }
}
