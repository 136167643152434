export class Consumable {
    consumable?: boolean;
    consumables?: Array<any>;

    constructor(values: Consumable = {}) {
        [
            'consumable',
            'consumables', 
        ].forEach(p => this[p] = values[p]);
    }
}