export class GoogleSocialCredential {
    id?: string;
    client_id?: string;
    enabled?: string;
    name?: string;
    code?: string;
    callback_path?: string;
    scheme?: string;
    redirect_domain_override?: string;
    google_client_id?: string;
    client_secret?: string;

    // Internal Properties
    internal_translate?: any = {
        path:"analytics_settings.google_social_credentials.table",
        section: "analytics_settings",
        category: "google_social_credentials",
        type: "form"
    };

    internal_properties?: any = [
        { name: "id", type: "string", readonly: true },
        { name: "client_id", type: "string", readonly: true },
        { name: "enabled", type: "boolean" },
        { name: "name", type: "string" },
        { name: "code", type: "string" },
        { name: "callback_path", type: "string" },
        { name: "scheme", type: "string" },
        { name: "redirect_domain_override", type: "string" },
        { name: "google_client_id", type: "textarea", nullable: false },
        { name: "client_secret", type: "string" },
    ];

    constructor(values: GoogleSocialCredential = {}) {
        Object.assign(this, values);
    }
}