export class WhitelistRule  {
  id?: string;
  created_at?: string;
  updated_at?: string;
  client_id?: string;
  email?: string;
  email_domain?: string;
  paywall_status?: string;
  pin?: number;
  deleted?: boolean;

  constructor(values: WhitelistRule = {}) {
    Object.assign(this, values)
  }
}
