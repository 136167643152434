export enum NotificationEventType {
    Unknown = "unknown",
    PurchaseCreated = "purchase.created",
    PurchaseExecuted = "purchase.executed",
    PurchaseCancelled = "purchase.cancelled",
    PurchaseUpdated = "purchase.updated",
    PurchaseRenewalFailed = "purchase.renewal_failed",
    CustomerCreated = "customer.created",
    CustomerUpdated = "customer.updated",
    CustomerDeleted = "customer.deleted",
    NewsletterRegistration = "newsletter.registration",
    ContactInfoUpdated = "contact_info.updated"
}
