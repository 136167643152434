
import { countryCodes } from "../constants";
import { AddressValidationType } from "../enums";

export class AddressValidationCredential {
    id?: string;
    client_id?: string;
    name?: string;
    type?: AddressValidationType;
    key?: string;
    country_codes?: Array<string>;
    validate_deliverability?: boolean;
    allow_manual_address?: boolean;

    // Internal Properties
    internal_translate?: any = {
        path: "analytics_settings.address_validation_credentials.table",
        section: "analytics_settings",
        category: "address_validation_credentials",
        type: "form"
    };

    internal_properties?: any = [
        { name: "id", type: "string", readonly: true },
        { name: "name", type: "string" },
        { name: "type", type: "dropdown-label" },
        { name: "key", type: "string" },
        { name: "country_codes", type: "dropdown-multiple", options: countryCodes },
        { name: "validate_deliverability", type: "boolean" },
        { name: "allow_manual_address", type: "boolean" },
    ]

    constructor(values: AddressValidationCredential = {}) {
        Object.assign(this, values);
    }
}