import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { ApiService } from '../api/api.service';
import { Observable } from 'rxjs';
import { Globals } from '../common/globals.service';
import { catchError, map } from 'rxjs/operators';

@Injectable()
export class PaymentService {

    constructor(private _apiService: ApiService, private _globals: Globals) {
        // this.initializeStripe();
    }

    initializeStripe(publishableKey?: string): Observable<any> {

        // return Stripe((!!publishableKey ? publishableKey : environment.stripe_publishable_key));
        return this._apiService.getRequest('vendors/me')
            .pipe(
                map(() => {
                    return Stripe((!!publishableKey ? publishableKey : environment.stripe_publishable_key));
                }),
                catchError((err: any) => {
                    let stripeKey = environment.stripe_publishable_key;
                    if (!!this._globals && !!this._globals.user && this._globals.user.current_client_id === 'inm') {
                        stripeKey = environment.stripe_publishable_key_inm;
                    }

                    return Stripe(stripeKey);
                })
            );
    }
}
