export class Segment {
  id?: string;
  name?: string;
  description?: string;
  created_at?: string;

  constructor(values: Segment) {
    this._internalMapping.forEach(s => this[s] = values[s]);
    delete this._internalMapping;
  }

  private _internalMapping = [
    "id",
    "name",
    "description",
    "created_at"
  ];

}
