export class UIOptions{
    active?: boolean;

    internal_properties?: any = {
        active: undefined,
    }

    constructor(values: UIOptions = {}) {
        if (Object.keys(values).length > 0) {
            Object.keys(values).filter(key => key in this.internal_properties).forEach(key => this.internal_properties[key] = values[key]);
        }
    }

}