export class CorporateAccount {
    id?: string;
    client_id?: string;
    account_user_id?: string;
    location?: string;
    name?: string;
    pin?: string;
    updated_at?: string;
    corporate_account_item_groups?: any[] = [];
    corporate_account_rules?: any[] = [];

    constructor(values: Partial<CorporateAccount> = {}) {
        Object.assign(this, values);
    }
}