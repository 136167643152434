export class Vendor {
  accounts?: Array<any>;
  address_validation_key?: string;
  address_validation_type?: string;
  client_id?: string;
  currencies?: Array<string>;
  currency?: string; 
  id?:string;
  ipstack_key?:string;
  name?:string;
  payment_provider?: string;
  payment_providers?: Array<string>;
  payment_providers_moto?: Array<string>;
  real_balance?: number
  stripe_publishable_key?: string;
  tax_code?: string;
  tax_code_eu?: string;
  tax_rate?: number;
  tax_rate_eu?: number;
  tax_rates?: Array<object>;
  time_zone_offset_minutes?: number;
  virtual_balance?: number;

  constructor(values: Vendor = {}) {
    this._internalProperties.forEach(p => this[p] = values[p]);
  }
  
  private _internalProperties?: any = [
    "accounts",
    "address_validation_key",
    "address_validation_type",
    "client_id",
    "currencies",
    "currency",
    "id",
    "ipstack_key",
    "name",
    "payment_provider",
    "payment_providers",
    "payment_providers_moto",
    "real_balance",
    "stripe_publishable_key",
    "tax_code",
    "tax_code_eu",
    "tax_rate",
    "tax_rate_eu",
    "tax_rates",
    "time_zone_offset_minutes",
    "virtual_balance",
  ];
}

