export class PurchaseNotification {
  additional_notifications_set?: boolean;
  additional_notification_one?: boolean;
  additional_notification_one_renewals?: boolean;
  additional_notification_one_duration?: number;
  additional_notification_one_duration_unit?: number;
  additional_notification_two?: boolean;
  additional_notification_two_renewals?: boolean;
  additional_notification_two_duration?: number;
  additional_notification_two_duration_unit?: number;

  constructor(values: PurchaseNotification = {}) {
    [
      "additional_notifications_set",
      "additional_notification_one",
      "additional_notification_one_renewals",
      "additional_notification_one_duration",
      "additional_notification_one_duration_unit",
      "additional_notification_two",
      "additional_notification_two_renewals",
      "additional_notification_two_duration",
      "additional_notification_two_duration_unit",
    ].forEach(p => this[p] = values[p]);
  }
}
