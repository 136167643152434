import { AdBlock } from "./adblock.model";
import { Consumable } from "./consumable.model";
import { Contract } from "./contract.model";
import { Country } from "./country.modal";
import { CouponModel } from "./coupon.model";
import { CustomTextFields } from "./customTextFields.model";
import { DeviceType } from "./deviceType.model";
import { Gifting } from "./gifting.model";
import { MeteredPaywall } from "./meteredPaywall.model";
import { PriceAmount } from "./priceAmount.model";
import { PriceDescription } from "./priceDescription.model";
import { PurchaseDuration } from "./purchaseDuration.model";
import { PurchaseNotification } from "./purchaseNotification.model";
import { ReferrerModel } from "./referrer.model";
import { RuleActivePeriod } from "./ruleActivePeriod.model";

export class OverrideDetials { 
    public driversTypes?: any;
    public overridesTypes?: any;

    constructor(parentPrice: any, priceOverride: any, isOverride: boolean = false, emailTemaplateActive?: boolean) {
        this._setDefaultValues();

        if (priceOverride && isOverride) {
            this._populateValues(parentPrice, priceOverride, emailTemaplateActive);
        }
    }

    /**
     * 
     * @param parentPrice 
     * @param priceData - parent price OR override price
     */
    private _populateValues(parentPrice: any, priceData: any, emailTemaplateActive?: boolean) {
        this.driversTypes.forEach((driver: any) => {
            driver.is_set = this._isActive(driver.model, priceData);
            if (!driver.is_set) {
                Object.assign(priceData, new driver.model(parentPrice));
            }
        });

        this.overridesTypes.forEach(async (override: any) => {
            if (override.type === 'email') {
                override.is_set = emailTemaplateActive;
            } else if (override.type === 'uIOption') {
              override.is_set = this._setUIOption(priceData, parentPrice);
            } else if (override.type === 'dealPrice') {
                override.is_set = this._setDealPriceOption(priceData);
            }
            else {
                override.is_set = this._isActive(override.model, priceData);
                if (!override.is_set) {
                    Object.assign(priceData, new override.model(parentPrice));
                }
            }
        });
    }

    private _setDefaultValues() {
        this.driversTypes = [
            { type: 'country', model: Country },
            { type: 'adBlock', model: AdBlock },
            { type: 'referrer', model: ReferrerModel },
            { type: 'activePeriod', model: RuleActivePeriod },
            { type: 'deviceType', model: DeviceType },
        ];

        this.overridesTypes = [
            { type: 'description', model: PriceDescription },
            { type: 'amount', model: PriceAmount },
            { type: 'purchaseDuration', model: PurchaseDuration },
            { type: 'contract', model: Contract },
            { type: 'meteredPaywall', model: MeteredPaywall },
            { type: 'couponCode', model: CouponModel },
            { type: 'consumable', model: Consumable },
            { type: 'customTextFields', model: CustomTextFields },
            { type: 'dealPrice' },
            { type: 'purchaseNotificationDuration', model: PurchaseNotification },
            { type: 'gifting', model: Gifting },
            { type: 'email' },
            { type: 'uIOption' },
        ];
    }

    /**
     * @description This method checks if any value of model is undefined
     * @param model 
     * @returns true if any value of model is defined, otherwise false
     */
    private _isActive(model: any, priceData: any): boolean {
        const properties = new model(priceData);

        const valuesProperties = Object.values(properties);
        for (let i = 0; i < valuesProperties.length; i++) {
            const value = valuesProperties[i];
            if (value !== undefined && !Array.isArray(value)) {
                return true;
            }

            if (Array.isArray(value) && value.length > 0) {
                return true;
            }

        }
        return false;
    }

    /**
     * @description looking if parent and override price status active is matching
     */
  private _setUIOption(priceData: any, parentPrice: any): boolean {
      if (parentPrice?.active && priceData?.active) {
        return false;
      }

      if (!parentPrice?.active && !priceData?.active) {
        return false;
      }

      return true;
    }

    private _setDealPriceOption(priceData): boolean {
        const data = !!priceData && priceData.deal;
        return data;
    }

}
