import { Injectable } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { Globals, HelpersService, StorageService } from '../common';
import { AuthErrorType } from '../../enums';
import { Subject } from 'rxjs';
import { AuthRequestType } from '../../enums/authRequestType';

export class User {
    id_token: string;
    access_token: string;
    token_type: string;
    provider: string;
    expired: boolean;
    expires_at: string;
    expires_at_datetime: string;
    access_token_expiration_date: string;
    access_token_expired: boolean;

    constructor(values: Object = {}) {
        Object.assign(this, values);
    }
}

@Injectable()
export class AuthService {
    private _config: any = environment;
    private _user: User = null;
    private _urlParameter = "";

    // Observable sources
    private _callParentComponentMethod = new Subject<any>();

    // Observable streams
    public callParentComponentMethod$ = this._callParentComponentMethod.asObservable();

    public clientId = "";

    constructor(
        private _globals: Globals,
        private _router: Router,
        private _storageService: StorageService,
        private _helpers: HelpersService,
        private _httpClient: HttpClient,
        private _route: ActivatedRoute
    ) {
        this._setUserInfoAsync();
    }

    isLoggedIn() {
        try {
            return this._user !== null && !!this._user.access_token && !this._user.expired;
        } catch (err) {
            return false;
        }
    }

    getAuthorizationHeaderValue(): string {
        if (!!this._user) {
            return `${this._user.token_type} ${this._user.access_token}`;
        } else {
            return null;
        }
    }

    async fpHandleAuthenticationRequestAsync(authType: string, request: any) {
        try {
            let response: any;
            if (authType === AuthRequestType.signIn) {
                response = await this._userSignInAsync(request);
            } if ([AuthRequestType.signInSocial.toString(), AuthRequestType.signUpSocial.toString()].includes(authType)) {
                response = await this._userStartSocialAuthenticationAsync(request);
            } else if (authType === AuthRequestType.signUp) {
                response = await this._userSignUpAsync(request);
            } else if (authType === AuthRequestType.confirmResetPassword) {
                response = await this._userConfirmResetPasswordAsync(request);
            } else if (authType === AuthRequestType.changePassword) {
                response = await this._userChangePassword(request);
            } else if (authType === AuthRequestType.updatePassword) {
                response = await this._userChangeNewPassword(request);
            }
            if (response === null) {
                return { success: true }
            }

            const user = {
                access_token: response.access_token,
                expires_at: response.expires_in,
                expires_at_datetime: new Date().setSeconds(response.expires_at),
                access_token_expiration_date_expired: new Date(new Date().setSeconds(response.expires_at)) < new Date()
            }

            await this._setUserInfoAsync(user);
            this._redirectAuthenticatedUser();
            return null;
        } catch (err) {
            return { error: err };
        }
    }

    async completeSocialAuthenticationAsync(params: any) {
        try {
            let authParams = params;
            if (!!params["verify_email"]) {
                authParams = await this._userSignUpVerifyEmail(params["verify_email"]);
            }

            const user = {
                access_token: authParams?.access_token,
                expires_in: authParams?.expires_in,
                token_type: authParams?.token_type,
                error_code: authParams?.error_code
            };

            await this._setUserInfoAsync(user);
            this._redirectAuthenticatedUser();
        } catch (err) {
            throw err;
        }
    }

    logout() {
        try {
            this._deleteUserData();
            this._storageService.cleanAll();
            this._router.navigate(["/authentication"]);
        } catch (err) {
            this._handleError(err);
        }
    }

    _userChangePassword(data: any) {
        try {
            const request = { "email": data.username };
            const url = this._config.authority + '/' + this._config.client_id + '/request_reset_password';
            return this._authPostRequest(url, request);
        } catch (err) {
            this._handleError(err);
        }

    }

    _userChangeNewPassword(data: any) {
        try {
            const request = {
                "old_password": data.oldPassword,
                "new_password": data.newPassword
            };
            const url = this._config.authority + '/users/' + this._globals.user.id + '/change_password';
            return this._authPostRequest(url, request);
        } catch (err) {
            this._handleError(err);
        }

    }

    private async _userSignInAsync(data: any) {
        try {
            const request = {
                "password": data.password,
                "username": data.username
            };

            const url = this._config.authority + '/' + this._config.client_id + '/login';
            return this._authPostRequest(url, request);
        } catch (err) {
            throw err;
        }
    }

    private async _userStartSocialAuthenticationAsync(data: any) {
        try {
            const url = `${this._config.authority}/${this._config.client_id}/external/${data.provider}?redirect_uri=${this._config.redirect_uri}`;
            window.location.href = url;
        } catch (err) {
            this._handleError(err);
        }
    }

    private async _userSignUpAsync(data: any) {
        try {
            const request = {
                "email": data.email,
                "username": data.email,
                "password": data.password,
                "password_confirm": data.password_confirm,
                "name": data.name,
                "marketing": false,
                "source": 'web-plus-email'
            };

            const url = this._config.authority + '/' + this._config.client_id + '/register';
            return this._authPostRequest(url, request);
        } catch (err) {
            throw err;
        }
    }

    private async _userSignUpVerifyEmail(token: string) {
        try {
            const request = { "token": token };
            const url = this._config.authority + '/' + this._config.client_id + '/verify_email_address';
            return this._authPostRequest(url, request);
        } catch (err) {
            throw err;
        }
    }

    private async _userConfirmResetPasswordAsync(data: any) {
        try {
            const request = {
                "token": data.reset_token_id,
                "password": data.password,
            };

            const url = this._config.authority + '/' + this._config.client_id + '/reset_password';
            return this._authPostRequest(url, request);
        } catch (err) {
            throw err;
        }
    }

    private _authOnStorageChange(event: any) {
        try {
            if (event.key === 'fp.user') {
                let user: any = event.newValue;
                user = !!user ? JSON.parse(user) : {};
                if (!!user.access_token_expiration_date) {
                    user.expires_at_datetime = this._helpers.secondsToDate(user.expires_at);
                }

                this._setUserInfoAsync(user);
            }
        } catch (err) {
            this._handleError(err);
        }
    }

    private _setUserInfoAsync(user?: any): Promise<any> {
        try {
            user = !!user ? user : sessionStorage.getItem('fp.user');
            user = !!user ? user : localStorage.getItem('fp.user');
            user = !!user ? user : this._helpers.getCookie('fp.user');
            user = !!user && typeof user === "string" ? JSON.parse(user) : user;

            if (!!user) {
                if (!!user.expires_at) {
                    user.expires_at_datetime = this._helpers.secondsToDate(user.expires_at, true);
                } else if (!!user.expires_in) {
                    user.expires_at_datetime = this._helpers.secondsToDate(user.expires_in, true);
                } else if (!!user.expires_at_datetime && typeof user.expires_at_datetime === 'string') {
                    user.expires_at_datetime = new Date(user.expires_at_datetime);
                } else if (!!user.access_token_expiration_date) {
                    user.expires_at_datetime = user.access_token_expiration_date;
                }

                // Store user details to cookie
                // ------------------------------------------------------------------------------------------------
                // if (!!this.config.auth_settings && !!this.config.auth_settings.provider) {
                //     data.provider = this.config.auth_settings.provider;
                // }

                // Set global variable
                this._user = !!this._user ? this._user : new User();
                this._user.id_token = user.id_token;
                this._user.access_token = user.access_token;
                this._user.access_token_expiration_date = user.expires_at_datetime;
                this._user.access_token_expired = new Date(user.expires_at_datetime) < new Date();
                this._user.provider = user.provider;
                this._user.token_type = "Bearer";

                // Set cookie variable
                const userDetails = JSON.stringify(user);
                sessionStorage.setItem('fp.user', userDetails);
                localStorage.setItem('fp.user', userDetails);
                this._helpers.setCookie('fp.user', userDetails, user.expires_at_datetime);

                // Continue in the flow
                // --------------------------------------------------------------------------------
                if (!!this._user && (!this._user.access_token_expiration_date || this._user.access_token_expired)) {
                    this.logout();
                    return;
                }

                return Promise.resolve();
            } else {
                sessionStorage.removeItem('fp.user');
                return Promise.resolve();
            }
        } catch (err) {
            this._handleError(err);
        }
    }

    private _deleteUserData() {
        try {
            this._user = null;
            this._globals.user = null;

            // Clear storages & cookie
            // --------------------------------------------------------------------------------
            const localstorageItems = ['fp.user'];
            for (let li = 0; li < localstorageItems.length; li++) {
                localStorage.removeItem(localstorageItems[li]);
            }

            const sessionstorageItems = ['fp.user', 'fp.selected_price'];
            for (let si = 0; si < localstorageItems.length; si++) {
                sessionStorage.removeItem(sessionstorageItems[si]);
            }

            this._helpers.deleteCookie("fp.user", "/");
        } catch (err) {
            this._handleError(err);
        }
    }

    private _authPostRequest(url: string, request: any, contentType?: string): any {

        const headers = new HttpHeaders(
            {
                'Content-Type': 'application/json'
            });

        const options: any = {
            headers: headers,
            responseType: (!!contentType && contentType.includes('text')) ? 'text' : 'json'
        };

        return this._httpClient
            .post(url, JSON.stringify(request), options)
            .pipe(
                catchError((err: HttpErrorResponse) => {
                    throw err;
                })
            ).toPromise();
    }

    private _handleError(err: any) {
        if (err.error instanceof Error) {
            // A client-side or network error occurred. Handle it accordingly.
            console.error('An error occurred:', err.error.message);
        } else if (err.status && err.error) {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong,
            console.error(`Backend returned code ${err.status}, with error code: ${err.error}`);
        } else {
            console.error(err);
        }
        return err;
    }

    private _redirectAuthenticatedUser() {
        try {
            let url = this._globals.entryUrl
                ? this._globals.entryUrl.url
                : `clients/${this._globals.user?.current_client_id}/analytics`;

            const storedEntryUrl = localStorage.getItem("fp.entry_url");
            if (storedEntryUrl) {
                url = storedEntryUrl;
            }

            this._router.navigate([url]);
        } catch (err) {
            this._router.navigate([`clients/${this._globals.user?.current_client_id}/analytics`]);
        }
    }

    public setErrorMessage(err: any) {
        if (!!err && !!err.error && !!err.error.code) {
            switch (err.error.code) {
                case "email_verification_required":
                    return AuthErrorType.emailVerificationRequired;
                case "social_registration":
                    return AuthErrorType.socialRegistration;
                case "customer_not_found":
                    return AuthErrorType.customerNotFound;
                case "invalid_password":
                    return AuthErrorType.invalidPassword;
                case "limit_exceeded":
                    return AuthErrorType.limitExceeded;
                default:
                    return AuthErrorType.generic;
            }
        } else {
            return AuthErrorType.generic;
        }
    }

    callParentRefresh(value: any) {
        this._callParentComponentMethod.next(value);
    }

    addHeaders(contentType?: string) {
        if (!!this._user) {
            this.clientId = this._user.token_type + " " + this._user.access_token;
        }

        let headers = new HttpHeaders(
            {
                'Content-Type': !!contentType ? contentType : 'application/json',
                'Authorization': this.getAuthorizationHeaderValue(),

            });

        if (!!this.clientId) {
            headers = new HttpHeaders(
                {
                    'Content-Type': !!contentType ? contentType : 'application/json',
                    'Authorization': this.getAuthorizationHeaderValue(),
                    'client-id': this.clientId
                });
        }

        return headers;
    }
}
