export class PriceMigration {
  target_price_id?: string;
  segments?: string;
  notification?: boolean;
  notification_duration?: string;
  notification_duration_unit?: string;

  constructor(values: PriceMigration = {}) {
    this._internalProperties.forEach(p => this[p] = values[p]);
    delete this._internalProperties;
  }

  private _internalProperties?: any = [
    "target_price_id",
    "segments",
    "notification",
    "notification_duration",
    "notification_duration_unit",
  ];

}
