import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class LocalizedText {
    public textSubject = new BehaviorSubject<any>(' ');
    public text = this.textSubject.asObservable();

    public transaltionsSubject = new BehaviorSubject<any>(' ');
    public translations = this.transaltionsSubject.toPromise();

    constructor() { }

    update(values: any) {
        this.textSubject.next(values);

        this.transaltionsSubject.next(values);
        this.transaltionsSubject.complete();
    }
}
