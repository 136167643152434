export const environment = {
  production: false,
  type: 'uat',
  // api-proxy
  base_url_transaction: 'https://api.uat.flip-pay.cloud/v1/',
  base_verify_ui: 'https://api.uat.flip-pay.cloud/v1/',
  cdn_url: 'https://cdn.flip-pay.cloud/uat/',

  // oidc
  client_id: 'impadminclient',
  redirect_uri: window.location.protocol + '//' + window.location.host + '/authentication',
  post_logout_redirect_uri: 'https://portal.uat.flip-pay.cloud/',
  response_type: 'id_token token',
  scope: 'openid read write refund admin cards-read cards-write charge',
  authority: 'https://accounts.uat.flip-pay.cloud',
  original_authority: 'https://accounts.uat.flip-pay.cloud',
  silent_redirect_uri: window.location.protocol + '//' + window.location.host + '/callback-silent.html',
  silent_renew: true,
  request_state_store: window.sessionStorage,
  filterProtocolClaims: true,
  loadUserInfo: true,
  automaticSilentRenew: true,

  // Logo
  default_navbar_logo: 'https://cdn.flip-pay.cloud/uat/images/fp_logo.png',

  // Payment
  stripe_publishable_key: 'pk_test_oEcNAf4QsvHN7aXgNpVUXhut',
  stripe_publishable_key_inm: 'pk_test_nZuNEPtqvv6vPKjH6enExJHm00zZ8lNaNg',

  // Documentation 
  customer_service_agent: "https://cdn.flip-pay.cloud/documentation/uat/081087f0-46ff-4475-9305-b8cc923179ce_csa.pdf",
  content_management: "https://cdn.flip-pay.cloud/documentation/uat/fd949427-a54e-4d60-821d-4b9c87ce1ef9_cm.pdf",
  access_control: "https://cdn.flip-pay.cloud/documentation/uat/256070e0-b526-444f-a7cc-21466a2fb4dd_ac.pdf",

  // Autoaddress base url
  autoaddress_base_data_api_url: "https://data-api.autoaddress.ie/",
  autoaddress_key: "145A5E12-6EEB-D1BB-A4C1-C7DCB66CB398",

  // Authorize net
  anet_src: "https://jstest.authorize.net/v1/Accept.js"
};
