export class SmtpCredentials {
    id?: string;
    client_id?: string;
    name?: string;
    server?: string;
    port?: number;
    username?: string;
    password?: string;
    from_name?: string;
    from_email?: string;
    enabled?: boolean;
    reply_to?: string;
    use_ssl?: boolean;
    bcc_address?: string;
    disable_ssl_certificate_validation?: boolean;

    // Internal Properties
    internal_translate?: any = {
        path: "analytics_settings.smtp_credentials.table",
        section: "analytics_settings",
        category: "smtp_credentials",
        type: "form"
    };

    internal_properties?: any = [
        { name: "id", type: "string", readonly: true },
        { name: "name", type: "string" },
        { name: "enabled", type: "boolean" },
        { name: "server", type: "string" },
        { name: "port", type: "number" },
        { name: "username", type: "string" },
        { name: "password", type: "string" },
        { name: "from_name", type: "string" },
        { name: "from_email", type: "string" },
        { name: "reply_to", type: "string" },
        { name: "use_ssl", type: "boolean" },
        { name: "bcc_address", type: "string" },
        { name: "client_id", type: "string" },
        { name: "disable_ssl_certificate_validation", type: "boolean" },
    ];

    constructor(values: SmtpCredentials = {}) {
        Object.assign(this, values);
    }
}
