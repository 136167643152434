export class SupportTicket {
  object?: string;
  id?: string;
  user_id?: string;
  agent_id?: string;
  client_id?: string;
  created_at?: string;
  title?: string;
  status?: string;
  notes?: Array<any>;
  audit_items?: Array<any>;
  severity?: string;
  updated_at?: string;
  corporate_account_id?: string;

  constructor(values: SupportTicket = {}) {
    Object.assign(this, values);
  }
}
