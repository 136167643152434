export class ReferrerModel {
    referrer_type?: string;
    referrer_domain?: string;
    referrer_id?: string;

    constructor(values: ReferrerModel = {}) {
        [
            'referrer_type',
            'referrer_domain',
            'referrer_id',
        ].forEach(p => this[p] = values[p]);
    }
}