export class AppleAppCredential {
    id?: string
    client_id?: string
    name?: string
    package_name?: string
    password?: string
    vendor_number?: string
    kid?: string
    key?: string
    iss?: string

    // Internal Properties
    internal_translate?: any = { 
        path: "analytics_settings.apple_app_credentials.table",
        section: "analytics_settings",
        category: "apple_app_credentials",
        type: "form"
    };

    internal_properties?: any = [
        { name: "id", type: "string", readonly: true },
        { name: "client_id", type: "string" },
        { name: "name", type: "string" },
        { name: "package_name", type: "string", nullable: false },
        { name: "password", type: "string", nullable: false },
        { name: "vendor_number", type: "number" },
        { name: "kid", type: "string" },
        { name: "key", type: "textarea" },
        { name: "iss", type: "string" },
    ];

    constructor(values: AppleAppCredential = {}) {
        Object.assign(this, values);
    }
}