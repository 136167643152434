import { Injectable, NgZone } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Globals } from '../common/globals.service';
import { AuthService } from './auth.guard.service';

@Injectable()
export class RoleGuard implements CanActivate {

    constructor(
        private _authService: AuthService,
        private _zone: NgZone,
        private _globals: Globals,
        private _router: Router
    ) { }

    async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
        try {
            if (!this._authService.isLoggedIn()) {
                return false;
            } else {
                const hasAccess = this._checkRoles(route.data.expectedRole, this._globals.user?.roles, state);
                if (hasAccess) {
                    return true;
                }

                this._accessDeniedRedirect(state);
            }
        } catch (err) {
            throw err;
        }
    }

    private _checkRoles(expectedRole: string, userRoles: any, state: any): boolean {
        let hasRole: boolean;
        let userRole = null;
        if (!!userRoles) {
            userRole = userRoles.find(x => x.client_id === this._globals.user.current_client_id);
        }

        if (!expectedRole) {
            hasRole = true;
        } else {
            hasRole =
                !!userRole
                    ? userRole.level.includes(expectedRole) || userRole.level.includes('god')
                    : false;
        }

        // If a user don't have an access as cs agent we need to check if he is cs supervisor and only after that we can redirect him
        if (expectedRole === 'cs_agent' && !hasRole) {
            hasRole =
                !!userRole
                    ? userRole.level.includes('cs_supervisor')
                    : false;
        }

        if (!hasRole) {
            if (!!userRole) {
                hasRole = !!["content", "cs_agent", "cs_supervisor", "analytics", "finance", "admin"].find(x => userRole.level.includes(x));

                const baseUrl = `clients/${this._globals.user?.current_client_id}/analytics`;
                if (userRole.level.includes('content')) {
                    this._router.navigate([`${baseUrl}/content`]);
                } else if (userRole.level.includes('cs_agent') || userRole.level.includes('cs_supervisor')) {
                    this._router.navigate([`${baseUrl}/customers`]);
                } else if (userRole.level.includes('analytics')) {
                    this._router.navigate([`${baseUrl}/overview`]);
                } else if (userRole.level.includes('finance')) {
                    this._router.navigate([`${baseUrl}/statements`]);
                } else if (userRole.level.includes('admin')) {
                    this._router.navigate([`${baseUrl}/team/members`]);
                } else {
                    this._globals.deniedAccessUrl = state;
                    this._router.navigate(['error/access-denied']);
                }
            } else {
                this._globals.deniedAccessUrl = state;
                this._router.navigate(['error/access-denied']);
            }
        }

        return hasRole;
    }

    private _accessDeniedRedirect(state: RouterStateSnapshot) {
        this._globals.deniedAccessUrl = state;
        this._zone.run(() => {
            this._router.navigate(['/error/access-denied']);
        });
    }
}
